import { useState } from "react";
import ReactModal from "react-modal";
import { useTranslation } from "react-i18next";

import { setInfo } from "../../http/userApi";

const langOptions = [{ value: "ru" }, { value: "ya" }];

const LanguagePicker = () => {
	const { t, i18n } = useTranslation();
	const [showModalLang, setShowModalLang] = useState(false);
	const currentLang = i18n.language;

	const changeLanguage = async (lng) => {
		i18n.changeLanguage(lng);
		const formData = new FormData();
		formData.append("language", lng);
		await setInfo(formData);

		setShowModalLang(false);
	};

	return (
		<>
			<button
				type="button"
				className="flex flex-col"
				onClick={() => setShowModalLang(true)}
			>
				<p className="text-lg leading-none">
					{t("settings.app_language")}
				</p>
				<label className="text-gray text-sm">{t(`lang.${currentLang}`)}</label>
			</button>

			<ReactModal
				isOpen={showModalLang}
				onRequestClose={() => setShowModalLang(false)}
				closeTimeoutMS={100}
				className="fixed bottom-0 w-full bg-paper px-5 pt-10 rounded-t-xl shadow-map outline-none"
				overlayClassName="fixed inset-0 bg-overlay"
			>
				<div className="flex flex-col space-y-5 mb-10">
					<div>
						<span className="block font-bold mb-4">
							{t("settings.сhoose_lang")}
						</span>
						<div className="flex flex-col">
							{langOptions.map((option) => (
								<button
									key={option.value}
									onClick={() => changeLanguage(option.value)}
									className="text-left flex justify-between items-center py-2"
								>
									{t(`lang.${option.value}`)}
									{option.value === currentLang && (
										<svg
											xmlns="http://www.w3.org/2000/svg"
											fill="none"
											viewBox="0 0 24 24"
											strokeWidth={1.5}
											stroke="currentColor"
											aria-hidden
											className="w-6 h-6"
										>
											<path
												strokeLinecap="round"
												strokeLinejoin="round"
												d="M4.5 12.75l6 6 9-13.5"
											/>
										</svg>
									)}
								</button>
							))}
						</div>
					</div>
				</div>
			</ReactModal>
		</>
	);
};

export default LanguagePicker
