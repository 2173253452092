import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { observer } from "mobx-react-lite";

import {
	changePrice,
	changeStatus,
	getAnswers,
	getOneOrder,
} from "../../../http/orderApi";
import {
	USER_CITY_START_ROUTE,
	USER_HISTORY_ROUTE,
	USER_CITY_GO_ROUTE,
} from "../../../utils/consts";
import {
	comment_icon,
	baby_chair_icon,
	many_people_icon,
} from "../../../img/index";
import { AppContext } from "../../../contexts/AppContext";
import AnswerList from "../../../components/AnswerList";
import { TravelMap } from "../../../components/TravelMap";
import Loader from "../../../components/Loader";
import Button from "../../../components/Button";
import Dialog from "../../../components/Dialog";

const UserCitySearch = observer(() => {
	const { t } = useTranslation();
	const { orders } = useContext(AppContext);
	const [orderInfo, setOrderInfo] = useState();
	const [isTooLongWait, setTooLongWait] = useState(false);
	const [newPrice, setNewPrice] = useState(0);
	const { id } = useParams();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		let timer

		getOneOrder(id)
			.then((data) => {
				if (!data) return (window.location.href = USER_CITY_START_ROUTE);
				if (data.status === 0)
					return (window.location.href = USER_HISTORY_ROUTE + "/" + id);
				if (data.status >= 2 && data.status <= 5)
					return (window.location.href = USER_CITY_GO_ROUTE + "/" + id);
				if (data.status === 6)
					return (window.location.href = USER_HISTORY_ROUTE + "/" + id);

				setOrderInfo({
					...data,
					fromPoint: data.fromPoint.split(",").map(Number),
					toPoint: data.toPoint.split(",").map(Number),
				});

				setNewPrice(data.price);

				const TIMEOUT = 1000 * 60 * 15

				if (new Date(data.createdAt).getTime() <= new Date().getTime() - TIMEOUT) {
					setTooLongWait(true)
				} else {
					const timeout = TIMEOUT - (new Date().getTime() - new Date(data.createdAt).getTime())
					timer = setTimeout(() => setTooLongWait(true), timeout + (1000 * 3))
				}
			})
			.finally(() => setLoading(false));

		getAnswers(id, 1).then((data) => orders.setAnswers(data));

		return () => {
			clearTimeout(timer)
		}
	}, []);

	// // Auto Update
	useEffect(() => {
		const loadData = setInterval(() => {
			getAnswers(id, 1).then((data) => {
				if (orders.answers.length !== data.length) {
					orders.setAnswers(
						data.filter(
							({ createdAt }) =>
								new Date().getTime() - new Date(createdAt).getTime() <
								1000 * 15,
						),
					);
				}
			});
		}, 5000);

		return () => clearInterval(loadData);
	}, [getAnswers, useState]);

	const changePriceButton = (value) => {
		if (value === "PLUS") {
			setNewPrice(newPrice + 20);
		} else {
			let changedPrice = newPrice - 20;
			if (changedPrice < 100) changedPrice = 100;
			setNewPrice(changedPrice);
		}
	};

	const changePriceForm = async () => {
		const data = await changePrice(id, newPrice);
		setOrderInfo(data);
	};

	const cancelOrder = async () => {
		const data = await changeStatus(id, 0);
		setOrderInfo(data.status);
		window.location.href = USER_CITY_START_ROUTE;
	};

	if (loading) return <Loader />;

	return (
		<main className="h-screen flex flex-col">
			<div className="flex-1 flex flex-col z-10">
				<div className="w-full bg-graylight" onClick={cancelOrder}>
					<div className="container mx-auto px-1.5 py-3">
						<span className="text-gray">{t("order.order_cancel")}</span>
					</div>
				</div>

				<div className="w-full bg-paper opacity-80 px-3 py-2">
					<p className="text-center">{t("city.search.we_offer_your_price")}</p>
				</div>

				{orderInfo && (
					<TravelMap
						fromPoint={orderInfo.fromPoint}
						toPoint={orderInfo.toPoint}
					/>
				)}
			</div>

			<div className="flex justify-center flex-col z-10">
				<div className="container sm:max-w-lg bg-paper mx-auto shadow-map p-5">
					<div className="flex flex-row flex-1 min-h-[3rem]">
						<div className="space-y-2.5 py-1">
							<div className="flex space-x-1.5">
								<span className="inline-block w-4 h-4 border-4 border-solid border-red rounded-full" />
								<p className="text-sm leading-4 font-bold">{orderInfo.from}</p>
							</div>

							<div className="flex space-x-1.5">
								<span className="inline-block w-4 h-4 border-4 border-solid border-blue rounded-full" />
								<p className="text-sm leading-4">{orderInfo.to}</p>
							</div>
						</div>
					</div>

					{orderInfo.comment && (
						<div className="flex flex-col justify-start">
							<div className="flex flex-row justify-start items-center space-x-2.5">
								<span className="w-3 h-2.5">
									<img src={comment_icon} />
								</span>
								<p className="text-xs">{orderInfo.comment}</p>
							</div>
						</div>
					)}

					<div className="flex flex-row justify-start items-center space-x-3 mb-3">
						{orderInfo.babyChair && (
							<div className="flex flex-row justify-start items-center space-x-2.5">
								<span className="w-3 h-2.5">
									<img src={baby_chair_icon} />
								</span>
								<p className="text-xs">{t("order.need_baby_chair")}</p>
							</div>
						)}

						{orderInfo.manyPeople && (
							<div className="flex flex-row justify-start items-center space-x-2.5">
								<span className="w-3 h-2.5">
									<img src={many_people_icon} />
								</span>
								<p className="text-xs">{t("order.more_than_4_passengers")}</p>
							</div>
						)}
					</div>

					<div className="flex flex-col mt-3">
						<div className="flex flex-row justify-between items-center mb-3">
							<button
								className="border border-solid border-red px-7 py-0.5 rounded-3xl text-red font-medium"
								onClick={() => changePriceButton("MINUS")}
							>
								-20
							</button>

							<div className="flex flex-col justify-center items-center">
								<span className="text-gray text-sm">
									{t("order.current_price")}
								</span>
								<p className="text-3xl">{newPrice} ₽</p>
							</div>

							<button
								className="border border-solid border-blue px-7 py-0.5 rounded-3xl text-blue font-medium"
								onClick={() => changePriceButton("PLUS")}
							>
								+20
							</button>
						</div>

						<Button
							disabled={newPrice === orderInfo.price}
							onClick={changePriceForm}
						>
							{t("order.change_price")}
						</Button>
					</div>
				</div>
			</div>

			{orders.answers.length > 0 && <AnswerList />}
			{isTooLongWait && <Dialog message={"Заказ устарел"} onClose={cancelOrder} />}
		</main>
	);
});

export default UserCitySearch;
