import { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import Navbar from "../../components/Navbar";
import { getDriverCars, deleteCar } from "../../http/userApi";
import { DRIVER_ADD_CAR_ROUTE, DRIVER_EDIT_CAR_ROUTE } from "../../utils/consts";

export default () => {
  const { t } = useTranslation();
  const [cityCars, setCityCars] = useState(null);
  const [isLoading, setLoading] = useState(true);

  useEffect(() => {
    getDriverCars("CITY").then((data) => setCityCars(data)).finally(() => {
      setLoading(false)
    });
  }, []);

  if (isLoading) {
    return null
  }

  return <main className="relative min-h-screen mx-auto">
    <Navbar title={t("menu.cars")} />

    <div>
      {cityCars ? (
        <div className="container mx-auto px-5 py-6">
          <h3 className="text-xl font-medium mb-5">
            {t("profile.car_in_city_mode")}
          </h3>

          <ul className="space-y-3 mb-5">
            <li>
              <p className="text-lg text-gray leading-none">
                {t("profile.car_model")}
              </p>
              <span className="text-lg">
                {cityCars.brand} {cityCars.model}
              </span>
            </li>

            <li>
              <p className="text-lg text-gray leading-none">
                {t("profile.car_year")}
              </p>
              <span className="text-lg">{cityCars.year}</span>
            </li>

            <li>
              <p className="text-lg text-gray leading-none">
                {t("profile.car_state_number")}
              </p>

              <div className="flex flex-row justify-between w-min bg-graylight rounded-md">
                <p className="w-24 py-1 pl-2.5">
                  {cityCars.number.substr(0, 8)}
                </p>
                <span className="w-px bg-black" />
                <p className="py-1 px-2.5">
                  {cityCars.number.substr(8, cityCars.number.length)}
                </p>
              </div>
            </li>
          </ul>

          {cityCars.status === "WAIT" && (
            <h3 className="text-lg">
              {t("profile.car_verify_wait_message")}
            </h3>
          )}

          {cityCars.status === "REJECTED" && (
            <>
              <h3 className="text-lg mb-3">
                {t("profile.car_verify_rejected_message")}
              </h3>
              <a
                href="#"
                className="border border-graylight rounded-2xl text-lg py-3 px-9"
              >
                {t("change")}
              </a>
            </>
          )}

          {cityCars.status === "OK" && (
            <div className="flex gap-3">
              <Link to={`${DRIVER_ADD_CAR_ROUTE}/city`} className="inline-block border border-graylight rounded-2xl text-lg py-3 px-9">
                {t("change")}
              </Link>

              <button
                type="button"
                className="inline-block border border-graylight rounded-2xl text-lg py-3 px-9"
                onClick={() => deleteCar("CITY").finally(() => setCityCars(null))}
              >
                {t("delete")}
              </button>
            </div>
          )}
        </div>
      ) : <div className="container mx-auto px-5 py-6">
        {/* <h3 className="text-xl font-medium mb-5">{t("modes")}</h3> */}

        <Link
          to={`${DRIVER_ADD_CAR_ROUTE}/city`}
          className="inline-block border border-graylight rounded-2xl text-lg py-4 px-9"
        >
          {t("profile.add_car")}
        </Link>
      </div>}
    </div>
  </main>
}