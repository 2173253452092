import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import InputMask from "react-input-mask";

import { changePhone } from "../../../../http/userApi";
import {
	USER_CHANGE_PHONE_CODE_ROUTE,
	USER_SETTINGS_ROUTE,
} from "../../../../utils/consts";
import { ru_icon } from "../../../../img/index";
import Navbar from "../../../../components/Navbar";
import Popup from "../../../../components/Popup/Popup";

const UserChangePhone = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [newPhone, setNewPhone] = useState("");
	const [error, setError] = useState("");
	const [popupActive, setPopupActive] = useState(false);

	const sendForm = async (e) => {
		e.preventDefault();

		const phone = newPhone.replace(/[^0-9]/g, "");
		if (phone.length !== 10) {
			setPopupActive(true);
			return setError(t("settings.incorrect_number"));
		}

		const data = await changePhone(phone);

		if (data.message) {
			setPopupActive(true);
			setError(data.message);
		} else {
			navigate(USER_CHANGE_PHONE_CODE_ROUTE);
		}
	};

	return (
		<main className="relative min-h-screen mx-auto">
			<Navbar
				title={t("settings.phone_number")}
				backLink={USER_SETTINGS_ROUTE}
			/>

			<div className="container mx-auto py-5">
				<div className="mt-10 mx-5">
					<p className="text-gray text-center">
						{t("settings.change_phone_desc")}
					</p>
				</div>

				<div className="mt-10 mx-5">
					<form className="flex flex-col" onSubmit={sendForm}>
						<div className="flex flex-row justify-center items-center mb-5">
							<label htmlFor="phone" className="flex-none w-8 h-8 mt-1">
								<img src={ru_icon} />
							</label>
							<p className="ml-2 mr-1 mt-1  text-xl">+7</p>
							<InputMask
								required
								type="tel"
								name="phone"
								id="phone"
								value={newPhone}
								onChange={(e) => setNewPhone(e.target.value)}
								mask="(999) 999-99-99"
								maskChar=" "
								className="w-full pt-2 pb-1 px-1 outline-none border-b border-gray text-xl"
								placeholder={t("settings.new_number")}
							/>
						</div>

						<button className="bg-blue rounded-2xl text-white text-xl font-medium py-4">
							{t("next")}
						</button>
					</form>
				</div>
			</div>

			<Popup active={popupActive} setActive={setPopupActive}>
				{error}
			</Popup>
		</main>
	);
};

export default UserChangePhone;
