import { useState, useRef, useEffect, forwardRef } from "react";
import ReactMapGL, { Marker, GeolocateControl } from "react-map-gl";
import mapboxgl from "mapbox-gl";

import "mapbox-gl/dist/mapbox-gl.css";

mapboxgl.workerClass =
	// eslint-disable-next-line import/no-webpack-loader-syntax
	require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

export const MapboxMap = forwardRef(
	(
		{ onGeolocate, geolocateOnMount, showGeolocateControl, children, ...props },
		forwardedRef,
	) => {
		const [isReady, setIsReady] = useState(false);
		const geolocateControlRef = useRef();

		return (
			<ReactMapGL
				ref={forwardedRef}
				mapboxAccessToken={
					"pk.eyJ1Ijoibmlrb2xheW11bGxlciIsImEiOiJjazl4bG9wdDgwZnlnM21wa21wYmd0OTZ1In0.WgJtMdaJqO_xy9lAOKO0sg"
				}
				mapStyle="mapbox://styles/mapbox/dark-v11"
				onLoad={() => {
					setIsReady(true);
					geolocateOnMount && geolocateControlRef.current.trigger();
				}}
				{...props}
			>
				{isReady ? children : null}
				<GeolocateControl
					ref={geolocateControlRef}
					onGeolocate={onGeolocate}
					positionOptions={{
						enableHighAccuracy: true,
					}}
					trackUserLocation={true}
					showUserHeading={true}
					position="bottom-right"
					style={{ display: showGeolocateControl ? "block" : "none" }}
				/>
			</ReactMapGL>
		);
	},
);

export const Mapbox = Object.assign(MapboxMap, { Marker });
