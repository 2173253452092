import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { POLICY_ROUTE, RULES_ROUTE } from "../../../utils/consts";
import Navbar from "../../../components/Navbar";

const UserAbout = () => {
	const { t } = useTranslation();

	return (
		<main className="relative min-h-screen mx-auto">
			<Navbar title={t("settings.about_service")} />

			<div className="container mx-auto py-5">
				<div className="flex flex-col mx-5">
					<ul className="text-xl space-y-2">
						<li className="py-3">
							<Link to={RULES_ROUTE}>{t("settings.rules")}</Link>
						</li>
						<li className="py-3">
							<Link to={POLICY_ROUTE}>{t("settings.policy")}</Link>
						</li>
					</ul>
				</div>
			</div>
		</main>
	);
};

export default UserAbout;
