import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { checkVerify, getDriverCars, getInfo } from "../../../http/userApi";
import {
	DRIVER_ADD_CAR_ROUTE,
	DRIVER_PROFILE_EDIT_ROUTE,
	DRIVER_VERIFY_ROUTE,
	// USER_PROFILE_EDIT_ROUTE,
} from "../../../utils/consts";
import {
	// phone,
	// avatar_placeholder,
	// cargo_icon,
	city_icon,
	// faeces_icon,
	// hamburger_icon,
	// intercity_icon,
	star_icon,
	// water_icon,
	// mail_icon,
} from "../../../img/index";
import Navbar from "../../../components/Navbar";
import Loader from "../../../components/Loader";
import Avatar from "../../../components/Avatar";

const DriverProfile = () => {
	const { t } = useTranslation();

	const [verifyState, setVerify] = useState(null);
	const [userInfo, setUserInfo] = useState(null);
	const [loading, setLoading] = useState(true);
	const [cityCars, setCityCars] = useState(null);
	const [mode, setMode] = useState("city");

	useEffect(() => {
		checkVerify().then((data) => {
			if (data) setVerify(data.status);
		});
		getDriverCars("CITY").then((data) => setCityCars(data));
		getInfo()
			.then((data) => {
				setUserInfo(data);
			})
			.finally(() => setLoading(false));
	}, []);

	if (loading) {
		return <Loader />;
	}

	return (
		<main className="relative min-h-screen mx-auto">
			<Navbar title={t("profile.title")} />

			<div className="divide-y divide-graylight">
				<div className="container mx-auto px-5 py-6">
					<div className="flex flex-row items-center mb-5">
						<Avatar
							size="md"
							src={
								userInfo.avatar
									? process.env.REACT_APP_API_URL + userInfo.avatar
									: undefined
							}
							className="mr-3"
						/>

						<div className="flex flex-col justify-center">
							<h2 className="text-xl">
								{userInfo.firstName ? userInfo.firstName : `+7${userInfo.phone}`}
							</h2>

							<div className="flex flex-row">
								<img src={star_icon} className="w-4 h-4 mt-0.5" />
								<p className="ml-1 text-gray">
									{userInfo.rating ? userInfo.rating.substr(0, 4) : "0.00"}
								</p>
							</div>
						</div>
					</div>

					<ul className="space-y-3 mb-5">
						<li>
							<p className="text-lg text-gray leading-none">{t("phone")}</p>
							<span className="text-lg">+7{userInfo.phone}</span>
						</li>
					</ul>

					<Link
						to={DRIVER_PROFILE_EDIT_ROUTE}
						className="inline-block border border-graylight rounded-2xl text-lg py-2.5 px-9"
						type="button"
					>
						{t("change")}
					</Link>
				</div>

				{/* {verifyState == null && (
					<div className="container mx-auto px-5 py-6">
						<h3 className="text-xl font-medium mb-5">
							{t("profile.verify_cta_message")}
						</h3>

						<Link
							to={DRIVER_VERIFY_ROUTE}
							className="border border-graylight rounded-2xl text-lg py-3 px-9"
						>
							{t("register")}
						</Link>
					</div>
				)} */}

				{/* {verifyState == "WAIT" && (
					<div className="container mx-auto px-5 py-6">
						<h3 className="text-lg">{t("profile.verify_wait_message")}</h3>
					</div>
				)} */}

				{/* {verifyState == "REJECTED" && (
					<div className="container mx-auto px-5 py-6">
						<h3 className="text-lg mb-3">
							{t("profile.verify_rejected_message")}
						</h3>
						<Link
							to={DRIVER_VERIFY_ROUTE}
							className="border border-graylight rounded-2xl text-lg py-3 px-9"
						>
							{t("register")}
						</Link>
					</div>
				)} */}

				{/* <div className="container mx-auto px-5 py-6">
				<p className="text-sm">
					{t("profile.support_cta")}{" "}
					<span className="text-blue">{t("support")}</span>
				</p>

				<ul className="flex space-x-2.5 mt-3">
					<li className="flex flex-row">
						<span>
							<img src={phone} alt="phone" />
						</span>
						 <p className="text-sm text-blue ml-1.5"></p> 
					</li>
					
					<li className="flex flex-row items-center">
						<span>
							<img src={mail_icon} alt="mail" />
						</span>
						<p className="text-sm text-blue ml-1.5"></p> 
					</li>
				</ul>
			</div> */}
			</div>
		</main>
	);
};

export default DriverProfile;
