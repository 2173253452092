import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

import AppRouter from "./components/AppRouter";
import UpdateDialog from "./components/UpdateDialog";
import Button from "./components/Button";
import { AppContext } from "./contexts/AppContext";
import { useGeolocation } from "./contexts/GeolocationContext";
import { check, getInfo } from "./http/userApi";
import Loader from "./components/Loader";
import { AUTH_COMPLETION_ROUTE } from "./utils/consts";
import { isIOS } from "./utils/isIOS";

const IOSBanner = () => {
	const [isShowing, setIsShowing] = useState(
		() => !localStorage.getItem("ios_banner_closed"),
	);
	if (!isIOS() || !isShowing) return null;

	const closeBanner = () => {
		setIsShowing(false);
		localStorage.setItem("ios_banner_closed", true);
	};

	return (
		<div className="p-4 flex flex-row border-b border-graylight">
			<div>
				<img
					width={88}
					src="/apple-touch-icon-60x60-precomposed.png"
					alt="app icon"
				/>
			</div>

			<div className="ml-4">
				<p>Добавить uprider на экран «Домой»</p>
				<p className="mt-1 text-sm text-gray">
					Нажмите на иконку «Поделиться» и в меню выберите «На экран „Домой“»
				</p>
			</div>

			<div className="pl-3">
				<button onClick={closeBanner} className="text-gray">
					<svg
						xmlns="http://www.w3.org/2000/svg"
						fill="none"
						viewBox="0 0 24 24"
						strokeWidth={1.5}
						stroke="currentColor"
						className="w-6 h-6"
					>
						<path
							strokeLinecap="round"
							strokeLinejoin="round"
							d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
						/>
					</svg>
				</button>
			</div>
		</div>
	);
};

const GeolocationDialog = () => {
	const geolocation = useGeolocation();
	const [isShow, setIsShow] = useState(true);

	const requestGeolocation = () => {
		geolocation.getPosition();
	};

	const close = () => {
		setIsShow(false);
	};

	if (geolocation.isEnabled || !isShow) {
		return null;
	}

	return (
		<div className="fixed inset-0 z-50 bg-paper flex flex-col items-center justify-center p-5 z-40">
			<button onClick={close} className="absolute top-4 right-4">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="currentColor"
					className="w-6 h-6"
				>
					<path
						fillRule="evenodd"
						d="M5.47 5.47a.75.75 0 011.06 0L12 10.94l5.47-5.47a.75.75 0 111.06 1.06L13.06 12l5.47 5.47a.75.75 0 11-1.06 1.06L12 13.06l-5.47 5.47a.75.75 0 01-1.06-1.06L10.94 12 5.47 6.53a.75.75 0 010-1.06z"
						clipRule="evenodd"
					/>
				</svg>
			</button>

			<svg
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 24 24"
				fill="currentColor"
				className="w-24 h-24 text-blue"
			>
				<path
					fillRule="evenodd"
					d="M11.54 22.351l.07.04.028.016a.76.76 0 00.723 0l.028-.015.071-.041a16.975 16.975 0 001.144-.742 19.58 19.58 0 002.683-2.282c1.944-1.99 3.963-4.98 3.963-8.827a8.25 8.25 0 00-16.5 0c0 3.846 2.02 6.837 3.963 8.827a19.58 19.58 0 002.682 2.282 16.975 16.975 0 001.145.742zM12 13.5a3 3 0 100-6 3 3 0 000 6z"
					clipRule="evenodd"
				/>
			</svg>

			<p className="text-lg text-center mt-5">
				Для корректной работы приложения требуется доступ к геолокации
			</p>

			<div className="mt-4">
				{geolocation.positionError.code === 1 ? (
					<div className="rounded-md p-1 px-3 bg-[#212121] flex">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="currentColor"
							className="w-10 h-10 text-white/30"
						>
							<path
								fillRule="evenodd"
								d="M9.401 3.003c1.155-2 4.043-2 5.197 0l7.355 12.748c1.154 2-.29 4.5-2.599 4.5H4.645c-2.309 0-3.752-2.5-2.598-4.5L9.4 3.003zM12 8.25a.75.75 0 01.75.75v3.75a.75.75 0 01-1.5 0V9a.75.75 0 01.75-.75zm0 8.25a.75.75 0 100-1.5.75.75 0 000 1.5z"
								clipRule="evenodd"
							/>
						</svg>

						<p className="text-sm leading-5 p-2">
							Проверьте настройки сайта и разрешите доступ к геолокации вручную
						</p>
					</div>
				) : (
					<Button
						size="sm"
						// disabled={geolocation.positionError.code === 1}
						onClick={requestGeolocation}
					>
						Разрешить
					</Button>
				)}
			</div>
		</div>
	);
};

const App = observer(() => {
	const { i18n, t } = useTranslation();
	const { user } = useContext(AppContext);
	const [loading, setLoading] = useState(true);
	const [showCookieMessage, setShowCookieMessage] = useState(() => {
		return !localStorage.getItem("accept_cookies");
	});

	const acceptCookie = () => {
		localStorage.setItem("accept_cookies", "true");
		setShowCookieMessage(false);
	};

	function refreshSession() {
		check()
			.then(getInfo)
			.then((info) => {
				if (
					window.location.pathname !== AUTH_COMPLETION_ROUTE &&
					(!info.firstName)
				) {
					window.location.href = AUTH_COMPLETION_ROUTE;
				} else {
					user.setUser(info);
					i18n.changeLanguage(info.language);

					setLoading(false);
				}
			})
			.catch(() => {
				setLoading(false);
				localStorage.removeItem("token");
				localStorage.removeItem("refresh_token");
			});
	}

	useEffect(() => {
		refreshSession();

		const timer = setInterval(refreshSession, 1000 * 60 * 60 - 3000);

		return () => {
			clearInterval(timer);
		};
	}, []);

	if (loading) {
		return <Loader />;
	}

	return (
		<>
			{user.user.id && <GeolocationDialog />}

			<IOSBanner />

			<UpdateDialog />

			<BrowserRouter>
				<AppRouter />
			</BrowserRouter>

			{showCookieMessage && (
				<div className="fixed bottom-0 w-screen z-30 p-3 bg-blue text-white">
					<p className="max-w-2xl text-sm">{t("accept_cookie_message")}</p>
					<button onClick={acceptCookie} className="font-bold mt-3">
						{t("continue")}
					</button>
				</div>
			)}
		</>
	);
});

export default App;
