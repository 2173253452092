import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";

import Navbar from "../../../components/Navbar";
import { getInfo, setInfo } from "../../../http/userApi";
import {
	DRIVER_CABINET_INCOME_ROUTE,
	DRIVER_CABINET_ROUTE,
} from "../../../utils/consts";
import Loader from "../../../components/Loader";

ReactModal.setAppElement("#root");

const DriverCabinet = () => {
	const { t } = useTranslation();
	const [personal, setPersonal] = useState("");
	const [driverPhone, setDriverPhone] = useState("");
	const [newDriverPhone, setNewDriverPhone] = useState("");
	const [loading, setLoading] = useState("");
	const [showModalPhone, setShowModalPhone] = useState(false);

	useEffect(() => {
		getInfo()
			.then((data) => {
				setPersonal(data.personal);
				setDriverPhone(data.driverPhone);
				setNewDriverPhone(data.driverPhone);
			})
			.finally(() => setLoading(false));
	}, []);

	if (loading) {
		return <Loader />;
	}

	const changeDriverPhone = async () => {
		let formData = new FormData();
		formData.append("driverPhone", newDriverPhone);
		const data = await setInfo(formData);
		window.location.href = DRIVER_CABINET_ROUTE;
	};

	return (
		<main className="relative min-h-screen mx-auto">
			<Navbar title={t("cabinet.title")} />

			<div className="container mx-auto px-5 py-5">
				<ul className="space-y-3 text-lg">
					<li>
						<p className="text-gray">{t("cabinet.account")}</p>
						<span>{personal}</span>
					</li>
					<li onClick={() => setShowModalPhone(true)}>
						<p className="text-gray">{t("cabinet.transaction_number")}</p>
						<span className="text-blue border-b border-blue border-solid">
							+7{driverPhone}
						</span>
					</li>
					{/* <li>
         <p className="text-gray">{t('cabinet.income')}</p>
        <a href={DRIVER_CABINET_INCOME_ROUTE} className="text-blue border-b border-blue border-solid">{t('look')}</a>
        </li> */}
				</ul>
			</div>

			<ReactModal
				isOpen={showModalPhone}
				onRequestClose={() => setShowModalPhone(false)}
				className="fixed bottom-0 w-full flex flex-col justify-between bg-paper px-5 pt-5 rounded-xl shadow-map outline-none"
				overlayClassName="fixed inset-0 bg-overlay"
			>
				<h1 className="text-center text-xl">
					{t("cabinet.transaction_number")}
				</h1>
				<div className="flex flex-col space-y-5 mt-5 mb-10">
					<input
						type="text"
						name="driverPhone"
						id="driverPhone"
						autoFocus={true}
						defaultValue={driverPhone}
						onChange={(e) => setNewDriverPhone(e.target.value)}
						className="w-full outline-none py-2 px-3 shadow-history rounded-xl text-xl"
						placeholder={t("cabinet.transaction_number")}
					/>
				</div>
				<div className="flex flex-row justify-between">
					<button
						onClick={() => changeDriverPhone()}
						className="w-full py-3 text-xl text-blue"
					>
						{t("save")}
					</button>
				</div>
			</ReactModal>
		</main>
	);
};

export default DriverCabinet;
