import { $authHost, $host } from "./index";
import jwt_decode from "jwt-decode";
// import FingerprintJS from "@fingerprintjs/fingerprintjs";

// const fpPromise = FingerprintJS.load();

export const authSendCode = async (phone) => {
	const { data } = await $host.post("api/user/authSendCode", { phone });
	localStorage.setItem("phone", data.phone);
	return data.phone;
};

export const getUsersInfo = async () => {
	const { data } = await $host.get("api/user/usersInfo");
	return data;
};

export const authCheckCode = async (phone, code) => {
	const { data } = await $host.post("api/user/authCheckCode", { phone, code });
	if (data.message) {
		return data;
	} else {
		localStorage.setItem("token", data.token);
		localStorage.setItem("refresh_token", data.refreshToken);
		return jwt_decode(data.token);
	}
};

export const getInfo = async () => {
	const { data } = await $authHost.get("api/user/getInfo");
	return data;
};

export const setInfo = async (info) => {
	const { data } = await $authHost.post("api/user/setInfo", info);
	return data;
};

export const changeRole = async (role) => {
	const { data } = await $authHost.post("api/user/changeRole", { role });
	localStorage.setItem("token", data.token);
	return jwt_decode(data.token);
};

export const changePhone = async (newPhone) => {
	const { data } = await $authHost.post("api/user/changePhone", { newPhone });
	if (data.message) {
		return data;
	} else {
		localStorage.setItem("newPhone", data.newPhone);
		return data;
	}
};

export const changePhoneCode = async (newPhone, code) => {
	const { data } = await $authHost.post("api/user/changePhoneCode", {
		newPhone,
		code,
	});
	if (data.token) {
		localStorage.setItem("phone", localStorage.getItem("newPhone"));
		localStorage.setItem("token", data.token);
		localStorage.removeItem("newPhone");
		return jwt_decode(data.token);
	}
	return data;
};

export const checkVerify = async () => {
	const { data } = await $authHost.get("api/user/checkVerify");
	return data;
};

export const sendVerify = async (info) => {
	const { data } = await $authHost.post("api/user/sendVerify", info);
	return data;
};

export const addCar = async (info) => {
	const { data } = await $authHost.post("api/user/addCar", info);
	return data;
};

export const changeCar = async (info) => {
	const { data } = await $authHost.post("api/user/changeCar", info);
	return data;
};

export const deleteCar = async (type) => {
	const { data } = await $authHost.post("api/user/deleteCar", { type });
	return data;
};

export const getDriverCars = async (type) => {
	const { data } = await $authHost.post("api/user/getDriverCars", { type });
	return data;
};

export const setReviewUser = async (orderId, userId, rate, review) => {
	const { data } = await $authHost.post("api/user/setReviewUser", {
		orderId,
		userId,
		rate,
		review,
	});

	localStorage.removeItem("active_order");
	localStorage.removeItem("order_status");

	return data;
};

export const setReviewDriver = async (orderId, userId, rate, review) => {
	const { data } = await $authHost.post("api/user/setReviewDriver", {
		orderId,
		userId,
		rate,
		review,
	});
	return data;
};

export const check = async () => {
	const { data } = await $authHost.get("api/user/check", {
		params: { refresh_token: localStorage.getItem('refresh_token') }
	});
	localStorage.setItem("token", data.token);
	localStorage.setItem("refresh_token", data.refreshToken);
	return jwt_decode(data.token);
};

export const pushRegister = async (sub) => {
	// const fp = await fpPromise;
	// const result = await fp.get();
	const { data } = await $authHost.post("api/user/push/register", sub);
	return data;
};

export const pushUnregister = async (sub) => {
	const { data } = await $authHost.post("api/user/push/unregister", sub);
	return data;
};

// DEV

export const getCode = async (phone) => {
	const { data } = await $host.post("api/user/getCode", { phone });
	return data;
};
