import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useSound from "use-sound";
import cx from "classnames"

import {
	changeAnswerStatus,
	changePrice,
	changeStatus,
	getDriverCar,
	getDriverInfo,
	setOrderDriver,
} from "../http/orderApi";
import { star_icon, user_icon } from "../img";
import Avatar from "./Avatar";
import Button from "./Button";
import { USER_CITY_GO_ROUTE, USER_CITY_WAIT_ROUTE } from "../utils/consts";
import NotifySfx from "../sounds/music_marimba_chord.mp3";

// const TIMEOUT = 1000 * 15

const AnswerItem = ({ answer, onCancel }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [driverInfo, setDriverInfo] = useState({});
	const [driverCar, setDriverCar] = useState({});
	const [loading, setLoading] = useState(true);
	const [hidden, setHidden] = useState(false);
	const [time, setTime] = useState(15);
	const isAcceptedRef = useRef(false);
	const [playNotify] = useSound(NotifySfx);

	const cancelOrder = async () => {
		if (isAcceptedRef.current === false) {
			await changeAnswerStatus(answer.id, 0);
			onCancel();
		}
	};

	const acceptOrder = async () => {
		isAcceptedRef.current = true;
		await changeAnswerStatus(answer.id, 2);
		await changeStatus(answer.orderId, 2);
		await setOrderDriver(answer.orderId, answer.userId, answer.time);
		await changePrice(answer.orderId, answer.price);
		return navigate(`${USER_CITY_GO_ROUTE}/${answer.orderId}`);
	};

	useEffect(() => {
		playNotify();
	}, [playNotify]);

	useEffect(() => {
		getDriverCar(answer.userId, "CITY").then((data) => setDriverCar(data));
		getDriverInfo(answer.userId)
			.then((data) => setDriverInfo(data))
			.finally(setLoading(false));
	}, []);

	useEffect(() => {
		if (time > 0) {
			const timer = setTimeout(() => setTime(time - 0.1), 100);

			return () => {
				clearTimeout(timer);
			};
		} else {
			setHidden(true);
		}
	}, [time]);

	if (loading) {
		return "Loading...";
	}

	if (hidden) {
		return null;
	}

	return (
		<li className={cx("bg-paper rounded-xl flex flex-col p-2.5 space-y-1.5 shadow-history", answer.isNavigatorMode && "ring ring-red")}>
			<div>
				<span
					className="rounded-full transition-all h-1 bg-blue block"
					style={{
						width: `calc(100% / 15 * ${time})`,
					}}
				/>
			</div>

			<div className="flex">
				<div className="mr-2">
					<Avatar
						src={
							driverInfo.avatar
								? process.env.REACT_APP_API_URL + driverInfo.avatar
								: undefined
						}
					/>
				</div>

				<div className="flex justify-between w-full">
					<div className="flex flex-col justify-start text-sm">
						<p>
							{driverCar.brand} {driverCar.model}
						</p>

						<p className="text-xs">
							{driverInfo.firstName} {driverInfo.lastName}
						</p>

						<div className="flex justify-start items-center space-x-1 mt-1">
							<span className="w-3 h-3">
								<img src={star_icon} />
							</span>

							<p className="leading-3 text-xs">
								{driverInfo.rating ? driverInfo.rating.substr(0, 4) : "0.00"}{" "}
								<span className="text-gray">({driverInfo.orders})</span>
							</p>
						</div>
					</div>

					<div className="flex flex-col justify-start text-right  text-sm">
						<p className="text-lg font-medium text-blue leading-none">
							{answer.price} ₽
						</p>
						<p>{answer.time} {t('time.minute')}</p>
						{answer.isNavigatorMode && <p className="text-xs">Не один</p>}
						{/* <p>500 м</p> */}
					</div>
				</div>
			</div>

			<div className="flex flex-row justify-between space-x-3">
				<Button
					size="sm"
					dangerous
					className="flex-1"
					onClick={cancelOrder}
				>
					{t("reject")}
				</Button>

				<Button
					size="sm"
					className="flex-1"
					onClick={acceptOrder}
				>
					{t("accept")}
				</Button>
			</div>
		</li>
	);
};

export default AnswerItem;
