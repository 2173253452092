import React, { useState, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import Button from "./Button";
import Avatar from "./Avatar";
import { changeRole } from "../http/userApi";
import { AppContext } from "../contexts/AppContext";
import {
	arrow_right_icon,
	city_menu_icon,
	hamburger_icon,
	history_driver_icon,
	home_icon,
	settings_icon,
	star_icon,
	tg_icon,
	time_icon,
	user_icon,
	vk_icon,
} from "../img";
import {
	DRIVER_CABINET_ROUTE,
	DRIVER_CITY_START_ROUTE,
	DRIVER_PROFILE_ROUTE,
	DRIVER_SETTINGS_ROUTE,
	DRIVER_HISTORY_ROUTE,
	USER_CITY_START_ROUTE,
	USER_HISTORY_ROUTE,
	USER_PROFILE_ROUTE,
	USER_SETTINGS_ROUTE,
	DRIVER_GARAGE_ROUTE,
} from "../utils/consts";

const Menu = () => {
	const { t } = useTranslation();
	const { user } = useContext(AppContext);
	const [isActive, setIsActive] = useState(false);
	const [pending, setPending] = useState(false);

	const toggleMenu = () => setIsActive((state) => !state);

	const changeRoleDriver = async () => {
		if (user.user.role === "USER") {
			setPending(true);
			await changeRole("DRIVER");
			window.location.href = DRIVER_CITY_START_ROUTE;
		}
	};

	const changeRoleUser = async () => {
		if (user.user.role === "DRIVER") {
			setPending(true);
			await changeRole("USER");
			return (window.location.href = USER_CITY_START_ROUTE);
		}
	};

	return (
		<>
			<button className="absolute top-0 left-0 z-30" onClick={toggleMenu}>
				<span className="w-10 h-10 rounded-full flex justify-center items-center mt-2.5 ml-2.5 bg-paper">
					<img src={hamburger_icon} className="w-6" />
				</span>
			</button>

			<button
				className={
					isActive
						? "block fixed top-0 left-0 w-full h-full bg-black/[.8] z-50 ease-in duration-300 opacity-100"
						: "hidden fixed top-0 left-0 w-full h-full bg-black/[.0] z-0 ease-in duration-300 "
				}
				onClick={toggleMenu}
			/>

			<div
				className={
					isActive
						? "fixed letf-0 top-0 min-h-screen w-72 bg-paper ease-in duration-300  translate-x-0 z-50"
						: "fixed letf-0 top-0 min-h-screen w-72 bg-paper ease-in duration-300 -translate-x-80 z-50"
				}
			>
				{user.user.role === "USER" && (
					<div className="flex flex-col">
						<div className="px-5 py-3 mb-5 border-b border-graylight">
							<Link
								to={USER_PROFILE_ROUTE}
								className="flex flex-row justify-between items-center"
							>
								<div className="flex flex-row">
									<Avatar
										src={
											user.user.avatar
												? process.env.REACT_APP_API_URL + user.user.avatar
												: undefined
										}
										className="mr-3"
									/>

									<div className="flex flex-col justify-center">
										<h3 className="text-xl">
											{user.user.firstName
												? user.user.firstName
												: `+7${user.user.phone}`}
										</h3>

										<div className="flex flex-row items-center">
											<img src={star_icon} className="mb-0.5" />
											<p className="ml-1.5 leading-none text-gray">
												{t("menu.rating", {
													rating: user.user.rating
														? user.user.rating.substr(0, 4)
														: "0.00",
													orders: user.user.orders,
												})}
											</p>
										</div>
									</div>
								</div>

								<div className="h-5">
									<img src={arrow_right_icon} />
								</div>
							</Link>
						</div>

						<div className="flex flex-col justify-between px-5 mb-5">
							<ul className="text-xl mb-5 space-y-4 mx-2.5">
								<li>
									<Link
										to={USER_CITY_START_ROUTE}
										onClick={toggleMenu}
										className="flex flex-row"
									>
										<div className="flex w-7 h-7 mr-5">
											<img src={home_icon} />
										</div>
										{t("menu.home")}
									</Link>
								</li>
								<li>
									<Link
										to="#"
										onClick={toggleMenu}
										className="flex flex-row"
									>
										<div className="flex w-7 h-7 mr-5">
											<img src={city_menu_icon} />
										</div>
										Тех. Помощь
									</Link>
								</li>

								<li>
									<a
										href="https://forum.uprider.ru/"
										onClick={toggleMenu}
										className="flex flex-row"
									>
										<div className="flex w-7 h-7 mr-5">
											<img src={history_driver_icon} />
										</div>
										Форум АвтоКлуба
									</a>
								</li>

								<li>
									<Link
										to="#"
										onClick={toggleMenu}
										className="flex flex-row"
									>
										<div className="flex w-7 h-7 mr-5">
											<img src={city_menu_icon} />
										</div>
										Авторынок
									</Link>
								</li>
								<li>
									<Link
										to={USER_HISTORY_ROUTE}
										onClick={toggleMenu}
										className="flex flex-row"
									>
										<div className="flex w-7 h-7 mr-5">
											<img src={time_icon} />
										</div>
										{t("menu.history")}
									</Link>
								</li>


								<li>
									<Link
										to={USER_SETTINGS_ROUTE}
										onClick={toggleMenu}
										className="flex flex-row"
									>
										<div className="flex w-7 h-7 mr-5">
											<img src={settings_icon} />
										</div>
										{t("menu.settings")}
									</Link>
								</li>
							</ul>

							<Button loading={pending} onClick={changeRoleDriver}>
								{t("menu.driver_mode")}
							</Button>

							<div className="flex flex-row justify-center items-center space-x-4 my-8">
								<a
									className="w-12 h-12"
									href="https://vk.com/public219986929"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={vk_icon} />
								</a>
								<a
									className="w-12 h-12"
									href="https://t.me/+oUJNdtT3KW9kYTQy"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={tg_icon} />
								</a>
							</div>
						</div>
					</div>
				)}

				{user.user.role === "DRIVER" && (
					<div className="flex flex-col">
						<div className="px-5 py-3 mb-5 border-b border-graylight">
							<Link
								to={DRIVER_PROFILE_ROUTE}
								className="flex flex-row justify-between items-center"
							>
								<div className="flex flex-row">
									<Avatar
										className="mr-3"
										src={
											user.user.avatar
												? process.env.REACT_APP_API_URL + user.user.avatar
												: undefined
										}
									/>

									<div className="flex flex-col justify-center">
										<h3 className="text-xl">
											{user.user.firstName
												? user.user.firstName
												: `+7${user.user.phone}`}
										</h3>

										<div className="flex flex-row items-center">
											<img src={star_icon} className="mb-0.5" />
											<p className="ml-1.5 leading-none text-gray">
												{t("menu.rating", {
													rating: user.user.rating
														? user.user.rating.substr(0, 4)
														: "0.00",
													orders: user.user.driverOrders,
												})}
											</p>
										</div>
									</div>
								</div>

								<div className="h-5">
									<img src={arrow_right_icon} />
								</div>
							</Link>
						</div>

						<div className="flex flex-col justify-between px-5 mb-5">
							<ul className="text-xl mb-5 space-y-4 mx-2.5">
								<li>
									<Link
										to={DRIVER_CITY_START_ROUTE}
										onClick={toggleMenu}
										className="flex flex-row"
									>
										<div className="flex w-7 h-7 mr-5">
											<img src={home_icon} />
										</div>
										{t("menu.city")}
									</Link>
								</li>
								<li>
									<Link
										to={DRIVER_GARAGE_ROUTE}
										onClick={toggleMenu}
										className="flex flex-row"
									>
										<div className="flex w-7 h-7 mr-5">
											<img src={city_menu_icon} />
										</div>
										{t("menu.cars")}
									</Link>
								</li>
								<li>
									<Link
										to={DRIVER_CABINET_ROUTE}
										onClick={toggleMenu}
										className="flex flex-row"
									>
										<div className="flex w-7 h-7 mr-5">
											<img src={user_icon} />
										</div>
										{t("menu.cabinet")}
									</Link>
								</li>
								<li>
									<Link to={DRIVER_HISTORY_ROUTE} className="flex flex-row">
										<div className="flex w-7 h-7 mr-5">
											<img src={history_driver_icon} />
										</div>
										{t("menu.history")}
									</Link>
								</li>
								<li>
									<Link
										to={DRIVER_SETTINGS_ROUTE}
										onClick={toggleMenu}
										className="flex flex-row"
									>
										<div className="flex w-7 h-7 mr-5">
											<img src={settings_icon} />
										</div>
										{t("menu.settings")}
									</Link>
								</li>
							</ul>

							<Button loading={pending} onClick={changeRoleUser}>
								{t("menu.client_mode")}
							</Button>

							<div className="flex flex-row justify-center items-center space-x-4 my-8">
								<a
									className="w-12 h-12"
									href="https://vk.com/public219986929"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={vk_icon} />
								</a>
								<a
									className="w-12 h-12"
									href="https://t.me/+oUJNdtT3KW9kYTQy"
									target="_blank"
									rel="noopener noreferrer"
								>
									<img src={tg_icon} />
								</a>
							</div>
						</div>
					</div>
				)}
			</div>
		</>
	);
};

export default Menu;
