import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import InputCode from "../../../../js/InputCode";
import { changePhoneCode } from "../../../../http/userApi";
import {
	DRIVER_SETTINGS_ROUTE,
	USER_SETTINGS_ROUTE,
} from "../../../../utils/consts";
import { hamburger_icon } from "../../../../img/index";

const DriverChangePhoneCode = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const newPhone = localStorage.getItem("newPhone");
	const [message, setMessage] = useState("");

	if (!newPhone) {
		window.location.href = DRIVER_SETTINGS_ROUTE;
	}

	const sendForm = async (code) => {
		const regPhone = newPhone.replace(/[^0-9]/g, "");
		if (regPhone.length !== 10) {
			return setMessage(t("settings.incorrect_number"));
		}

		const data = await changePhoneCode(regPhone, code);

		if (data.message) {
			return setMessage(data.message);
		} else window.location.href = DRIVER_SETTINGS_ROUTE;
	};

	return (
		<main className="relative min-h-screen mx-auto">
			<div className="fixed top-0 left-0 right-0 bg-paper flex flex-row justify-between items-center h-14 px-5 shadow-md z-10">
				<a href={USER_SETTINGS_ROUTE}>
					<span className="flex w-7 h-3.5">
						<img src={hamburger_icon} />
					</span>
				</a>
				<h1 className="text-xl font-medium">{t("settings.phone_number")}</h1>
				<span className="flex w-7 h-3.5" />
			</div>

			<div className="container mx-auto py-16">
				<div className="mt-10 mx-5">
					<p className="text-gray text-center">
						{t("settings.change_phone_desc")}
					</p>
				</div>

				<div className="mt-10 mb-5">
					<p className="text-center text-lg font-medium">{message}</p>
				</div>

				<div className="mt-10 mx-5">
					<form className="flex flex-col">
						<label className="text-center text-lg">
							{t("auth.sms_code")}
						</label>

						<div className="flex flex-row justify-center items-center mb-5">
							<InputCode
								length={6}
								onComplete={(code) => {
									sendForm(code);
								}}
							/>
						</div>

						<button className="bg-blue rounded-2xl text-white text-xl font-medium py-4">
							{t("next")}
						</button>
					</form>
				</div>
			</div>
		</main>
	);
};

export default DriverChangePhoneCode;
