import React from 'react'
import { hamburger_icon } from '../img'
import Menu from './Menu'

const Navbar = ({ title }) => {
  return (
    <>
      <Menu />
      <div className="sticky top-0 left-0 right-0 bg-paper flex flex-row justify-center items-center shadow h-14 z-10">
        <h1 className="text-xl font-medium">{title}</h1>
      </div>
    </>
  )
}

export default Navbar
