import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getInfo } from "../../../http/userApi";
import { USER_PROFILE_EDIT_ROUTE } from "../../../utils/consts";
import { star_icon } from "../../../img/index";
import Navbar from "../../../components/Navbar";
import Loader from "../../../components/Loader";
import Avatar from "../../../components/Avatar";

const UserProfile = () => {
	const { t } = useTranslation();
	const [userInfo, setUserInfo] = useState(null);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getInfo()
			.then((data) => {
				setUserInfo(data);
			})
			.finally(() => setLoading(false));
	}, []);

	if (loading) {
		return <Loader />;
	}

	return (
		<main className="relative min-h-screen mx-auto">
			<Navbar title={t("profile.title")} />

			<div className="container mx-auto px-5 py-5">
				<div className="flex flex-row items-center">
					<Avatar
						size="md"
						src={
							userInfo.avatar
								? process.env.REACT_APP_API_URL + userInfo.avatar
								: undefined
						}
						className="mr-3"
					/>

					<div className="flex flex-col justify-center">
						<h2 className="text-xl">
							{userInfo.firstName ? userInfo.firstName : `+7${userInfo.phone}`}
						</h2>

						<div className="flex flex-row">
							<img src={star_icon} className="w-4 h-4 mt-0.5" />
							<p className="ml-1 text-gray">
								{userInfo.rating ? userInfo.rating.substr(0, 4) : "0.00"}
							</p>
						</div>
					</div>
				</div>
				<ul className="space-y-3 mt-5">
					<li>
						<p className="text-lg text-gray leading-none">{t("phone")}</p>
						<span className="text-lg">+7{userInfo.phone}</span>
					</li>
				</ul>

				<div className="mt-6">
					<Link
						to={USER_PROFILE_EDIT_ROUTE}
						className="border border-gray rounded-2xl text-lg py-2.5 px-9"
					>
						{t("change")}
					</Link>
				</div>
			</div>
		</main>
	);
};

export default UserProfile;
