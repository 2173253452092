import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import ReactModal from "react-modal";

import { DRIVER_PROFILE_ROUTE, POLICY_ROUTE } from "../../../utils/consts";
import {
	arrow_right_icon,
	check_mark_icon,
	passport,
	license,
} from "../../../img/index";
import { checkVerify, sendVerify } from "../../../http/userApi";
import Navbar from "../../../components/Navbar";
import Button from "../../../components/Button";

ReactModal.setAppElement("#root");

const DriverVerify = () => {
	const { t } = useTranslation();
	const [passFile, setPass] = useState();
	const [licenseFile, setLicense] = useState();
	const [showModalPass, setShowModalPass] = useState(false);
	const [showModalLicense, setShowModalLicense] = useState(false);

	useEffect(() => {
		checkVerify().then((data) => {
			if (data.status || data.status !== "REJECTED")
				window.location.href = DRIVER_PROFILE_ROUTE;
		});
	}, []);

	const selectPass = (e) => {
		setPass(e.target.files[0]);
		setShowModalPass(false);
	};

	const selectLicense = (e) => {
		setLicense(e.target.files[0]);
		setShowModalLicense(false);
	};

	const sendForm = async () => {
		if (!(passFile && licenseFile)) return;
		const formData = new FormData();
		formData.append("passFile", passFile);
		formData.append("licenseFile", licenseFile);
		const data = await sendVerify(formData);
		window.location.href = DRIVER_PROFILE_ROUTE;
	};

	return (
		<main className="relative min-h-screen mx-auto">
			<Navbar title={t("register")} backLink={DRIVER_PROFILE_ROUTE} />

			<div className="container mx-auto px-5 py-5">
				<ul className="space-y-3 my-5">
					<li
						className="flex flex-row justify-between items-center py-2.5"
						onClick={() => setShowModalPass(true)}
					>
						<p>{t("profile.id_confirmation")}</p>
						<span>
							<img
								src={passFile ? check_mark_icon : arrow_right_icon}
								className="h-5"
							/>
						</span>
					</li>
					<li
						className="flex flex-row justify-between items-center py-2.5"
						onClick={() => setShowModalLicense(true)}
					>
						<p>{t("profile.additional_document")}</p>
						<span>
							<img
								src={licenseFile ? check_mark_icon : arrow_right_icon}
								className="h-5"
							/>
						</span>
					</li>
				</ul>

				<Button
					disabled={!(passFile && licenseFile)}
					className="w-full"
					onClick={() => sendForm()}
				>
					{t("complete")}
				</Button>

				<p className="my-6 text-xs text-gray font-bold text-center">
					<Trans
						i18nKey="profile.comfirm_message"
						components={{
							a: <Link to={POLICY_ROUTE} className="underline" />,
						}}
					/>
				</p>
			</div>

			<ReactModal
				isOpen={showModalPass}
				onRequestClose={() => setShowModalPass(false)}
				closeTimeoutMS={200}
				className="container mx-auto bg-paper px-5 pt-5 rounded-xl shadow-map outline-none"
				overlayClassName="fixed inset-0 bg-overlay"
			>
				<div className="container mx-auto px-5 pt-5">
					<div className="flex flex-col justify-center items-center text-center mb-4">
						<div className="max-w-xs px-7">
							<img src={passport} className="mb-4" />
							<label
								htmlFor="passportImg"
								className="bg-paper border border-solid border-blue text-blue text-medium rounded-2xl py-1.5 px-4"
							>
								{t("add_photo")}
							</label>
							<input
								id="passportImg"
								name="passportImg"
								type="file"
								onChange={selectPass}
								hidden={true}
							/>
						</div>
					</div>

					<div className="space-y-3.5 mb-6">
						<p className="text-lg text-center">
							{t("profile.document_upload_hint")}
						</p>
					</div>

					<p className="my-6 text-xs text-center">
						{t("profile.support_cta_message")}{" "}
						<span className="text-blue">{t("support")}</span>
					</p>
				</div>
				<div className="flex flex-row justify-center">
					<button
						onClick={() => setShowModalPass(false)}
						className="w-full py-3 text-xl text-blue"
					>
						Ок
					</button>
				</div>
			</ReactModal>

			<ReactModal
				isOpen={showModalLicense}
				onRequestClose={() => setShowModalLicense(false)}
				closeTimeoutMS={200}
				className="container mx-auto bg-paper px-5 pt-5 rounded-xl shadow-map outline-none"
				overlayClassName="fixed inset-0 bg-overlay"
			>
				<div className="container mx-auto px-5 pt-5">
					<div className="flex flex-col justify-center items-center text-center mb-4">
						<div className="max-w-xs px-7">
							<img src={license} className="mb-4" />
							<label
								htmlFor="licenseImg"
								className="bg-paper border border-solid border-blue text-blue text-medium rounded-2xl py-1.5 px-4"
							>
								{t("add_photo")}
							</label>
							<input
								id="licenseImg"
								name="licenseImg"
								type="file"
								onChange={selectLicense}
								hidden={true}
							/>
						</div>
					</div>

					<div className="space-y-3.5 mb-6">
						<p className="text-lg text-center">
							{t("profile.document_upload_hint_2")}
						</p>
					</div>

					<p className="my-6 text-xs text-center">
						{t("profile.support_cta_message")}{" "}
						<span className="text-blue">{t("support")}</span>
					</p>
				</div>
				<div className="flex flex-row justify-center">
					<button
						onClick={() => setShowModalLicense(false)}
						className="w-full py-3 text-xl text-blue"
					>
						Ок
					</button>
				</div>
			</ReactModal>
		</main>
	);
};

export default DriverVerify;
