import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import ruTranslation from "./ru/translation.json";
import yaTranslation from "./ya/translation.json";

const resources = {
	ru: {
		translation: ruTranslation,
	},
	ya: {
		translation: yaTranslation,
	},
};

i18n.use(initReactI18next).init({
	resources,
	fallbackLng: "ru",
	debug: true,

	interpolation: {
		escapeValue: false,
	},
});

export default i18n;
