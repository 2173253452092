import { observer } from "mobx-react-lite";
import { useContext } from "react";

import { AppContext } from "../contexts/AppContext";
import HistoryItem from "./HistoryItem";

const HistoryList = observer(() => {
	const { orders } = useContext(AppContext);

	return (
		<ul className="divide-y divide-graylight">
			{orders.orders.map((order) => (
				<li key={order.id}>
					<HistoryItem order={order} />
				</li>
			))}
		</ul>
	);
});

export default HistoryList;
