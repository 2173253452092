import { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";
import axios from "axios";

import joinAddressLine from '../../../utils/joinAddressLine'

const reqInstance = axios.create({
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
		Authorization: "Token 46d3c0914c1f5c75dc902b447e0244b38f406bd5",
	},
});

function makeAddressString(address) {
	return joinAddressLine([
		(address.city !== address.region &&
			joinAddressLine([address.city_type, address.city], " ")) ||
		"",
		joinAddressLine([address.street_type, address.street], " "),
		joinAddressLine(
			[address.house_type, address.house, address.block_type, address.block],
			" ",
		),
		joinAddressLine([address.settlement_type, address.settlement], " "),
		// join([address.area_type, address.area], " "),
		// join([address.region_type, address.region], " "),
	]);
}

const AddressPickerModal = ({
	isOpen,
	onClose,
	initialValue = "",
	type,
	geolocation,
	from,
	onChange,
}) => {
	const { t } = useTranslation();
	const inputRef = useRef()
	const [query, setQuery] = useState(() => initialValue);
	const [suggestions, setSuggestions] = useState([]);

	const handleChange = ({ value, data }) => {
		if (data.house !== null) {
			onChange({ value, data });
			onClose();
		} else {
			setQuery(value)
			inputRef.current.focus()
		}
	};

	useEffect(() => {
		reqInstance
			.post(
				"https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address",
				{
					query,
					...(geolocation.address
						? {
							locations: [{ region: geolocation.address.region }],
							from_bound: { value: "street" },
							to_bound: { value: "house" },
						}
						: {}),
					locations_geo: geolocation.coords
						? [
							{
								lat: geolocation.coords.latitude,
								lon: geolocation.coords.longitude,
								radius_meters: 50000,
							},
						]
						: [],
					from_bound: { value: "street" },
					to_bound: { value: "house" },
					restrict_value: true,
				},
			)
			.then(({ data }) => setSuggestions(data.suggestions));
	}, [query]);

	useEffect(() => {
		if (isOpen) {
			setQuery(initialValue);
		}
	}, [isOpen]);

	return (
		<ReactModal
			isOpen={isOpen}
			onRequestClose={onClose}
			closeTimeoutMS={200}
			className="fixed bottom-0 w-full h-full bg-paper px-5 pt-3 shadow-map outline-none"
			overlayClassName="fixed inset-0 bg-overlay"
		>
			<div className="flex flex-col justify-between h-full">
				<div>
					<div className="flex flex-col mb-5 space-y-2">
						<div className="flex flex-col">
							<div className="flex flex-row items-center">
								<span
									className={
										type === "start"
											? "w-4 h-4 rounded-full border-4 border-solid border-red mr-2"
											: "w-4 h-4 rounded-full border-4 border-solid border-blue mr-2"
									}
								/>
								<input
									ref={inputRef}
									type="text"
									placeholder={t("order.from")}
									value={query}
									onChange={(e) => setQuery(e.target.value)}
									className="w-full py-1.5 outline-none"
									autoFocus={true}
								/>
							</div>
							<div className="flex flex-row items-center">
								<span className="w-4 h-4 mr-2" />
								<span className="w-full border-b border-solid border-graylight" />
							</div>
						</div>

						{/* <div className="flex flex-row items-center">
                  <span className="w-4 h-4 mr-2">
                    <img src={location_icon} />
                  </span>
                  <p className="text-blue">Указать на карте</p>
                </div> */}
					</div>

					<div className="h-56">
						{suggestions.map(({ value, data }) => {
							const address = makeAddressString(data);

							return (
								<button
									key={value}
									className='block text-start my-3.5'
									onClick={() => handleChange({ value: address, data, })}
								>
									{address}
								</button>
							);
						})}
					</div>
				</div>

				<div className="flex flex-row justify-center" />

				<button onClick={onClose} className="p-3 text-blue">
					{t("close")}
				</button>
			</div>
		</ReactModal>
	);
};

export default AddressPickerModal
