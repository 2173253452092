import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";

import { getInfo, setInfo } from "../../../http/userApi";
import { USER_PROFILE_ROUTE } from "../../../utils/consts";
import { optimizeImage } from "../../../utils/image";
import Navbar from "../../../components/Navbar";
import Loader from "../../../components/Loader";
import Button from "../../../components/Button";
import Avatar from "../../../components/Avatar";
import UserNameField from "../../../components/fields/UserNameField";

ReactModal.setAppElement("#root");

// Check inputs
const regName = /^(?=.{1,40}$)[а-яёА-ЯЁ]+(?:[-' ][а-яёА-ЯЁ]+)*$/;

const MAX_IMAGE_FIT_SIZE = 300;

const UserProfileEdit = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();

	const [selectedImage, setSelectedImage] = useState();
	const [avatar, setAvatar] = useState(null);
	const [firstName, setFirstName] = useState(null);
	const [lastName, setLastName] = useState(null);
	const [email, setEmail] = useState(null);
	const [birthday, setBirthday] = useState();
	const [city, setCity] = useState(null);
	const [file, setFile] = useState(null);
	const [loading, setLoading] = useState(null);
	const [showModalError, setShowModalError] = useState(false);
	const [errorMessage, setErrorMessage] = useState();

	useEffect(() => {
		getInfo()
			.then((data) => {
				setAvatar(data.avatar ? data.avatar : null);
				setFirstName(data.firstName ? data.firstName : null);
				setLastName(data.lastName ? data.lastName : null);
				setEmail(data.email ? data.email : null);
				setBirthday(data.birthday);
				setCity(data.city ? data.city : null);
			})
			.finally(() => setLoading(false));
	}, []);

	if (loading) {
		return <Loader />;
	}

	const selectFile = (e) => {
		setFile(e.target.files[0]);
		setSelectedImage(e.target.files[0]);
	};

	const sendForm = async (e) => {
		e.preventDefault();

		if (firstName === "" || lastName === "") {
			setShowModalError(true);
			setErrorMessage("Необходимо указать имя и фамилию");
			return;
		}

		if (regName.test(firstName) === false) {
			setShowModalError(true);
			setErrorMessage("Имя содержит недопустимые символы");
			return;
		}

		if (regName.test(firstName) === false) {
			setShowModalError(true);
			setErrorMessage("Фамилия содержит недопустимые символы");
			return;
		}

		const formData = new FormData();
		if (firstName) formData.append("firstName", firstName);
		if (lastName) formData.append("lastName", lastName);
		if (file) {
			const image = await optimizeImage(file, { fitSize: MAX_IMAGE_FIT_SIZE });

			formData.append("img", image);
		}
		if (email) formData.append("email", email);
		if (birthday) formData.append("birthday", birthday);
		if (city) formData.append("city", city);

		return setInfo(formData).then(() => navigate(USER_PROFILE_ROUTE));
	};

	return (
		<main className="relative min-h-screen mx-auto">
			<Navbar title={t("profile.edit.title")} />

			<div className="container mx-auto py-5">
				<div className="mt-8">
					<label htmlFor="file" className="block w-40 h-40 mx-auto">
						<Avatar
							size="xl"
							src={
								selectedImage
									? URL.createObjectURL(selectedImage)
									: avatar
										? process.env.REACT_APP_API_URL + avatar
										: undefined
							}
						/>

						<input
							id="file"
							name="file"
							type="file"
							onChange={selectFile}
							hidden={true}
						/>
					</label>
				</div>

				<div className="flex flex-col mx-5 mt-12">
					<div className="flex flex-col mb-4">
						<UserNameField
							firstName={firstName}
							lastName={lastName}
							onChange={({ firstName, lastName }) => {
								setFirstName(firstName);
								setLastName(lastName);
							}}
						/>
					</div>

					<Button type="button" onClick={sendForm}>
						{t("save")}
					</Button>
				</div>
			</div>

			<ReactModal
				isOpen={showModalError}
				onRequestClose={() => setShowModalError(false)}
				closeTimeoutMS={300}
				className="fixed bottom-0 top-0 left-0 right-0 flex justify-center items-center"
				overlayClassName="fixed inset-0 bg-overlay"
			>
				<div className="w-72 h-min bg-paper px-5 py-10 rounded-xl shadow-map outline-none mx-auto">
					<div className="f-modal-alert">
						<div className="f-modal-icon f-modal-error animate">
							<span className="f-modal-x-mark">
								<span className="f-modal-line f-modal-left animateXLeft" />
								<span className="f-modal-line f-modal-right animateXRight" />
							</span>
							<div className="f-modal-placeholder" />
							<div className="f-modal-fix" />
						</div>
					</div>

					<h1 className="font-semibold text-lg text-center mb-5">
						{t("error")}
					</h1>

					<p className="text-center mb-5">{errorMessage}</p>

					<div className="flex flex-row justify-center">
						<button className="w-full py-3 text-xl text-blue">Ок</button>
					</div>
				</div>
			</ReactModal>
		</main>
	);
};

export default UserProfileEdit;
