import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import InputMask from "react-input-mask";
import { observer } from "mobx-react-lite";

import Popup from "../../components/Popup/Popup";
import Button from "../../components/Button";
import { logo, ru_icon } from "../../img";
import { authSendCode } from "../../http/userApi";
import { AUTH_SMS_ROUTE, POLICY_ROUTE } from "../../utils/consts";

const Auth = observer(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [pending, setPending] = useState(false);
	const cachePhone = localStorage.getItem("phone");

	if (cachePhone) {
		window.location.href = AUTH_SMS_ROUTE;
	}

	const [phone, setPhone] = useState("");
	const [error, setError] = useState("");
	const [popupActive, setPopupActive] = useState(false);

	const sendForm = async (e) => {
		e.preventDefault();
		const regPhone = phone.replace(/[^0-9]/g, "");

		if (regPhone.length !== 10) {
			setPopupActive(true);
			return setError("Номер указан неверно");
		}

		setPending(true);

		authSendCode(regPhone)
			.catch(() => setPending(false))
			.then(() => navigate(AUTH_SMS_ROUTE));
	};

	return (
		<main className="h-screen flex flex-col text-center">
			<div className="mt-28">
				<img src={logo} className="w-24 h-24 mx-auto" />
				<p className="text-base font-semibold text-blue">www.uprider.ru</p>
			</div>

			<div className="container mx-auto sm:max-w-lg mt-10">
				<form className="flex flex-col px-5" onSubmit={sendForm}>
					<div className="flex flex-row items-center mb-5 bg-gray-600">
						<label htmlFor="phone" className="flex-none w-8 h-8 mt-1">
							<img src={ru_icon} />
						</label>

						<p className="ml-2 mr-1 mt-1 text-xl">+7</p>

						<InputMask
							type="tel"
							name="phone"
							id="phone"
							value={phone}
							onChange={(e) => setPhone(e.target.value)}
							mask="(999) 999-99-99"
							maskChar="_"
							className="w-full pt-2 pb-1 px-1 outline-none border-b border-gray text-xl"
							placeholder={t("settings.phone_number")}
						/>
					</div>

					<Button type="submit" loading={pending}>{t("next")}</Button>
				</form>
			</div>

			<div className="container mx-auto sm:max-w-lg fixed bottom-0 left-0 right-0 mb-5 px-2 hide">
				<p className="text-xs font-bold text-gray">
					<Trans
						i18nKey="profile.comfirm_message"
						components={{
							a: <Link to={POLICY_ROUTE} className="underline" />,
						}}
					/>
				</p>
			</div>

			<Popup active={popupActive} setActive={setPopupActive}>
				{error}
			</Popup>
		</main>
	);
});

export default Auth;
