import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";

ReactModal.setAppElement("#root");

const Modal = ({ isOpen, initialState, onChange, onClose }) => {
	const [firstName, setFirstName] = useState(initialState.firstName);
	// const [lastName, setLastName] = useState(initialState.lastName);

	useEffect(() => {
		setFirstName(initialState.firstName)
		// setLastName(initialState.lastName)
	}, [initialState])

	return (
		<ReactModal
			isOpen={isOpen}
			onRequestClose={onClose}
			closeTimeoutMS={300}
			className="fixed bottom-0 w-full bg-paper p-5 pt-10 rounded-xl shadow-map outline-none"
			overlayClassName="fixed inset-0 bg-overlay"
		>
			<form
				className="flex flex-col space-y-5"
				onSubmit={(event) => {
					event.preventDefault();

					onChange({ firstName, lastName: initialState.lastName });

					onClose();
				}}
			>
				<input
					type="text"
					name="firstname"
					id="firstname"
					autoFocus={true}
					value={firstName}
					onChange={(e) => setFirstName(e.target.value)}
					className="w-full pt-2 pb-1 px-1 outline-none border-b border-gray text-xl"
					placeholder='Имя'
					required
				/>

				<div className="flex flex-row justify-center">
					<button className="w-full py-3 text-xl text-blue">Ок</button>
				</div>
			</form>
		</ReactModal>
	);
};

const UserNameField = ({ firstName, lastName, onChange }) => {
	const { t } = useTranslation();
	const [showModal, setShowModal] = useState(false);

	const fullNameDisplay = firstName || "";

	return (
		<>
			<button
				type="button"
				className="flex flex-col"
				onClick={() => setShowModal(true)}
			>
				<p className="text-lg leading-none">{t("first_name")}</p>
				<input
					className="text-gray font-normal outline-none"
					id="name"
					type="text"
					value={fullNameDisplay}
					readOnly
					placeholder={t("full_name")}
				/>
			</button>

			<Modal
				initialState={{ firstName, lastName }}
				onChange={onChange}
				isOpen={showModal}
				onClose={() => setShowModal(false)}
			/>
		</>
	);
};

export default UserNameField;
