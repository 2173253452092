import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";

const EntranceModal = ({ isOpen, entrance: initialEntrance = "", onChange, onClose }) => {
	const { t } = useTranslation()
	const [entrance, setEntrance] = useState(initialEntrance);

	return (
		<ReactModal
			isOpen={isOpen}
			onRequestClose={onClose}
			closeTimeoutMS={200}
			className="fixed bottom-0 w-full bg-paper rounded-t-xl shadow-map outline-none"
			overlayClassName="fixed inset-0 bg-overlay"
		>
			<form
				className="flex flex-col justify-center items-center px-5"
				onSubmit={(e) => {
					e.preventDefault();
					onChange(entrance)
					onClose();
				}}
			>
				<div className="flex flex-row justify-center items-end space-x-2 mt-4 mb-4">
					<label className="text-xl">{t("pd")}</label>
					<input
						type="number"
						min="1"
						max="25"
						inputMode="numeric"
						value={entrance}
						onChange={(e) => setEntrance(e.target.value)}
						className="w-36 px-2 pt-1 text-xl outline-none border-b border-solid border-graylight text-center"
						autoFocus={true}
					/>
				</div>

				<div className="flex justify-center w-full border-t border-solid border-graylight">
					<button className="p-3 text-blue">{t("complete")}</button>
				</div>
			</form>
		</ReactModal>
	);
};

export default EntranceModal;
