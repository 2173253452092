import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

import InputCode from "../../../../js/InputCode";
import { changePhoneCode, getCode } from "../../../../http/userApi";
import { USER_SETTINGS_ROUTE } from "../../../../utils/consts";
import Navbar from "../../../../components/Navbar";

const UserChangePhoneCode = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const newPhone = localStorage.getItem("newPhone");
	const [message, setMessage] = useState("");
	// DEV
	const [showCode, setShowCode] = useState("");

	if (!newPhone) {
		window.location.href = USER_SETTINGS_ROUTE;
	}

	const sendForm = async (code) => {
		const regPhone = newPhone.replace(/[^0-9]/g, "");

		if (regPhone.length !== 10) {
			return setMessage(t("settings.incorrect_number"));
		}

		const data = await changePhoneCode(regPhone, code);

		if (data.message) {
			return setMessage(data.message);
		} else {
			navigate(USER_SETTINGS_ROUTE);
		}
	};

	useEffect(() => {
		getCode(localStorage.getItem("phone")).then((data) =>
			setShowCode(data.code),
		);
	}, []);

	return (
		<main className="relative min-h-screen mx-auto">
			<Navbar
				title={t("settings.phone_number")}
				backLink={USER_SETTINGS_ROUTE}
			/>

			<div className="container mx-auto py-5">
				<div className="mt-10 mx-5">
					<p className="text-gray text-center">
						{t("settings.change_phone_desc")}
					</p>
				</div>

				<div className="mt-10 mb-5">
					<p className="text-center text-lg font-medium">{message}</p>
				</div>

				<div className="mt-10 mx-5">
					<form className="flex flex-col">
						<label className="text-center text-lg">{t("auth.sms_code")}</label>

						<h3 className="text-center">{showCode}</h3>

						<div className="flex flex-row justify-center items-center mb-5">
							<InputCode
								length={6}
								onComplete={(code) => {
									sendForm(code);
								}}
							/>
						</div>

						<button className="bg-blue rounded-2xl text-white text-xl font-medium py-4">
							{t("next")}
						</button>
					</form>
				</div>
			</div>
		</main>
	);
};

export default UserChangePhoneCode;
