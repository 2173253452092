import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import Navbar from "../components/Navbar";
import { AppContext } from "../contexts/AppContext";
import {
	AUTH_ROUTE,
	DRIVER_ABOUT_ROUTE,
	USER_ABOUT_ROUTE,
} from "../utils/consts";

const Policy = () => {
	const { t } = useTranslation();
	const { user } = useContext(AppContext);

	let link;
	if (user.user.role === "DRIVER") {
		link = DRIVER_ABOUT_ROUTE;
	} else if (user.user.role === "CLIENT") {
		link = USER_ABOUT_ROUTE;
	} else {
		link = AUTH_ROUTE;
	}

	return (
		<main className="relative min-h-screen mx-auto">
			<Navbar title={t("settings.policy")} backLink={link} />

			<div className="container mx-auto py-5">
				<div className="flex flex-col mx-5">
					<p>
						<strong>Политика конфиденциальности сервиса </strong>
						<strong>uprider.</strong>
						<strong>ru</strong>
					</p>
					<p>
						<strong>
							(Соглашение об обработке персональных данных) от 01.05.2023
						</strong>
					</p>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
					<p>&nbsp;</p>
					<p>
						Настоящая Политика конфиденциальности персональных данных (далее -
						&laquo;Политика&raquo;) определяет основные принципы, цели, общие
						условия и способы обработки персональных данных, реализуемые меры
						защиты персональных данных, перечень субъектов персональных данных,
						обрабатываемых Оператором, функции Оператора, права субъектов
						персональных данных в процессе пользования Сервисом{" "}
						<strong>uprider.</strong>
						<strong>ru</strong> (далее &ndash; Сервис).
					</p>
					<p>&nbsp;</p>
					<p>
						Политика конфиденциальности регулирует условия и обработку
						персональных данных между Сервисом и Пользователями в процессе
						пользования Сервисом.
					</p>
					<p>&nbsp;</p>
					<p>
						Используя Сервис, Пользователь соглашается с настоящей Политикой.
						Принятие условий Политики Пользователем является явно выраженным,
						предметным, определенным и неабстрактным согласием Пользователя на
						обработку персональных данных Оператором.
					</p>
					<p>&nbsp;</p>
					<p>
						Описанные в настоящем документе правила действуют только в тех
						случаях, когда они соответствуют законодательству регионов, в
						которых осуществляется деятельность Сервиса. Таким образом, если
						определенные положения Политики противоречат законодательству
						конкретного региона, в нем они не действуют.
					</p>
					<p>&nbsp;</p>
					<p>
						Оператором персональных данных, которые вы предоставляете или
						которые собирает Администрация или ее аффилированные лица, является:
						ООО "ГОРОДСКОЙ ТЕМП" &nbsp;(ОГРН: 1221400004506, ИНН: 1400007901).
					</p>
					<p>&nbsp;</p>
					<ol>
						<li>
							<strong> Понятия и термины</strong>
						</li>
					</ol>
					<p>&nbsp;</p>
					<p>1.1. Понятия и термины, используемые в настоящей Политике:</p>
					<p>
						1). Оператор Персональных данных &ndash; Администрация, юридическое
						или физическое лицо, самостоятельно или совместно с другими лицами,
						организующее и (или) осуществляющее обработку персональных данных, а
						также определяющее цели обработки персональных данных, состав
						персональных данных, подлежащих обработке, действия (операции),
						совершаемые с персональными данными.
					</p>
					<p>&nbsp;</p>
					<p>
						2). Обработка Персональных данных - любое действие (операция) или
						совокупность действий (операций), совершаемых с использованием
						средств автоматизации или без использования таких средств с
						персональными данными, включая сбор, запись, систематизацию,
						накопление, хранение, уточнение (обновление, изменение), извлечение,
						использование, передачу (распространение, предоставление, доступ),
						обезличивание, блокирование, удаление, уничтожение персональных
						данных;
					</p>
					<p>&nbsp;</p>
					<p>
						3). Персональные данные - любая информация, относящаяся прямо или
						косвенно к определенному или определяемому физическому лицу
						(субъекту персональных данных):
					</p>
					<p>&nbsp;</p>
					<p>
						- информация, которую Пользователь предоставляет о себе
						самостоятельно при регистрации (создании учетной записи) и
						использовании Сервиса. Обязательная информация для предоставления
						услуг Оператором помечается специальным образом. Иная информация
						предоставляется Пользователем на его усмотрение;
					</p>
					<p>&nbsp;</p>
					<p>
						- данные, которые автоматически становятся доступны Оператору во
						время получения доступа и непосредственного использования Сервиса, в
						том числе, но не ограничиваясь: данные об идентификации браузера
						Пользователя (или иной программы, с помощью которой осуществляется
						доступ на Сервис, порядок посещения страниц Пользователем, время и
						дату подключения и доступа Пользователя к Сервису, IP-адрес, данные
						файлов cookies, модель мобильного устройства используемого
						Пользователем, данные о местонахождении Пользователя,
						предоставляемой службами геолокации, используемыми в устройстве
						Пользователя, технические характеристики оборудования и программного
						обеспечения, используемых Пользователем и иная подобная информация;
					</p>
					<p>&nbsp;</p>
					<p>
						- иная информация о Пользователе, которая становится доступна
						Оператору в процессе предоставления Пользователям услуг Оператором,
						в том числе информация, получаемая от третьих лиц.
					</p>
					<p>&nbsp;</p>
					<p>
						4). Субъект персональных данных &mdash; Пользователь Сервиса,
						который предоставляет персональные данные Оператору, и в отношении
						которого Оператором обрабатываются персональные данные.
					</p>
					<p>&nbsp;</p>
					<ol start="2">
						<li>
							<strong> Общие положения</strong>
						</li>
					</ol>
					<p>&nbsp;</p>
					<p>
						2.1. Если Вы не согласны с настоящей Политикой, а также правилами
						пользования Сервисом, не хотите их соблюдать, не устанавливайте
						веб-приложение, незамедлительно удалите его/любой его компонент с
						мобильного телефона или компьютера и не пользуйтесь сайтом или
						веб-приложением. В случае, если Вы по каким-либо причинам не
						согласны с нижеприведенными условиями, то это означает Вашу
						обязанность по удалению Сервиса со своего мобильного устройства или
						компьютера и не пользованию Сервисом uprider.ru. В противном случае
						Вы будете считаться согласным в безусловном порядке с условиями
						настоящей Политики.
					</p>
					<p>&nbsp;</p>
					<p>
						2.2. Действие настоящей Политики распространяется на все субъекты
						персональных данных, которые предоставляют персональные данные
						Оператору, и в отношении которых Оператор осуществляет обработку
						персональных данных. Требования Политики также учитываются и
						предъявляются в отношении иных лиц при необходимости их участия в
						процессе обработки персональных данных Оператором, а также в случаях
						передачи им в установленном порядке персональных данных на основании
						соглашений, договоров, поручений на обработку.
					</p>
					<p>&nbsp;</p>
					<p>
						2.3. Оператор, исходя из принципов разумности и добросовестности,
						предполагает, что Пользователь:
					</p>
					<p>&nbsp;</p>
					<p>
						2.3.1. Перед началом использования Сервиса внимательно ознакомился с
						условиями настоящей Политики Сервиса и иными официальными
						документами Сервиса;
					</p>
					<p>&nbsp;</p>
					<p>
						2.3.2. Приступив к пользованию Сервисом. выразил свое согласие с
						условиями настоящей Политики и принял на себя указанные в ней права
						и обязанности;
					</p>
					<p>&nbsp;</p>
					<p>
						2.3.3. Понимает и осознает, что в процессе пользования Сервисом
						информация, размещенная Пользователем о себе, может быть доступна
						другим Пользователям Сервиса, при этом Оператор не несет
						ответственности за действия третьих лиц.
					</p>
					<p>&nbsp;</p>
					<p>
						2.4. Настоящая Политика является открытым и общедоступным
						документом, действует бессрочно (либо до утверждения его в новой
						редакции) и предусматривает возможность ознакомления с ней любых
						лиц. Политика может быть изменена и/или дополнена в одностороннем
						порядке Администратором Сервиса без какого-либо специального
						уведомления (согласия) субъектов персональных данных.
					</p>
					<p>&nbsp;</p>
					<p>
						2.5. Изменения и дополнения вступают в силу на следующий день после
						размещения новой редакции.
					</p>
					<p>&nbsp;</p>
					<p>
						2.6. Пользователи обязаны регулярно проверять условия настоящей
						Политики на предмет её изменения и/или дополнения. Продолжение
						пользования Сервисом после внесения изменений и/или дополнений в
						настоящую Политику будет означать принятие и согласие Пользователя с
						такими изменениями и/или дополнениями.
					</p>
					<p>&nbsp;</p>
					<p>
						2.8. Оператор вправе осуществлять обработку персональных данных
						Пользователей, не уведомляя уполномоченный орган по защите прав
						субъектов персональных данных.
					</p>
					<p>&nbsp;</p>
					<p>
						2.9. Администратор является оператором по обработке персональных
						данных, за исключением случаев, когда функции по обработке
						персональных данных переданы другому лицу на основании заключенного
						с таким лицом договора. Обработка персональных данных может
						осуществляться Администратором совместно с иным оператором по
						обработке персональных данных. Администратор вправе передать функции
						по обработке персональных данных другому лицу также в отношении
						отдельных категорий субъектов персональных данных, перечень которых
						определен в настоящей Политике.
					</p>
					<p>&nbsp;</p>
					<p>
						2.10. Сервис не является службой такси или агрегатором, и не несет
						никакой ответственности или обязательств, за оказание услуг или
						деятельность, осуществляемую на Сервисе Пользователей или от
						третьего лица.&nbsp;
					</p>
					<p>&nbsp;</p>
					<p>
						2.11. Использование Пользователем Сервиса означает согласие и, что
						Пользователь внимательно ознакомился с условиями настоящей Политикой
						конфиденциальности Сервиса, и условиями обработки персональных
						данных пользователя.
					</p>
					<p>&nbsp;</p>
					<p>
						2.12. Вы понимаете и признаете, что компания ООО "ГОРОДСКОЙ ТЕМП" не
						является стороной каких-либо соглашений, договоренностей и
						договорных отношений, которые происходят под средством интернет
						связи между Пользователями Сервиса uprider.ru
					</p>
					<p>&nbsp;</p>
					<ol start="3">
						<li>
							<strong> Субъекты персональных данных</strong>
						</li>
					</ol>
					<p>&nbsp;</p>
					<p>
						3.1. Мы обрабатываем персональные следующих лиц: Пользователи
						Сервиса.
					</p>
					<p>&nbsp;</p>
					<ol start="4">
						<li>
							<strong> Цели обработки информации</strong>
						</li>
					</ol>
					<p>&nbsp;</p>
					<p>
						4.1. Оператор собирает и хранит только ту персональную информацию,
						которая необходима для использования веб-приложения Пользователю, за
						исключением случаев, когда законодательством предусмотрено
						обязательное хранение личной (персональной) информации в течение
						определенного законом срока.
					</p>
					<p>&nbsp;</p>
					<p>
						4.2. Оператор осуществляет обработку персональных данных в целях:
					</p>
					<p>&nbsp;</p>
					<p>
						4.2.2. Осуществления Оператором своих законных прав и интересов в
						рамках осуществления своей деятельности по предоставлению доступа к
						Сервису;
					</p>
					<p>&nbsp;</p>
					<p>
						4.2.3. Повышения безопасности и защиты пользователей, поддержки
						пользователей, клиентской помощи, обеспечения связи с Администрацией
						Сервиса или между пользователями;
					</p>
					<p>&nbsp;</p>
					<p>
						4.2.4. Исполнения судебных актов, актов других органов или
						должностных лиц, подлежащих исполнению в соответствии с
						законодательством Российской Федерации;
					</p>
					<p>&nbsp;</p>
					<p>
						4.2.5. Для проведения промо-акций или конкурсов, связанных с
						деятельностью Сервиса, для направления информационных и/или
						рекламных рассылок, в том числе СМС-сообщений, писем на электронную
						почту и иных форм рекламных рассылок, связанных с деятельностью
						Сервиса.
					</p>
					<p>&nbsp;</p>
					<p>
						4.3. Администрация не продает ваши данные и не предоставляет их
						третьим лицам для использования&nbsp; ими данных в своих рекламных
						целях.
					</p>
					<p>&nbsp;</p>
					<ol start="5">
						<li>
							<strong> Принципы и условия обработки персональных данных</strong>
						</li>
					</ol>
					<p>&nbsp;</p>
					<p>
						5.1. Обработка персональных данных осуществляется на основании
						следующих принципов:
					</p>
					<p>&nbsp;</p>
					<p>- законности и справедливости;</p>
					<p>&nbsp;</p>
					<p>- конкретности;</p>
					<p>&nbsp;</p>
					<p>- добросовестности;</p>
					<p>&nbsp;</p>
					<p>
						- недопустимости объединения баз данных, содержащих персональные
						данные, обработка которых осуществляется в целях, несовместимых
						между собой.
					</p>
					<p>&nbsp;</p>
					<p>5.2. Условия обработки личной (персональной) информации:</p>
					<p>&nbsp;</p>
					<p>
						- при обработке персональных данных Пользователя, Оператор
						руководствуется действующим законодательством РФ &laquo;О
						персональных данных&raquo;;
					</p>
					<p>&nbsp;</p>
					<p>
						- обработка персональных данных Пользователя осуществляется в
						соответствии с настоящей Политикой конфиденциальности;
					</p>
					<p>&nbsp;</p>
					<p>
						- в отношении персональных данных информации сохраняется ее
						конфиденциальность кроме случаев, когда предоставление информации о
						Пользователе осуществляется для исполнения соглашения с
						Пользователем;
					</p>
					<p>&nbsp;</p>
					<p>
						- в отношении персональных данных также сохраняется ее целостность и
						доступность для надлежащего оказания услуг Оператором;
					</p>
					<p>&nbsp;</p>
					<p>
						5.2.1. Передача Оператором личной (персональной) информации
						Пользователя третьим лицам осуществляется в следующих случаях:
					</p>
					<p>&nbsp;</p>
					<p>
						- для реализации функционала Сервиса при заказах, чтобы обеспечить
						планирование и выполнение;
					</p>
					<p>&nbsp;</p>
					<p>
						- передача необходима по запросу правоохранительных органов и судов
						в связи с осуществлением ими деятельности в рамках своей
						компетенции, в соответствии с законодательством Российской
						Федерации;
					</p>
					<p>&nbsp;</p>
					<p>
						- в целях обеспечения защиты законных прав и интересов Оператора
						и/или третьих лиц, или иных применимых документов (договоров,
						соглашений, правил) Сервиса;
					</p>
					<p>&nbsp;</p>
					<p>
						- если у Оператора&nbsp; меняется система управления, включая случаи
						слияния, поглощения или покупки всего имущества компании либо
						значительной его части;
					</p>
					<p>&nbsp;</p>
					<p>- согласно вашему ясно выраженному предварительному разрешению;</p>
					<p>&nbsp;</p>
					<p>
						- передачи обезличенной статистической информации в целях проведения
						исследований, в том числе маркетинговых и рекламных;
					</p>
					<p>&nbsp;</p>
					<p>
						- в целях обеспечения правовой защиты Оператора или третьих лиц при
						нарушении Политики или условий, регулирующих использование отдельных
						Сервисов, либо в ситуации, когда существует угроза такого нарушения;
					</p>
					<p>&nbsp;</p>
					<p>
						- в иных случаях, предусмотренных законодательством Российской
						Федерации и настоящей Политикой;
					</p>
					<p>&nbsp;</p>
					<p>- если происходит продажа или иное отчуждение Сервиса;</p>
					<p>&nbsp;</p>
					<p>- аффилированным лицам Оператора.</p>
					<p>&nbsp;</p>
					<p>
						5.2.2. Пользователь соглашается и поручает Оператору поручать и
						передавать (предоставление, доступ) партнерам, действующим от имени
						и в интересах Сервиса, осуществлять обработку, включая сбор, запись,
						систематизацию, накопление, хранение, уточнение (обновление,
						изменение), сопоставление, извлечение, использование, обезличивание,
						блокирование, удаление и уничтожение учетных и иных данных
						пользователя, включая результаты их автоматизированной обработки.
						Такая обработка основывается на договорных отношениях Оператора и
						его партнеров, при этом обработка персональных данных ограничивается
						достижением целей, определенных в настоящей Политике. Оператор
						обязуется сотрудничать с партнером на условиях соблюдения им
						законодательства РФ в области персональных данных, а также при
						условии заключения соглашения о конфиденциальности передаваемых
						данных.
					</p>
					<p>&nbsp;</p>
					<ol start="6">
						<li>
							<strong> Перечень обрабатываемых персональных данных</strong>
						</li>
					</ol>
					<p>&nbsp;</p>
					<p>6.1. В отношении Пользователей:</p>
					<p>&nbsp;</p>
					<p>
						6.1.1. При прохождении авторизации на Сервисе, при установке на
						мобильное устройство Сервиса у Пользователей, а также во время
						использования Сервиса, могут запрашиваться персональные данные,
						которые могут включать следующие данные Пользователей:
					</p>
					<p>&nbsp;</p>
					<p>
						- имя, номер мобильного телефона, данные об автотранспортном
						средстве (государственный номер, марка и модель автомобиля, цвет
						автомобиля), фотографии.
					</p>
					<p>&nbsp;</p>
					<p>
						С помощью серверов Сервиса сохраняется информация об активности
						Пользователя на Сервисе. Сервис собирает и хранит данные в виде
						информации о предоставленных Услугах по доступу к функционалу
						Сервиса, а также информации о них. В состав личной информации и
						персональных данных может входить некоторая информация, прямо не
						предоставляемая Пользователями, поскольку Сервис протоколирует
						информацию, относящуюся к порядку доступа к Сервису.
					</p>
					<p>&nbsp;</p>
					<p>
						Несмотря на указанные выше положения, Сервис имеет право собирать
						следующую информацию:
					</p>
					<p>&nbsp;</p>
					<p>
						&ndash; сведения о мобильном устройстве Пользователя (модель, версия
						операционной системы, уникальные идентификаторы устройства UDID,
						UUID или другой уникальный идентификатор, сведения о мобильной
						операционной системе, а также данные о мобильной сети и номер
						телефона);
					</p>
					<p>&nbsp;</p>
					<p>
						&ndash; сведения журналов, содержащие информацию об использовании
						Сервиса или просмотре содержания, предоставленного с помощью Сервиса
						(включая, но, не ограничиваясь подробными сведениями об
						использовании Сервиса, включая поисковые запросы, данные о
						телефонных вызовах, включая номера телефонов для входящих, исходящих
						и переадресованных звонков, дата, время, тип и продолжительность
						вызовов, а также информация о маршруте SMS, IP-адреса, данные об
						аппаратных событиях, в том числе о сбоях и действиях в системе, а
						также о настройках, типе и языке браузера, дате и времени запроса и
						URL перехода);
					</p>
					<p>&nbsp;</p>
					<p>
						&ndash; сведения о местоположении (включая данные GPS, отправленные
						мобильным устройством, данные различных технологий определения
						координат).
					</p>
					<p>&nbsp;</p>
					<p>6.2. В отношении иных субъектов персональных данных:</p>
					<p>&nbsp;</p>
					<p>
						6.2.1. Перечень персональных данных, обрабатываемых Оператором в
						отношении иных субъектов персональных данных, определяется в
						соответствии с законодательством Российской Федерации и локальными
						актами Оператора с учетом целей обработки персональных данных,
						указанных в разделе 4 Политики.
					</p>
					<p>&nbsp;</p>
					<p>
						6.3. Обработка специальных категорий персональных данных, касающихся
						расовой, национальной принадлежности, политических взглядов,
						религиозных или философских убеждений, состояния здоровья, частной
						жизни Пользователей Оператором не осуществляется.
					</p>
					<p>&nbsp;</p>
					<ol start="7">
						<li>
							<strong> Обработка персональных данных</strong>
						</li>
					</ol>
					<p>&nbsp;</p>
					<p>
						7.1. Под обработкой персональных данных понимается любое действие
						(операция) или совокупность действий (операций), совершаемых с
						использованием средств автоматизации или без использования таких
						средств с персональными данными, включая сбор, запись,
						систематизацию, накопление, хранение, уточнение (обновление,
						изменение), извлечение, использование, передачу (распространение,
						предоставление, доступ), обезличивание, блокирование, удаление,
						уничтожение персональных данных.
					</p>
					<p>&nbsp;</p>
					<p>
						7.2. Обработка персональных данных Пользователя осуществляется в
						соответствии с целями, условиями и принципами, определенными
						настоящей Политикой.
					</p>
					<p>&nbsp;</p>
					<p>
						7.3. Обработка персональных данных Оператором осуществляется с
						согласия субъекта персональных данных на обработку его персональных
						данных, если иное не предусмотрено законодательством Российской
						Федерации в области персональных данных.
					</p>
					<p>&nbsp;</p>
					<p>
						7.4. Согласно законодательству, отдельное письменное согласие
						Пользователя на обработку его персональных данных не требуется.
					</p>
					<p>&nbsp;</p>
					<p>
						7.5. Обработка персональных данных субъекта персональных данных
						осуществляется в строгом соответствии с законодательством стран, в
						которых осуществляет деятельность Сервис, в том числе согласно
						законодательству в области обработки и защиты персональных данных, а
						также законодательству в области информации и информационных
						технологий.
					</p>
					<p>&nbsp;</p>
					<p>
						7.6. Информация, полученная Оператором от Пользователя, в том числе
						информация, полученная в автоматическом режиме при обращении
						Пользователя к Сервису является конфиденциальной, кроме случаев
						добровольного предоставления Пользователем информации о себе для
						общего доступа неограниченному кругу лиц.
					</p>
					<p>&nbsp;</p>
					<p>
						7.7. Оператор вправе уничтожить персональные данные Пользователя в
						случаях нарушения Пользователем условий настоящей Политики или иных
						применимых документов (договоров, соглашений, правил) Сервиса.
					</p>
					<p>&nbsp;</p>
					<p>
						7.8. В случаях, предусмотренных законодательством, Оператор вправе
						и/или обязан хранить информацию и обезличенные персональные данные
						субъекта персональных данных в течение определенного законом срока и
						передать такую информацию по запросам уполномоченных органов
						государственной власти.
					</p>
					<p>&nbsp;</p>
					<p>
						7.9. Оператор хранит вашу информацию до тех пор, пока ваша учетная
						запись остается активной, за исключением случаев, когда от вас
						поступает запрос на удаление вашей информации или учетной записи. В
						некоторых случаях Оператор может хранить определенную информацию о
						вас в соответствии с требованиями законодательства или преследуя
						иные цели, описанные в настоящем разделе, даже если ваш аккаунт
						удален.
					</p>
					<p>&nbsp;</p>
					<p>
						С учетом нижеуказанных исключений компания Оператор удаляет или
						обезличивает вашу информацию по вашему требованию.
					</p>
					<p>&nbsp;</p>
					<p>
						Оператор может хранить информацию после удаления аккаунта в
						следующих случаях:
					</p>
					<p>&nbsp;</p>
					<p>
						- при наличии неурегулированного спора в связи с Вашим аккаунтом;
					</p>
					<p>&nbsp;</p>
					<p>
						- если это требуется по закону, а также в агрегированной или
						обезличенной форме;
					</p>
					<p>&nbsp;</p>
					<p>
						Оператор может также хранить определенную информацию, если это
						необходимо для защиты ее законных деловых интересов, таких как
						предотвращение мошенничества и обеспечение безопасности и защиты
						пользователей.
					</p>
					<p>&nbsp;</p>
					<ol start="8">
						<li>
							<strong> Cookies</strong>
						</li>
					</ol>
					<p>&nbsp;</p>
					<p>
						8.1. В то время, как вы используете Сервис с помощью сайта или
						веб-приложения, Оператор может использовать общеотраслевую
						технологию, называемую &laquo;куки&raquo; (cookies), которая
						позволяет сохранить определенную информацию на вашем телефоне.
						Cookies позволяют выполнить автоматический вход в приложение:
						зарегистрированные пользователи будут идентифицированы
						автоматически. В большинстве телефонов есть функция очистки
						компьютера от cookie-файлов, блокировки их приема или отправки
						уведомления каждый раз перед сохранением подобного фала. Однако
						после блокировки или удаления cookie ваши возможности в
						интерактивной деятельности могут быть ограничены.
					</p>
					<p>&nbsp;</p>
					<p>
						8.2. Файлы Cookies передаваемые Оператором оборудованию и
						программному обеспечению Пользователя могут использоваться
						Оператором для предоставления персонализированных услуг, для
						отправки рекламы целевой аудитории, в статистических и
						исследовательских целях, а также для улучшения качества услуг
						Оператора.
					</p>
					<p>&nbsp;</p>
					<ol start="9">
						<li>
							<strong> Права субъектов персональных данных</strong>
						</li>
					</ol>
					<p>&nbsp;</p>
					<p>
						9.1. Субъект персональных данных имеет право отозвать согласие на
						обработку персональных данных, направив соответствующий запрос
						Оператору по электронной почте urban-pace@mail.ru
					</p>
					<p>&nbsp;</p>
					<p>
						9.2. Субъект персональных данных имеет право на получение
						информации, касающейся обработки его персональных данных на
						основании запроса.
					</p>
					<p>&nbsp;</p>
					<p>
						9.3. Субъект персональных данных вправе требовать от Оператора
						уточнения его персональных данных, их блокирования или уничтожения в
						случае, если персональные данные являются неполными, устаревшими,
						неточными, незаконно полученными или не являются необходимыми для
						заявленной цели обработки.
					</p>
					<p>&nbsp;</p>
					<p>
						9.3.1. Пользователь вправе самостоятельно осуществлять изменение,
						уточнение или удаление своих персональных данных и иной информации с
						учетом доступного функционала Сервиса, в том числе в Личном
						кабинете.
					</p>
					<p>&nbsp;</p>
					<ol start="10">
						<li>
							<strong> Меры принимаемые в целях защиты информации</strong>
						</li>
					</ol>
					<p>&nbsp;</p>
					<p>
						10.1. Оператор принимает необходимые правовые, технические и
						организационные меры в целях защиты персональной информации
						Пользователя от неправомерного или случайного доступа к ним,
						уничтожения, изменения, блокирования, копирования, распространения,
						а также от иных неправомерных действий. К таким мерам относятся:
					</p>
					<p>&nbsp;</p>
					<p>
						- получение согласий субъектов персональных данных на обработку их
						персональных данных, за исключением случаев, предусмотренных
						законодательством Российской Федерации;
					</p>
					<p>&nbsp;</p>
					<p>
						- обособление персональных данных, обрабатываемых без использования
						средств автоматизации, от иной информации, в частности путем их
						фиксации на отдельных материальных носителях персональных данных, в
						специальных разделах;
					</p>
					<p>&nbsp;</p>
					<p>
						- резервное копирование информации для возможности восстановления;
					</p>
					<p>&nbsp;</p>
					<p>
						- иные меры, предусмотренные законодательством в области
						персональных данных.
					</p>
					<p>&nbsp;</p>
					<ol start="11">
						<li>
							<strong> Хранение и уничтожение информации</strong>
						</li>
					</ol>
					<p>&nbsp;</p>
					<p>
						11.1 Оператор использует информацию о Пользователе для
						предоставления доступа к функционалу Сервиса и хранит ее, пока у вас
						есть учетная запись.
					</p>
					<p>&nbsp;</p>
					<p>
						11.2. Оператор закрывает доступ и запрещает использование
						информации, которая более не нужна для использования Сервиса,
						поддержки пользователей, повышения качества обслуживания и других
						операционных целей. Такие данные используются исключительно для
						соблюдения настоящих требований, обеспечения безопасности, выявления
						и предотвращения случаев мошенничества.
					</p>
					<p>&nbsp;</p>
					<p>
						11.3. Вы можете отправить запрос на удаление аккаунта в любой момент
						в соответствующем разделе сайта или веб-приложения uprider.ru или на
						электронную почту urban-pace@mail.ru.
					</p>
					<p>&nbsp;</p>
					<p>
						11.4. Получив такой запрос, Оператор удаляет всю информацию за
						исключением той, которую она обязана хранить. В некоторых случаях,
						например, при неразрешенных споров, Оператор не может удалить
						аккаунт. После устранения данных факторов Оператор удаляет аккаунт,
						следуя описанной выше процедуре.
					</p>
					<p>&nbsp;</p>
					<p>
						11.5. Оператор может также сохранять определенную информацию, если
						это необходимо для соблюдения наших законных деловых интересов,
						таких как предотвращение мошенничества и обеспечение безопасности и
						защиты пользователей. Например, если Оператор заблокирует аккаунт
						пользователя из-за небезопасного поведения или случаев нарушения
						безопасности, мы можем сохранить определенную информацию об этом
						аккаунте, чтобы предотвратить создание пользователем нового аккаунта
						в будущем.
					</p>
					<p>&nbsp;</p>
					<ol start="12">
						<li>
							<strong> Ограничение действия Политики</strong>
						</li>
					</ol>
					<p>&nbsp;</p>
					<p>
						12.1. Оператор не несет ответственности за действия Пользователей и
						третьих лиц, получивших в результате пользования Сервисом доступ к
						информации о Пользователе и за последствия использования,
						распространения и совершения иных действий в отношении такой
						информации, доступ к которой в силу особенностей Сервиса доступен
						любому пользователю сети Интернет и Сервиса.
					</p>
					<p>&nbsp;</p>
					<ol start="13">
						<li>
							<strong> Иные положения</strong>
						</li>
					</ol>
					<p>&nbsp;</p>
					<p>
						13.1. Субъекты персональных данных направляют запросы, уведомления
						на адрес электронной почты: urban-pace@mail.ru
					</p>
					<p>&nbsp;</p>
					<p>
						13.2. Оператор отвечает на запросы субъектов персональных данных в
						течение 30 календарных дней с момента их получения.
					</p>
				</div>
			</div>
		</main>
	);
};

export default Policy;
