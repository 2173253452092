import { useState, useEffect, useRef, memo } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Ticker from "react-ticker";
import ReactModal from "react-modal";
import { lineString } from "@turf/helpers";
import bbox from "@turf/bbox";

import ReactSwitch from "../../../components/Switch";
import { Mapbox } from "../../../components/Mapbox";
import { createOrder, getActiveOrder } from "../../../http/orderApi";
import { getUsersInfo } from "../../../http/userApi";
import {
	comment_icon,
	many_people_icon,
	baby_chair_icon,
        city_icon,
        cargo_icon,
        faeces_icon,
        water_icon,
} from "../../../img/index";
import {
	USER_CITY_GO_ROUTE,
	USER_CITY_SEARCH_ROUTE,
} from "../../../utils/consts";
import joinAddressLine from "../../../utils/joinAddressLine";
import Menu from "../../../components/Menu";
import Loader from "../../../components/Loader";
import Button from "../../../components/Button";
import { useGeolocation } from "../../../contexts/GeolocationContext";

import AddressPickerModal from "./AddressPickerModal";
import PriceModal from "./PriceModal";
import EntranceModal from "./EntranceModal";

// import "react-dadata/dist/react-dadata.css";

ReactModal.setAppElement("#root");

// const reqInstance = axios.create({
// 	headers: {
// 		"Content-Type": "application/json",
// 		Accept: "application/json",
// 		Authorization: "Token 46d3c0914c1f5c75dc902b447e0244b38f406bd5",
// 	},
// });

const Map = memo(({ initialViewState, fromPoint, toPoint }) => {
	const mapRef = useRef(null);

	useEffect(() => {
		if (fromPoint.length === 2 && toPoint.length === 0) {
			mapRef.current?.flyTo({
				center: fromPoint,
				zoom: 16,
				essential: true,
			});
		} else if (toPoint.length === 2 && fromPoint.length === 0) {
			mapRef.current?.flyTo({
				center: toPoint,
				zoom: 16,
				essential: true,
			});
		} else {
			console.log(fromPoint, toPoint);
			const _bbox = bbox(lineString([fromPoint, toPoint]));

			mapRef.current?.fitBounds(_bbox, { padding: 50 });
		}
	}, [fromPoint, toPoint]);

	return (
		<Mapbox
			ref={mapRef}
			initialViewState={initialViewState}
			geolocateOnMount
			showGeolocateControl
		>
			{fromPoint.length === 2 && (
				<Mapbox.Marker latitude={fromPoint[1]} longitude={fromPoint[0]}>
					<span className="block w-4 h-4 bg-paper rounded-full border-4 border-solid border-red" />
				</Mapbox.Marker>
			)}
			{toPoint.length === 2 && (
				<Mapbox.Marker latitude={toPoint[1]} longitude={toPoint[0]}>
					<span className="block w-4 h-4 bg-paper rounded-full border-4 border-solid border-blue" />
				</Mapbox.Marker>
			)}
		</Mapbox>
	);
});

const UserCityStart = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const geolocation = useGeolocation();
	const [from, setFrom] = useState("");
	const [entrance, setEntrance] = useState("");
	const [fromPoint, setFromPoint] = useState([]);
	const [to, setTo] = useState("");
	const [toPoint, setToPoint] = useState([]);
	const [price, setPrice] = useState("");
	const [payMethod, setPayMethod] = useState("CASH");
	const [comment, setComment] = useState("");
	const [babyChair, setBabyChair] = useState(false);
	const [manyPeople, setManyPeople] = useState(false);
	const [fromCity, setFromCity] = useState("");
	const [loading, setLoading] = useState(true);
	const [sending, setSending] = useState(false);
	const [usersInfo, setUsersInfo] = useState(null);

	const [showModalFrom, setShowModalFrom] = useState(false);
	const [showModalTo, setShowModalTo] = useState(false);
	const [showModalPrice, setShowModalPrice] = useState(false);
	const [showModalComment, setShowModalComment] = useState(false);
	const [showModalEntrance, setShowModalEntrance] = useState(false);

	const isValidForm = from && to && price;

	useEffect(() => {
		getActiveOrder()
			.then((activeOrder) => {
				if (activeOrder?.status === 1) {
					navigate(`${USER_CITY_SEARCH_ROUTE}/${activeOrder.id}`);
				} else if (activeOrder?.status >= 2 && activeOrder?.status <= 5) {
					navigate(`${USER_CITY_GO_ROUTE}/${activeOrder.id}`);
				}
			}).then(getUsersInfo).then(setUsersInfo)
			.finally(() => setLoading(false));
	}, []);

	// useEffect(() => {
	// 	//
	// }, [])

	const sendForm = async (e) => {
		e.preventDefault();

		if (!isValidForm) return;

		setSending(true);

		const formData = new FormData();
		formData.append("type", "CITY");
		formData.append("from", from);
		formData.append("fromPoint", fromPoint);
		formData.append("to", to);
		formData.append("toPoint", toPoint);
		formData.append("price", price);
		formData.append("payMethod", payMethod);
		formData.append("comment", comment);
		formData.append("babyChair", babyChair);
		formData.append("manyPeople", manyPeople);
		formData.append("city", fromCity);
		formData.append("entrance", entrance);
		const data = await createOrder(formData);

		if (data.status === 200) {
			navigate(`${USER_CITY_SEARCH_ROUTE}/${data.data.id}`);
		} else {
			setSending(false);
		}
	};

	const saveFromAddress = ({ value, data }) => {
		setFrom(value);
		const point = [data.geo_lon, data.geo_lat];
		setFromPoint(point);
		setFromCity(data.city);
		setShowModalFrom(false);
	};

	const saveToAddress = ({ value, data }) => {
		setTo(value);
		const point = [data.geo_lon, data.geo_lat];
		setToPoint(point);
		setShowModalTo(false);
	};

	if (loading) return <Loader />;

	return (
		<main className="relative w-full mx-auto">
			<div className="fixed top-0 left-0 right-0 z-10">
				<div className="flex flex-col justify-center items-center space-x-1.5 mt-6">
					{geolocation.address && (
						<h2 className="font-bold leading-none">
							{joinAddressLine([
								joinAddressLine(
									[geolocation.address.street_type, geolocation.address.street],
									" ",
								),
								joinAddressLine(
									[
										geolocation.address.house_type,
										geolocation.address.house,
										geolocation.address.block_type,
										geolocation.address.block,
									],
									" ",
								),
								joinAddressLine(
									[
										geolocation.address.settlement_type,
										geolocation.address.settlement,
									],
									" ",
								),
							])}
						</h2>
					)}
				</div>
			</div>

			<Menu />

			<div className="h-screen flex flex-col">
				<div className="relative h-full">
					<Map
						initialViewState={{
							longitude: geolocation.coords?.longitude || 129.71,
							latitude: geolocation.coords?.latitude || 62.05,
							zoom: 16,
						}}
						fromPoint={fromPoint}
						toPoint={toPoint}
					/>

					<div className="absolute left-2 bottom-2 text-white py-1 space-y-1">
						<p className="whitespace-nowrap text-sm px-1">Водителей онлайн: {usersInfo && usersInfo.usersOnline}</p>
						<p className="whitespace-nowrap text-sm px-1">Зарегистрированно: {usersInfo && usersInfo.usersCount + 5000}</p>
					</div>
				</div>

				<div className='h-min w-full mx-auto shadow-map z-10'>
					<div className="bg-blue text-white py-1">
						<Ticker offset={40}>
							{() => (
								<p className="whitespace-nowrap px-5">{t("info_message")}</p>
							)}
						</Ticker>
					</div>
<ul className="flex flex-row space-x-3 overflow-auto py-2.5 px-2.5 hide">
            <li className="flex flex-col justify-end items-center bg-paper rounded-lg border-2 border-solid border-gray px-1 py-1">
              <span className="w-20 h-12"><img src={city_icon} width={100} height={100} /></span>
              <p className="text-sm">Легковые</p>
            </li>
            <li className="flex flex-col justify-end items-center bg-paper rounded-lg shadow-history px-1 py-1">
              <span className="w-20 h-12"><img src={cargo_icon} width={100} height={100} /></span>
              <p className="text-sm text-gray">Грузовые</p>
            </li>
            <li className="flex flex-col justify-end items-center bg-paper rounded-lg shadow-history px-1 py-1">
              <span className="w-20 h-12"><img src={faeces_icon} width={100} height={100} /></span>
              <p className="text-sm text-gray">Фекалки</p>
            </li>
            <li className="flex flex-col justify-end items-center bg-paper rounded-lg shadow-history px-1 py-1">
              <span className="w-20 h-12"><img src={water_icon} width={100} height={100} /></span>
              <p className="text-sm text-gray">Водовозки</p>
            </li>
          </ul>

					<form className="flex flex-col px-5 mb-5" onSubmit={sendForm}>
						<div className="flex flex-col mb-4">
							<div className="flex flex-col items-stretch">
								<div className="flex items-center">
									<button
										type="button"
										className="flex-1 flex items-center text-start"
										onClick={() => setShowModalFrom(true)}
									>
										<span
											className={
												from
													? "w-4 h-4 rounded-full border-4 border-solid border-red mr-2"
													: "w-4 h-4 rounded-full border-4 border-solid border-gray mr-2"
											}
										/>
										<input
											type="text"
											placeholder={t("order.from")}
											value={from}
											className="w-full py-1.5 outline-none cursor-pointer"
											readOnly={true}
											required
										/>
									</button>

									<button
										type="button"
										onClick={() => setShowModalEntrance(true)}
										className="pl-3 text-gray border-l border-graylight whitespace-nowrap outline-none"
									>
										<p>{`${entrance} подъезд`}</p>
									</button>
								</div>
								<div className="flex flex-row items-center">
									<span className="w-4 h-4 mr-2" />
									<span className="w-full border-b border-solid border-graylight" />
								</div>
							</div>

							<div className="flex flex-col items-stretch">
								<button
									type="button"
									className="flex flex-row items-center"
									onClick={() => setShowModalTo(true)}
								>
									<span
										className={
											to
												? "w-4 h-4 rounded-full border-4 border-solid border-blue mr-2"
												: "w-4 h-4 rounded-full border-4 border-solid border-gray mr-2"
										}
									/>
									<input
										type="text"
										placeholder={t("order.to")}
										value={to}
										className="w-full py-1.5 outline-none cursor-pointer"
										readOnly={true}
										required
									/>
								</button>
								<div className="flex flex-row items-center">
									<span className="w-4 h-4 mr-2" />
									<span className="w-full border-b border-solid border-graylight" />
								</div>
							</div>

							<button
								type="button"
								className="flex flex-col items-stretch"
								onClick={() => setShowModalPrice(true)}
							>
								<div className="flex flex-row items-center">
									<span className="w-4 h-4 text-center text-gray leading-4 font-medium mr-2">
										₽
									</span>
									<input
										type="number"
										placeholder={t("city.offer_your_price")}
										value={price}
										className="w-full py-1.5 outline-none cursor-pointer"
										readOnly={true}
										required
									/>
								</div>
								<div className="flex flex-row items-center">
									<span className="w-4 h-4 mr-2" />
									<span className="w-full border-b border-solid border-graylight" />
								</div>
							</button>

							<button
								type="button"
								className="flex flex-col items-stretch"
								onClick={() => setShowModalComment(true)}
							>
								<div className="flex flex-row items-center">
									<span className="w-5 h-5 mt-2 mr-2">
										<img src={comment_icon} />
									</span>
									<input
										type="text"
										placeholder={t("comment")}
										value={comment}
										className="w-full py-1.5 outline-none cursor-pointer"
										readOnly={true}
									/>
								</div>
								<div className="flex flex-row items-center">
									<span className="w-4 h-4 mr-2" />
									<span className="w-full border-b border-solid border-graylight" />
								</div>
							</button>
						</div>

						<Button type="submit" disabled={!isValidForm} loading={sending}>
							{t("action.order")}
						</Button>
					</form>

					
				</div>
			</div>

			<AddressPickerModal
				isOpen={showModalFrom}
				onClose={() => setShowModalFrom(false)}
				initialValue={from}
				geolocation={geolocation}
				onChange={saveFromAddress}
				type={"start"}
			/>

			<AddressPickerModal
				isOpen={showModalTo}
				onClose={() => setShowModalTo(false)}
				initialValue={to}
				geolocation={geolocation}
				onChange={saveToAddress}
				type={"end"}
			/>

			<PriceModal
				price={price}
				payMethod={payMethod}
				onChange={({ price, payMethod }) => {
					setPrice(price);
					setPayMethod(payMethod);
				}}
				isOpen={showModalPrice}
				onClose={() => setShowModalPrice(false)}
			/>

			<EntranceModal
				isOpen={showModalEntrance}
				onClose={() => setShowModalEntrance(false)}
				initialEntrance={entrance}
				onChange={setEntrance}
			/>

			<ReactModal
				isOpen={showModalComment}
				onRequestClose={() => setShowModalComment(false)}
				closeTimeoutMS={200}
				className="fixed bottom-0 w-full bg-paper rounded-t-xl shadow-map outline-none"
				overlayClassName="fixed inset-0 bg-overlay"
			>
				<form
					className="flex flex-col px-5 mt-3"
					onSubmit={(e) => {
						e.preventDefault();
						setShowModalComment(false);
					}}
				>
					<div className="flex flex-col">
						<div className="flex flex-row items-center">
							<span className="w-4 h-4 mr-3">
								<img src={comment_icon} />
							</span>
							<input
								type="text"
								placeholder={t("city.form.comment")}
								value={comment}
								onChange={(e) => setComment(e.target.value)}
								className="w-full py-1.5 outline-none"
							/>
						</div>
						<div className="flex flex-row items-center">
							<span className="w-4 h-4 mr-2" />
							<span className="w-full border-b border-solid border-graylight" />
						</div>
					</div>

					<div className="flex flex-row justify-between items-center my-2">
						<div className="flex flex-row items-center">
							<span className="w-4 h-4 mr-3">
								<img src={baby_chair_icon} />
							</span>
							<span className="text-gray"></span>
						</div>
						<ReactSwitch
							isOn={babyChair}
							handleToggle={() => setBabyChair(!babyChair)}
						/>
					</div>

					<div className="flex flex-row justify-between items-center my-2">
						<div className="flex flex-row items-center">
							<span className="w-4 h-4 mr-3">
								<img src={many_people_icon} />
							</span>
							<span className="text-gray">
								{t("order.more_than_4_passengers")}
							</span>
						</div>
						<ReactSwitch
							isOn={manyPeople}
							handleToggle={() => setManyPeople(!manyPeople)}
						/>
					</div>
				</form>

				<div className="flex flex-row justify-center border-t border-solid border-graylight">
					<button
						onClick={() => setShowModalComment(false)}
						className="p-3 text-blue"
					>
						{t("complete")}
					</button>
				</div>
			</ReactModal>
		</main>
	);
};

export default UserCityStart;
