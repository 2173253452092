import { Component } from 'react'
import Switch from "react-switch"

const ReactSwitch = ({ isOn, handleToggle }) => {
  return (
    <Switch
      onChange={handleToggle}
      checked={isOn}
      onColor='#2693e6'
      onHandleColor="#0045AC"
      handleDiameter={22}
      uncheckedIcon={false}
      checkedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.4)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={14}
      width={35} />
  )
}

export default ReactSwitch
