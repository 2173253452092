import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";

import {
	card,
	phone,
	money,
} from "../../../img/index";

const PriceModal = ({ isOpen, price: initialPrice = "", payMethod: initialPayMethod = "CASH", onChange, onClose }) => {
	const { t } = useTranslation()
	const [price, setPrice] = useState(initialPrice);
	const [payMethod, setPayMethod] = useState(initialPayMethod);

	const changePayMethod = (value) => {
		setPayMethod(value);
	};

	return (
		<ReactModal
			isOpen={isOpen}
			onRequestClose={onClose}
			closeTimeoutMS={200}
			className="fixed bottom-0 w-full bg-paper rounded-t-xl shadow-map outline-none"
			overlayClassName="fixed inset-0 bg-overlay"
		>
			<form
				className="flex flex-col justify-center px-5"
				onSubmit={(e) => {
					e.preventDefault();
					onChange({
						price,
						payMethod,
					})
					onClose();
				}}
			>
				<div className="flex flex-row justify-center items-end space-x-2 mt-4">
					<label className="text-xl">{t("city.offer_your_price")}</label>
					<input
						type="number"
						min="100"
						max="9999"
						inputMode="numeric"
						required
						value={price}
						onChange={(e) => setPrice(e.target.value)}
						className="w-36 px-2 pt-1 text-xl outline-none border-b border-solid border-graylight text-center"
						autoFocus={true}
					/>
				</div>
				<div className="flex flex-col mt-3 px-5 space-y-2.5">
					<button
						type="button"
						className={
							payMethod == "CASH"
								? "flex flex-row justify-center space-x-2 text-center shadow-history rounded-xl py-3 border border-blue"
								: "flex flex-row justify-center space-x-2 text-center shadow-history rounded-xl py-3"
						}
						onClick={() => changePayMethod("CASH")}
					>
						<span className="w-5 h-5">
							<img src={money} />
						</span>
						<span className="text-sm">{t("pay_method.cash")}</span>
					</button>

					<button
						type="button"
						className={
							payMethod == "TRANSFER"
								? "flex flex-row justify-center space-x-2 text-center shadow-history rounded-xl py-3 border border-blue"
								: "flex flex-row justify-center space-x-2 text-center shadow-history rounded-xl py-3"
						}
						onClick={() => changePayMethod("TRANSFER")}
					>
						<span className="w-5 h-5">
							<img src={phone} />
						</span>
						<span className="text-sm">{t("pay_method.transfer")}</span>
					</button>

					<div className="flex justify-center w-full border-t mt-5 border-solid border-graylight">
						<button className="p-3 text-blue">{t("complete")}</button>
					</div>
				</div>
			</form>
		</ReactModal>
	);
};

export default PriceModal;
