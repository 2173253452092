import React, { useEffect, useRef, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useTranslation, Trans } from "react-i18next";
import ReactModal from "react-modal";
import InputMask from "react-input-mask";
import { Combobox, Transition } from "@headlessui/react";
import cx from "classnames";

// import { optimizeImage } from "../../../utils/image";
import {
	arrow_right_icon,
	car_photo,
	check_mark_icon,
} from "../../../img";
import {
	DRIVER_GARAGE_ROUTE,
	DRIVER_PROFILE_ROUTE,
	POLICY_ROUTE,
} from "../../../utils/consts";
import { addCar, changeCar, getDriverCars } from "../../../http/userApi";
import Navbar from "../../../components/Navbar";
import Button from "../../../components/Button";
import { dadataAPI } from "../../../lib/dadata";

ReactModal.setAppElement("#root");

// const MAX_IMAGE_FIT_SIZE = 1024;

const DriverAddCar = () => {
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [brand, setBrand] = useState();
	const [model, setModel] = useState();
	const [color, setColor] = useState();
	const [number, setNumber] = useState();
	// const [photo, setPhoto] = useState();
	// const [certificate, setCertificate] = useState();
	const [year, setYear] = useState();
	const [isLoading, setLoading] = useState(true);
	const [mode, setMode] = useState("CREATE");
	// const brandSuggestionsRef = useRef();

	const [showModalBrand, setShowModalBrand] = useState(false);
	const [showModalCertificate, setShowModalCertificate] = useState(false);
	const [brandQuery, setBrandQuery] = useState("");
	const [brandSuggestions, setBrandSuggestions] = useState([]);

	const { id } = useParams();

	// const selectCarPhoto = (e) => {
	// 	setPhoto(e.target.files[0]);
	// 	setShowModalPhoto(false);
	// };

	// const selectCertificate = (e) => {
	// 	setCertificate(e.target.files[0]);
	// };

	const formatChars = {
		9: "[0-9]",
		a: "[А-Яа-я]",
	};

	const sendForm = async () => {
		if (
			!brand ||
			!model ||
			!color ||
			!number ||
			// !photo ||
			// !certificate ||
			!year
		)
			return;
		const formData = new FormData();
		formData.append("type", id.toUpperCase());
		formData.append("brand", brand);
		formData.append("model", model);
		formData.append("color", color);
		formData.append("number", number);

		// if (photo) {
		// 	const image = await optimizeImage(photo, { fitSize: MAX_IMAGE_FIT_SIZE });

		// 	formData.append("photo", image);
		// }

		// if (certificate) {
		// 	const image = await optimizeImage(certificate, {
		// 		fitSize: MAX_IMAGE_FIT_SIZE,
		// 	});

		// 	formData.append("certificate", image);
		// }

		formData.append("year", year);

		if (mode === "EDIT") {
			await changeCar(formData);
		} else {
			await addCar(formData);
		}

		navigate(DRIVER_GARAGE_ROUTE);
	};

	useEffect(() => {
		getDriverCars("CITY")
			.then((res) => {
				if (res !== null) {
					setBrand(res.brand);
					setModel(res.model);
					setColor(res.color);
					setNumber(res.number);
					// fetch(`https://up-drivers.ru/${res.photo}`)
					// 	.then((r) => r.blob())
					// 	.then((b) => {
					// 		const [name, ext] = res.photo.split(".");

					// 		setPhoto(new File([b], name, { type: `image/${ext}` }));
					// 	});
					// fetch(`https://up-drivers.ru/${res.certificate}`)
					// 	.then((r) => r.blob())
					// 	.then((b) => {
					// 		const [name, ext] = res.certificate.split(".");

					// 		setCertificate(new File([b], name, { type: `image/${ext}` }));
					// 	});
					setYear(res.year);

					setMode("EDIT");
				}
			})
			.finally(() => setLoading(false));
	}, []);

	useEffect(() => {
		dadataAPI.carBrands({ query: brandQuery })
			.then(({ suggestions }) => setBrandSuggestions(suggestions.map(({ value }) => value)));
	}, [brandQuery]);

	if (isLoading) {
		return null;
	}

	return (
		<main className="relative min-h-screen mx-auto">
			<Navbar
				title={t("profile.add_car.title")}
				backLink={DRIVER_PROFILE_ROUTE}
			/>

			<div className="container mx-auto px-5 pt-5">
				<ul className="space-y-1">
					<li
						className="flex flex-row justify-between items-center py-2.5"
						onClick={() => setShowModalBrand(true)}
					>
						<p>{t("profile.vendor_model_color")}</p>
						<span>
							<img
								src={
									brand && model && color ? check_mark_icon : arrow_right_icon
								}
								className="h-5"
							/>
						</span>
					</li>

					{/* <li
						type="button"
						className="flex flex-row justify-between items-center py-2.5"
						onClick={() => setShowModalPhoto(true)}
					>
						<p>{t("profile.car_photo")}</p>
						<span>
							<img
								src={photo ? check_mark_icon : arrow_right_icon}
								className="h-5"
							/>
						</span>
					</li> */}

					<li
						className="flex flex-row justify-between items-center py-2.5"
						onClick={() => setShowModalCertificate(true)}
					>
						<p>{t("profile.registration_certificate")}</p>
						<span>
							<img
								src={year && number ? check_mark_icon : arrow_right_icon}
								className="h-5"
							/>
						</span>
					</li>
				</ul>

				<Button
					disabled={!(brand && model && color && number && year)}
					onClick={() => sendForm()}
					className="w-full mt-5"
				>
					{t("complete")}
				</Button>

				<p className="my-6 text-xs text-gray font-bold text-center">
					<Trans
						i18nKey="profile.comfirm_message"
						components={{
							a: <Link to={POLICY_ROUTE} className="underline" />,
						}}
					/>
				</p>
			</div>

			<ReactModal
				isOpen={showModalBrand}
				onRequestClose={() => setShowModalBrand(false)}
				closeTimeoutMS={200}
				className="container mx-auto absolute inset-0 bg-paper px-5 pt-5 shadow-map outline-none"
				overlayClassName="fixed inset-0 bg-overlay"
			>
				<div className="container mx-auto pt-3">
					<div className="space-y-3.5 mb-6">
						<p className="text-lg text-center">{t("profile.fill_form")}</p>
					</div>
					<div className="flex flex-col space-y-4">
						<div className="flex flex-col">
							<label htmlFor="model">{t("profile.car_model")}</label>
							<Combobox
								as="div"
								value={brand || ""}
								onChange={setBrand}
								className="relative"
							>
								<Combobox.Input
									onChange={(event) => setBrandQuery(event.target.value)}
									placeholder="Volkswagen"
									className="w-full pt-2 pb-1 outline-none border-b border-gray appearance-none block focus:outline-none"
								/>
								<Combobox.Options
									as="ul"
									className="absolute z-10 top-full w-full max-h-64 overflow-auto bg-graylight"
								>
									{brandSuggestions
										.map((brand) => (
											<Combobox.Option
												as="li"
												key={brand}
												value={brand}
												className={({ active }) =>
													cx("w-full text-start p-2", active && "bg-white/10")
												}
											>
												{brand}
											</Combobox.Option>
										))}
								</Combobox.Options>
							</Combobox>
						</div>

						<div className="flex flex-col">
							<label htmlFor="model">{t("profile.car_model")}</label>
							<input
								id="model"
								type="text"
								value={model}
								onChange={(e) => setModel(e.target.value)}
								className="w-full pt-2 pb-1 outline-none border-b border-gray appearance-none block focus:outline-none"
								placeholder="Passat"
							/>
						</div>

						<div className="flex flex-col">
							<label htmlFor="color">{t("profile.car_color")}</label>
							<input
								id="color"
								type="text"
								value={color}
								onChange={(e) => setColor(e.target.value)}
								className="w-full pt-2 pb-1 outline-none border-b border-gray appearance-none block focus:outline-none"
								placeholder="Черный"
							/>
						</div>
					</div>
				</div>

				<div className="flex flex-row justify-center">
					<button
						type="button"
						onClick={() => setShowModalBrand(false)}
						className="w-full py-3 text-xl text-blue"
					>
						Ок
					</button>
				</div>
			</ReactModal>

			{/* <ReactModal
				isOpen={showModalPhoto}
				onRequestClose={() => setShowModalPhoto(false)}
				closeTimeoutMS={200}
				className="container mx-auto absolute bottom-0 bg-paper px-5 pt-5 rounded-t-xl shadow-map outline-none"
				overlayClassName="fixed inset-0 bg-overlay"
			>
				<div className="container mx-auto px-5 pt-5">
					<p className="text-center text-lg py-1.5">
						{t("profile.car_photo_upload_hint")}
					</p>
					<div className="flex flex-col justify-center items-center text-center mb-4">
						<div className="max-w-xs px-7">
							<img src={car_photo} className="mb-4" />
							<label
								htmlFor="photo"
								className="bg-paper border border-solid border-blue text-blue text-medium rounded-2xl py-1.5 px-4"
							>
								{t("add_photo")}
							</label>
							<input
								id="photo"
								name="photo"
								type="file"
								onChange={selectCarPhoto}
								hidden={true}
							/>
						</div>
					</div>
				</div>
				<div className="flex flex-row justify-center">
					<button
						type="button"
						onClick={() => setShowModalPhoto(false)}
						className="w-full py-3 text-xl text-blue"
					>
						Ок
					</button>
				</div>
			</ReactModal> */}

			<ReactModal
				isOpen={showModalCertificate}
				onRequestClose={() => setShowModalCertificate(false)}
				closeTimeoutMS={200}
				className="container mx-auto absolute bottom-0 bg-paper px-5 pt-5 rounded-t-xl shadow-map outline-none"
				overlayClassName="fixed inset-0 bg-overlay"
			>
				<div className="container mx-auto px-5 pt-2">
					{/* <p className="text-center text-lg py-1.5">
						{t("profile.upload_certificate_message")}
					</p>

					<div className="flex flex-col justify-center items-center text-center mb-4">
						<div className="max-w-xs px-7">
							<img src={sor} className="mb-4" />
							<label
								htmlFor="certificate"
								className="bg-paper border border-solid border-blue text-blue text-medium rounded-2xl py-1.5 px-4"
							>
								{t("add_photo")}
							</label>
							<input
								id="certificate"
								certificate="photo"
								type="file"
								onChange={selectCertificate}
								hidden={true}
							/>
						</div>
					</div> */}

					<div className="flex flex-col space-y-4">
						<div className="flex flex-col">
							<label htmlFor="model">{t("profile.car_state_number")}</label>
							<InputMask
								id="number"
								type="text"
								value={number}
								mask="a 999 aa 999"
								formatChars={formatChars}
								onChange={(e) => setNumber(e.target.value.toUpperCase())}
								className="w-full pt-2 pb-1 outline-none border-b border-gray appearance-none block focus:outline-none"
								placeholder="А 111 АА 777"
							/>
						</div>

						<div className="flex flex-col">
							<label htmlFor="year">{t("profile.car_year")}</label>
							<input
								id="year"
								type="tel"
								value={year}
								onChange={(e) => setYear(e.target.value)}
								className="w-full pt-2 pb-1 outline-none border-b border-gray appearance-none block focus:outline-none"
								placeholder="2013"
							/>
						</div>
					</div>
				</div>

				<div className="flex flex-row justify-center">
					<button
						type="button"
						onClick={() => setShowModalCertificate(false)}
						className="w-full py-3 text-xl text-blue"
					>
						Ок
					</button>
				</div>
			</ReactModal>
		</main>
	);
};

export default DriverAddCar;
