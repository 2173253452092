import { useEffect, useState } from "react";

const UpdateDialog = () => {
	const [isShow, setIsShow] = useState(false);

	const accept = () => {
		navigator.serviceWorker.addEventListener("controllerchange", () => {
			window.location.reload();
		});

		navigator.serviceWorker.ready.then((registration) => {
			registration.waiting.postMessage({ type: "SKIP_WAITING" });
		});
	};

	useEffect(() => {
		if (!Boolean(sessionStorage.getItem("banner_updates_rejected"))) {
			navigator.serviceWorker.ready.then((registration) => {
				registration.onupdatefound = () => {
					const installingWorker = registration.installing;

					if (installingWorker != null) {
						installingWorker.onstatechange = () => {
							if (
								installingWorker.state === "installed" &&
								navigator.serviceWorker.controller
							) {
								setIsShow(true);
							}
						};
					}
				};
			});
		}
	}, []);

	if (!isShow) {
		return null;
	}

	return (
		<div className="bg-paper p-4 border-b border-graylight flex flex-col items-center z-40">
			<h2>Доступна новая версия!</h2>

			<p className="text-sm text-gray mt-1">Хотите обновить приложение?</p>

			<div className="mt-4 space-x-3">
				<button
					onClick={accept}
					className="text-sm h-10 rounded-md px-4 min-w-[9rem] bg-blue active:bg-blue/90 text-white"
				>
					Обновить
				</button>
			</div>
		</div>
	);
};

export default UpdateDialog;
