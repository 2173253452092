class DadataAPI {
  carBrands(args) {
    return this.#fetch("car_brand", {
      method: "POST",
      body: JSON.stringify({ query: args.query }),
    })
  }

  #fetch(path, init) {
    return fetch(
      `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/${path}`,
      {
        ...init,
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
          Authorization: "Token 46d3c0914c1f5c75dc902b447e0244b38f406bd5",
          ...init.headers,
        },
      },
    )
      .then((res) => res.json())
  }
}

export const dadataAPI = new DadataAPI()