import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";

import {
	user_icon,
	comment_icon,
	location_icon,
	baby_chair_icon,
	many_people_icon,
} from "../img/index";
import { DRIVER_CITY_TRADING_ROUTE } from "../utils/consts";
import Avatar from "./Avatar";

ReactModal.setAppElement("#root");

const OrderItem = ({ order, onClick }) => {
	const { t } = useTranslation();
	const [avatar, setAvatar] = useState(null);

	const payType = (param) => {
		switch (param) {
			case "CASH":
				return t("pay_method.cash");
			case "TRANSFER":
				return t("pay_method.transfer");
			case "CARD":
				return t("pay_method.card");
		}
	};

	const date = new Date(order.createdAt);

	function declOfNum(number, words) {
		return words[
			number % 100 > 4 && number % 100 < 20
				? 2
				: [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
		];
	}

	const timeSince = (date) => {
		const seconds = Math.floor((new Date() - date) / 1000);
		let interval = seconds / 31536000;

		interval = seconds / 86400;
		if (interval > 1) {
			return (
				Math.floor(interval) +
				" " +
				declOfNum(Math.floor(interval), ["д", "д", "д"])
			);
		}
		interval = seconds / 3600;
		if (interval > 1) {
			return (
				Math.floor(interval) +
				" " +
				declOfNum(Math.floor(interval), ["ч", "ч", "ч"])
			);
		}
		interval = seconds / 60;
		if (interval > 1) {
			return (
				Math.floor(interval) +
				" " +
				// declOfNum(Math.floor(interval), ["минуту", "минуты", "минут"])
				declOfNum(Math.floor(interval), ["мин", "мин", "мин"])
			);
		}
		return (
			Math.floor(seconds) +
			" " +
			// declOfNum(Math.floor(seconds), ["секунду", "секунды", "секунд"])
			declOfNum(Math.floor(seconds), ["сек", "сек", "сек"])
		);
	};

	return (
		<>
			<button type="button" className="w-full text-start active:bg-graylight/30" onClick={onClick}>
				<div className="flex flex-col justify-between px-5 p-3">
					<div className="flex flex-row">
						<div className="flex flex-col text-center items-center mr-3">
							<Avatar
								src={
									order.user.avatar
										? process.env.REACT_APP_API_URL + order.user.avatar
										: undefined
								}
								onClick={order.user.avatar ? (event) => {
									event.stopPropagation()

									setAvatar(order.user.avatar)
								} : undefined}
							/>

							<div className="mt-1">
								<p className="text-xs text-center w-14 truncate">
									{order.user.firstName
										? order.user.firstName
										: `+7${order.user.phone}`}
								</p>

								<p className="text-xs text-gray whitespace-nowrap">
									{`${order.user.avg_rating
										? order.user.avg_rating.substr(0, 4)
										: "0.00"
										} (${order.user.num_orders})`}
								</p>
							</div>
						</div>

						<div className="flex flex-col w-full">
							<div className="flex flex-row flex-1 min-h-[3rem] pb-1">
								<div className="space-y-2.5 py-1">
									<div className="flex space-x-1.5">
										<span className="inline-block w-4 h-4 border-4 border-solid border-red rounded-full" />
										<p className="text-sm leading-4 font-bold">{order.from}</p>
									</div>

									<div className="flex space-x-1.5">
										<span className="inline-block w-4 h-4 border-4 border-solid border-blue rounded-full" />
										<p className="text-sm leading-4">{order.to}</p>
									</div>
								</div>
							</div>

							{order.comment && (
								<div className="flex flex-col justify-start">
									<div className="flex flex-row justify-start items-center h-5 space-x-2.5">
										<span className="w-3 h-2.5">
											<img src={comment_icon} alt="Комментарий" />
										</span>
										<p className="text-xs">{order.comment}</p>
									</div>
								</div>
							)}

							{order.babyChair && (
								<div className="flex flex-row justify-start items-center h-5 space-x-2.5">
									<span className="w-3 h-2.5">
										<img src={baby_chair_icon} alt={t("order.baby_chair")} />
									</span>
									<p className="text-xs">{t("order.need_baby_chair")}</p>
								</div>
							)}

							{order.manyPass && (
								<div className="flex flex-row justify-start items-center h-5 space-x-2.5">
									<span className="w-3 h-2.5">
										<img src={many_people_icon} alt="Больше 4 человек" />
									</span>
									<p className="text-xs">{t("order.more_than_4_passengers")}</p>
								</div>
							)}

							<div className="flex flex-row justify-between items-center">
								<div className="flex flex-row justify-start items-center divide-x divide-gray text-center">
									<span className="text-gray text-xs pr-1.5">
										{timeSince(date)} {t("aasta")}
										{/* {timeSince(date)} */}
									</span>
									{/* <span className="flex flex-row justify-center items-center text-gray text-xs px-1.5 space-x-1 font-semibold">
                    <img src={location_icon} className="w-2.5 grayscale" alt="Расстояние" />
                    <p>1,3 км</p>
                  </span> */}
									<span className="text-xs px-1.5 text-gray">
										{payType(order.payMethod)}
									</span>
								</div>
								<span className="text-blue font-medium">{order.price} ₽</span>
							</div>
						</div>
					</div>
				</div>
			</button>

			<ReactModal
				isOpen={Boolean(avatar)}
				onRequestClose={() => setAvatar(null)}
				closeTimeoutMS={300}
				className="fixed bottom-0 top-0 left-0 right-0 flex justify-center items-center"
				overlayClassName="fixed inset-0 bg-overlay"
			>
				<div className="h-min bg-paper p-5 rounded-xl shadow-map outline-none mx-auto text-center">

					<div className="flex justify-end">
						<button type="button" className="text-blue" onClick={() => {
							setAvatar(null)
						}}><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
								<path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
							</svg>
						</button>
					</div>

					<Avatar
						size="xl"
						src={
							order.user.avatar
								? process.env.REACT_APP_API_URL + order.user.avatar
								: undefined
						}
					/>

					<p>{order.user.firstName}</p>

					<p className="text-xs text-gray whitespace-nowrap">
						{`${order.user.avg_rating
							? order.user.avg_rating.substr(0, 4)
							: "0.00"
							} (${order.user.num_orders})`}
					</p>
				</div>
			</ReactModal>
		</>
	);
};

export default OrderItem;
