export const SECOND = 1000;
export const MINUTE = SECOND * 60;

// PUBLIC
export const HOME_ROUTE = "/";
// Auth
export const AUTH_ROUTE = "/auth";
export const AUTH_SMS_ROUTE = "/auth/sms";
export const AUTH_COMPLETION_ROUTE = "/auth/completion";
// Pages
export const RULES_ROUTE = "/rules";
export const POLICY_ROUTE = "/policy";
export const NOT_FOUND_ROUTE = "/404";

// USER
export const USER_HISTORY_ROUTE = "/user/history";
// PROFILE
export const USER_PROFILE_ROUTE = "/user/profile";
export const USER_PROFILE_EDIT_ROUTE = "/user/profile/edit";
// SETTINGS
export const USER_SETTINGS_ROUTE = "/user/settings";
export const USER_CHANGE_PHONE_ROUTE = "/user/settings/phone";
export const USER_CHANGE_PHONE_CODE_ROUTE = "/user/settings/phone/code";
export const USER_ABOUT_ROUTE = "/user/settings/about";
// CITY
export const USER_CITY_START_ROUTE = "/user/city";
export const USER_CITY_SEARCH_ROUTE = "/user/city/search";
export const USER_CITY_GO_ROUTE = "/user/city/go";

// DRIVER
export const DRIVER_HISTORY_ROUTE = "/driver/history";
export const DRIVER_GARAGE_ROUTE = "/driver/garage";
// PROFILE
export const DRIVER_PROFILE_ROUTE = "/driver/profile";
export const DRIVER_PROFILE_EDIT_ROUTE = "/driver/profile/edit";
// SETTINGS
export const DRIVER_SETTINGS_ROUTE = "/driver/settings";
export const DRIVER_CHANGE_PHONE_ROUTE = "/driver/settings/phone";
export const DRIVER_CHANGE_PHONE_CODE_ROUTE = "/driver/settings/phone/code";
export const DRIVER_ABOUT_ROUTE = "/driver/settings/about";
export const DRIVER_VERIFY_ROUTE = "/driver/profile/verify";
export const DRIVER_ADD_CAR_ROUTE = "/driver/profile/add";
export const DRIVER_EDIT_CAR_ROUTE = "/driver/car/edit";
// CABINET
export const DRIVER_CABINET_ROUTE = "/driver/cabinet";
export const DRIVER_CABINET_INCOME_ROUTE = "/driver/cabinet/income";
// CITY
export const DRIVER_CITY_START_ROUTE = "/driver/city";
export const DRIVER_CITY_TRADING_ROUTE = "/driver/city/trading";
export const DRIVER_CITY_WAIT_ROUTE = "/driver/city/wait";
export const DRIVER_CITY_GO_ROUTE = "/driver/city/go";
