import { useContext } from "react";
import { Link } from "react-router-dom";

import { AppContext } from "../contexts/AppContext";
import {
	USER_CITY_START_ROUTE,
	DRIVER_CITY_START_ROUTE,
} from "../utils/consts";

const NotFound = () => {
	const { user } = useContext(AppContext);

	return (
		<div className="w-screen h-screen flex justify-center items-center">
			<div className="text-center">
				<p className="text-5xl font-bold mb-2">404</p>
				<Link
					to={
						user.user.role === "USER"
							? USER_CITY_START_ROUTE
							: user.user.role === "DRIVER"
							? DRIVER_CITY_START_ROUTE
							: "/auth"
					}
					className="text-blue"
				>
					Вернуться на главную
				</Link>
			</div>
		</div>
	);
};

export default NotFound;
