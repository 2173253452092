import { useContext, useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

import {
	DRIVER_ADD_CAR_ROUTE,
	DRIVER_CITY_GO_ROUTE,
	DRIVER_VERIFY_ROUTE,
} from "../../../utils/consts";
import { isPushNotificationSupported } from "../../../utils/push";
import OrderList from "../../../components/OrderList";
import { getActiveDriverOrder, getOrders } from "../../../http/orderApi";
import { checkVerify, getDriverCars } from "../../../http/userApi";
import { AppContext } from "../../../contexts/AppContext";
import Loader from "../../../components/Loader";
import Navbar from "../../../components/Navbar";
import { useGeolocation } from "../../../contexts/GeolocationContext";

function sendOrderNotification(order) {
	if (isPushNotificationSupported()) {
		navigator.serviceWorker.ready.then(function (serviceWorker) {
			const title = "Новый заказ";
			const body = `${order.from} - ${order.to}. ${order.price}₽`;

			const options = {
				body,
				tag: "new-order",
				icon: "/apple-touch-icon-57x57-precomposed.png",
				// actions: [{ action: "accept-order", title: "Принять" }],
				data: {
					url: "/driver/city",
				},
			};

			serviceWorker.showNotification(title, options);
		});
	}
}

const DriverCityStart = observer(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const geolocation = useGeolocation();
	const { orders, users } = useContext(AppContext);
	const [verifyState, setVerify] = useState(null);
	const [cityCars, setCityCars] = useState("REJECTED");
	const [loading, setLoading] = useState(true);
	// const [isNavigatorMode, setNavigatorMode] = useState(false);

	// // First Load
	useEffect(() => {
		async function fetchData() {
			await checkVerify().then((data) => {
				if (data !== null) setVerify(data.status);
			});

			await getDriverCars("CITY").then((data) => {
				if (data !== null) setCityCars(data.status);
			});

			await getOrders(
				null,
				1,
				geolocation?.coords
					? `${geolocation.coords.latitude},${geolocation.coords.longitude}`
					: null,
			).then((data) => orders.setOrders(data));
		}

		fetchData()
			.then(getActiveDriverOrder)
			.then((activeOrder) => {
				if (activeOrder && activeOrder.status >= 2 && activeOrder.status < 5) {
					navigate(`${DRIVER_CITY_GO_ROUTE}/${activeOrder.id}`);
				}
			})
			.finally(() => setLoading(false));
	}, [useState]);

	// // Auto Update
	useEffect(() => {
		const loadData = setInterval(() => {
			getOrders(
				null,
				1,
				geolocation?.coords
					? `${geolocation.coords.latitude},${geolocation.coords.longitude}`
					: null,
			).then((data) => {
				const prevOrderIds = orders.orders.map(({ id }) => id);

				orders.setOrders(data);

				for (const order of data) {
					if (!prevOrderIds.includes(order.id)) {
						sendOrderNotification(order);
					}
				}
			});
		}, 5000);

		return () => clearInterval(loadData);
	}, [getOrders, useState]);

	if (loading) {
		return <Loader />;
	}

	return (
		<main className="relative min-h-screen mx-auto">
			<Navbar title={t("city.title")} />

			<div className="container sm:max-w-lg mx-auto py-5">
				{cityCars === "OK" && <OrderList />}

				{cityCars !== "OK" && cityCars !== "REJECTED" && (
					<div className="flex flex-col flex-center mx-auto text-center px-5">
						<p className="text-lg font-medium">{t("city.cars.pending_msg")}</p>
					</div>
				)}

				{cityCars === "REJECTED" && (
					<div className="flex flex-col flex-center mx-auto text-center px-5">
						<p className="text-lg font-medium mb-3">
							{t("city.cars.rejected_msg")}
						</p>

						<Link
							to={`${DRIVER_ADD_CAR_ROUTE}/city`}
							className="border border-graylight rounded-2xl text-lg py-3 px-9"
						>
							{t("add")}
						</Link>
					</div>
				)}

				{/* {verifyState === "OK" && cityCars === "OK" && <OrderList />} */}

				{/* {verifyState !== "OK" && (
					<div className="flex flex-col flex-center mx-auto text-center px-5">
						<p className="text-lg font-medium mb-3">
							{t("city.driver_register_cta")}
						</p>

						<Link
							to={DRIVER_VERIFY_ROUTE}
							className="border border-graylight rounded-2xl text-lg py-3 px-9"
						>
							{t("register")}
						</Link>
					</div>
				)}

				{cityCars !== "OK" && cityCars !== "REJECTED" && (
					<div className="flex flex-col flex-center mx-auto text-center px-5">
						<p className="text-lg font-medium">{t("city.cars.pending_msg")}</p>
					</div>
				)}

				{verifyState === "OK" && cityCars === "REJECTED" && (
					<div className="flex flex-col flex-center mx-auto text-center px-5">
						<p className="text-lg font-medium mb-3">
							{t("city.cars.rejected_msg")}
						</p>

						<Link
							to={`${DRIVER_ADD_CAR_ROUTE}/city`}
							className="border border-graylight rounded-2xl text-lg py-3 px-9"
						>
							{t("add")}
						</Link>
					</div>
				)} */}
			</div>
		</main>
	);
});

export default DriverCityStart;
