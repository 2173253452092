import { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams, useNavigate } from "react-router-dom";

import { TravelMap } from "../../../components/TravelMap";
import Loader from "../../../components/Loader";
import Dialog from "../../../components/Dialog";
import {
	getMyAnswer,
	deleteAnswer,
	changeAnswerStatus,
} from "../../../http/orderApi";
import {
	DRIVER_CITY_GO_ROUTE,
	DRIVER_CITY_START_ROUTE,
} from "../../../utils/consts";

const DriverCityWait = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const { t } = useTranslation();
	const [answerInfo, setAnswerInfo] = useState({});
	const [loading, setLoading] = useState(true);
	const [status, setStatus] = useState(1);
	const timeoutRef = useRef();
	const intervalRef = useRef();

	const cancel = async () => {
		await deleteAnswer(answerInfo.id);
		navigate(DRIVER_CITY_START_ROUTE, { replace: true });
	};

	// initial load
	useEffect(() => {
		getMyAnswer(id)
			.then((data) => {
				setAnswerInfo({
					...data,
					order: {
						...data.order,
						fromPoint: data.order.fromPoint.split(",").map(Number),
						toPoint: data.order.toPoint.split(",").map(Number),
					},
				});

				if (data.order.status > 1) {
					setStatus(2);
				} else {
					// timeout
					timeoutRef.current = setTimeout(() => {
						setStatus(3);
						clearTimeout(timeoutRef.current);
						clearInterval(intervalRef.current);
						deleteAnswer(answerInfo.id);
					}, 1000 * 30);

					// long pooling
					intervalRef.current = setInterval(() => {
						getMyAnswer(id).then((data) => {
							if (data.status === 2) {
								navigate(`${DRIVER_CITY_GO_ROUTE}/${data.order.id}`, {
									replace: true,
								});
							} else {
								if (data.order.status > 1) {
									setStatus(2);
									clearTimeout(timeoutRef.current);
									clearInterval(intervalRef.current);
								} else {
									setStatus(data.status);
								}
							}
						});
					}, 5000);
				}
			})
			.finally(() => setLoading(false));

		return () => {
			clearTimeout(timeoutRef.current);
			clearInterval(intervalRef.current);
		};
	}, [id]);

	if (loading) return <Loader />;

	return (
		<main className="flex flex-col h-screen mx-auto bg-map bg-center bg-no-repeat bg-cover">
			{answerInfo.order && (
				<TravelMap
					fromPoint={answerInfo.order.fromPoint}
					toPoint={answerInfo.order.toPoint}
				/>
			)}

			<div>
				{status === 0 && (
					<Dialog message={t("city.client_reject_msg")} onClose={cancel} />
				)}

				{status === 1 && (
					<div className="flex justify-center flex-col bg-paper shadow-map p-5">
						<div className="flex flex-col justify-between px-2.5 py-2.5 text-center space-y-2.5">
							<p className="text-lg font-bold">
								{t("city.we_offer_your_price")}
							</p>
							<span className="text-xl text-blue font-bold">
								{answerInfo.price} ₽
							</span>
							<p className="text-lg">{t("city.wait_msg")}</p>
						</div>

						<div className="flex justify-center mt-4">
							<span className="circle animate-loader" />
							<span className="circle animate-loader animation-delay-200" />
							<span className="circle animate-loader animation-delay-400" />
						</div>

						<button
							type="button"
							className="text-blue text-lg font-medium py-2.5"
							onClick={cancel}
						>
							{t("back")}
						</button>
					</div>
				)}

				{status === 2 && <Dialog message={t("order.acces")} onClose={cancel} />}

				{status === 3 && (
					<Dialog
						message={t("client.not")}
						onClose={cancel}
					/>
				)}
			</div>
		</main>
	);
};

export default DriverCityWait;
