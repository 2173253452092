import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ReactModal from "react-modal";

import { optimizeImage } from "../../utils/image";
import { USER_CITY_START_ROUTE } from "../../utils/consts";
import { setInfo } from "../../http/userApi";
import LanguagePicker from "../../components/fields/LanguagePicker";
import UserNameField from "../../components/fields/UserNameField";
import Avatar from "../../components/Avatar";
import Button from "../../components/Button";

ReactModal.setAppElement("#root");

// Check inputs
const regName =
	/^[аАбБвВгГдДеЕёЁжЖзЗиИйЙкКлЛмМнНоОпПрРсСтТуУфФхХцЦчЧшШщЩъЪыЫьЬэЭюЮяЯ]+$/;

const MAX_IMAGE_FIT_SIZE = 300;

const AuthCompletion = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [selectedImage, setSelectedImage] = useState();
	const [file, setFile] = useState(null);
	// const [language, setLanguage] = useState(DEFAULT_LANG);
	// const [showModalName, setShowModalName] = useState(false);
	// const [showModalCity, setShowModalCity] = useState(false);
	// const [showModalLang, setShowModalLang] = useState(false);
	const [showModalError, setShowModalError] = useState(false);

	const [errorMessage, setErrorMessage] = useState();

	const selectFile = (e) => {
		setFile(e.target.files[0]);
		setSelectedImage(e.target.files[0]);
	};

	const sendForm = async (e) => {
		e.preventDefault();

		const _firstName = firstName.trim();
		// const _lastName = firstName.trim();

		if (_firstName === "") {
			setShowModalError(true);
			setErrorMessage("Необходимо указать имя");
			return;
		}

		// if (regName.test(_firstName) === false) {
		// 	setShowModalError(true);
		// 	setErrorMessage("Имя содержит недопустимые символы");
		// 	return;
		// }

		// if (regName.test(_lastName) === false) {
		// 	setShowModalError(true);
		// 	setErrorMessage("Фамилия содержит недопустимые символы");
		// 	return;
		// }

		const formData = new FormData();
		formData.append("firstName", firstName);
		formData.append("lastName", lastName);

		if (file) {
			const image = await optimizeImage(file, { fitSize: MAX_IMAGE_FIT_SIZE });

			formData.append("img", image);
		} else {
			setShowModalError(true);
			setErrorMessage("Аватар обязателен");
			return;
		}

		return setInfo(formData).then(() => navigate(USER_CITY_START_ROUTE));
	};

	// const fullNameDisplay =
	// 	firstName || lastName ? `${firstName} ${lastName}` : "";

	return (
		<main className="relative min-h-screen mx-auto">
			<div className="bg-paper flex flex-row justify-between items-center h-14 shadow z-10">
				<span className="flex w-7 h-3.5" />
				<h1 className="text-xl font-medium">{t("auth.signup_complete")}</h1>
				<span className="flex w-7 h-3.5" />
			</div>

			<div className="container mx-auto py-5">
				<p className="text-center font-semibold">
					{t("auth.signup_complete_desc")}
				</p>

				<div className="flex flex-col mb-5">
					<div className="mt-8">
						<label htmlFor="file" className="block w-40 h-40 mx-auto">
							<Avatar
								size="xl"
								src={
									selectedImage ? URL.createObjectURL(selectedImage) : undefined
								}
							/>
							<input
								id="file"
								name="file"
								type="file"
								onChange={selectFile}
								hidden={true}
							/>
						</label>
					</div>

					<div className="flex flex-col mx-5 mt-12">
						<div className="flex flex-col space-y-4 mb-4">
							<UserNameField
								firstName={firstName}
								lastName={lastName}
								onChange={({ firstName, lastName }) => {
									setFirstName(firstName);
									setLastName(lastName);
								}}
							/>
							<LanguagePicker />
						</div>

						<Button type="button" onClick={sendForm}>
							{t("save")}
						</Button>
					</div>
				</div>
			</div>

			<ReactModal
				isOpen={showModalError}
				onRequestClose={() => setShowModalError(false)}
				closeTimeoutMS={300}
				className="fixed bottom-0 top-0 left-0 right-0 flex justify-center items-center"
				overlayClassName="fixed inset-0 bg-overlay"
			>
				<div className="w-72 h-min bg-paper px-5 py-10 rounded-xl shadow-map outline-none mx-auto">
					<div className="f-modal-alert">
						<div className="f-modal-icon f-modal-error animate">
							<span className="f-modal-x-mark">
								<span className="f-modal-line f-modal-left animateXLeft" />
								<span className="f-modal-line f-modal-right animateXRight" />
							</span>
							<div className="f-modal-placeholder" />
							<div className="f-modal-fix" />
						</div>
					</div>

					<h1 className="font-semibold text-lg text-center mb-5">
						{t("error")}
					</h1>

					<p className="text-center mb-5">{errorMessage}</p>

					<div className="flex flex-row justify-center">
						<button
							type="button"
							onClick={() => setShowModalError(false)}
							className="w-full py-3 text-xl text-blue"
						>
							Ок
						</button>
					</div>
				</div>
			</ReactModal>
		</main>
	);
};

export default AuthCompletion;
