import {
	AUTH_ROUTE,
	AUTH_SMS_ROUTE,
	HOME_ROUTE,
	NOT_FOUND_ROUTE,
	USER_PROFILE_ROUTE,
	USER_PROFILE_EDIT_ROUTE,
	USER_SETTINGS_ROUTE,
	USER_ABOUT_ROUTE,
	POLICY_ROUTE,
	RULES_ROUTE,
	USER_CHANGE_PHONE_ROUTE,
	USER_CHANGE_PHONE_CODE_ROUTE,
	USER_CITY_START_ROUTE,
	USER_HISTORY_ROUTE,
	USER_CITY_SEARCH_ROUTE,
	DRIVER_PROFILE_ROUTE,
	DRIVER_PROFILE_EDIT_ROUTE,
	DRIVER_SETTINGS_ROUTE,
	DRIVER_ABOUT_ROUTE,
	DRIVER_CHANGE_PHONE_ROUTE,
	DRIVER_CHANGE_PHONE_CODE_ROUTE,
	DRIVER_CABINET_ROUTE,
	DRIVER_CITY_START_ROUTE,
	DRIVER_VERIFY_ROUTE,
	DRIVER_ADD_CAR_ROUTE,
	// DRIVER_CITY_TRADING_ROUTE,
	DRIVER_CITY_WAIT_ROUTE,
	USER_CITY_GO_ROUTE,
	DRIVER_CITY_GO_ROUTE,
	AUTH_COMPLETION_ROUTE,
	DRIVER_HISTORY_ROUTE,
	DRIVER_GARAGE_ROUTE,
} from "./utils/consts";
import Home from "./pages/index";
import Policy from "./pages/Policy";
import Rules from "./pages/Rules";
import Auth from "./pages/Auth/index";
import AuthSMS from "./pages/Auth/Sms";
import AuthCompletion from "./pages/Auth/Completion";
import DriverAbout from "./pages/Driver/Settings/About";
import Garage from "./pages/Driver/Garage";
import DriverChangePhone from "./pages/Driver/Settings/Phone";
import DriverChangePhoneCode from "./pages/Driver/Settings/Phone/Sms";
import DriverProfile from "./pages/Driver/Profile";
import DriverProfileEdit from "./pages/Driver/Profile/Edit";
import DriverSettings from "./pages/Driver/Settings";
import NotFound from "./pages/404";
import UserProfile from "./pages/User/Profile";
import UserProfileEdit from "./pages/User/Profile/Edit";
import UserSettings from "./pages/User/Settings";
import UserAbout from "./pages/User/Settings/About";
import UserChangePhone from "./pages/User/Settings/Phone";
import UserChangePhoneCode from "./pages/User/Settings/Phone/Sms";
import UserCityStart from "./pages/User/City";
import UserHisotry from "./pages/User/History";
import UserHisotryOrder from "./pages/User/History/Order";
import UserCitySearch from "./pages/User/City/Search";
import DriverCabinet from "./pages/Driver/Cabinet";
import DriverCityStart from "./pages/Driver/City";
import DriverVerify from "./pages/Driver/Profile/Verify";
import DriverAddCar from "./pages/Driver/Profile/Add";
import DriverCityTrading from "./pages/Driver/City/Trading";
import DriverCityWait from "./pages/Driver/City/Wait";
import UserCityGo from "./pages/User/City/Go";
import DriverCityGo from "./pages/Driver/City/Go";
import DriverHisotry from "./pages/Driver/History";
import DriverHisotryOrder from "./pages/Driver/History/Order";

export const publicRoutes = [
	{
		path: HOME_ROUTE,
		Component: <Home />,
	},
	{
		path: AUTH_ROUTE,
		Component: <Auth />,
	},
	{
		path: AUTH_SMS_ROUTE,
		Component: <AuthSMS />,
	},
	{
		path: POLICY_ROUTE,
		Component: <Policy />,
	},
	{
		path: RULES_ROUTE,
		Component: <Rules />,
	},
	{
		path: NOT_FOUND_ROUTE,
		Component: <NotFound />,
	},
];

export const userRouter = [
	{
		path: AUTH_COMPLETION_ROUTE,
		Component: <AuthCompletion />,
	},
	{
		path: USER_HISTORY_ROUTE,
		Component: <UserHisotry />,
	},
	{
		path: `${USER_HISTORY_ROUTE}/:id`,
		Component: <UserHisotryOrder />,
	},
	{
		path: USER_PROFILE_ROUTE,
		Component: <UserProfile />,
	},
	{
		path: USER_PROFILE_EDIT_ROUTE,
		Component: <UserProfileEdit />,
	},
	{
		path: USER_SETTINGS_ROUTE,
		Component: <UserSettings />,
	},
	{
		path: USER_ABOUT_ROUTE,
		Component: <UserAbout />,
	},
	{
		path: USER_CHANGE_PHONE_ROUTE,
		Component: <UserChangePhone />,
	},
	{
		path: USER_CHANGE_PHONE_CODE_ROUTE,
		Component: <UserChangePhoneCode />,
	},
	{
		path: USER_CITY_START_ROUTE,
		Component: <UserCityStart />,
	},
	{
		path: `${USER_CITY_SEARCH_ROUTE}/:id`,
		Component: <UserCitySearch />,
	},
	{
		path: `${USER_CITY_GO_ROUTE}/:id`,
		Component: <UserCityGo />,
	},
];

export const driverRouter = [
	{
		path: AUTH_COMPLETION_ROUTE,
		Component: <AuthCompletion />,
	},
	{
		path: DRIVER_PROFILE_ROUTE,
		Component: <DriverProfile />,
	},
	{
		path: DRIVER_GARAGE_ROUTE,
		Component: <Garage />,
	},
	{
		path: DRIVER_PROFILE_EDIT_ROUTE,
		Component: <DriverProfileEdit />,
	},
	{
		path: DRIVER_SETTINGS_ROUTE,
		Component: <DriverSettings />,
	},
	{
		path: DRIVER_ABOUT_ROUTE,
		Component: <DriverAbout />,
	},
	{
		path: DRIVER_HISTORY_ROUTE,
		Component: <DriverHisotry />,
	},
	{
		path: `${DRIVER_HISTORY_ROUTE}/:id`,
		Component: <DriverHisotryOrder />,
	},
	{
		path: DRIVER_CHANGE_PHONE_ROUTE,
		Component: <DriverChangePhone />,
	},
	{
		path: DRIVER_CHANGE_PHONE_CODE_ROUTE,
		Component: <DriverChangePhoneCode />,
	},
	{
		path: DRIVER_CABINET_ROUTE,
		Component: <DriverCabinet />,
	},
	{
		path: DRIVER_VERIFY_ROUTE,
		Component: <DriverVerify />,
	},
	{
		path: `${DRIVER_ADD_CAR_ROUTE}/:id`,
		Component: <DriverAddCar />,
	},
	{
		path: DRIVER_CITY_START_ROUTE,
		Component: <DriverCityStart />,
	},
	// {
	// 	path: `${DRIVER_CITY_TRADING_ROUTE}/:id`,
	// 	Component: <DriverCityTrading />,
	// },
	{
		path: `${DRIVER_CITY_WAIT_ROUTE}/:id`,
		Component: <DriverCityWait />,
	},
	{
		path: `${DRIVER_CITY_GO_ROUTE}/:id`,
		Component: <DriverCityGo />,
	},
];
