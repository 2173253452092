import React from "react";
import { useTranslation } from "react-i18next";

import "./Popup.css";

const Popup = ({ active, setActive, children }) => {
	const { t } = useTranslation();

	return (
		<div
			className={active ? "popup active" : "popup"}
			onClick={() => setActive(false)}
		>
			<div
				className={active ? "popup-content active" : "popup-content"}
				onClick={(e) => e.stopPropagation()}
			>
				{children}
				<div className="mt-4">
					<button
						className="border border-graylight px-5 py-1.5 rounded-lg"
						onClick={() => setActive(false)}
					>
						{t("close")}
					</button>
				</div>
			</div>
		</div>
	);
};

export default Popup;
