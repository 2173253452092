import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import Navbar from "../components/Navbar";
import { AppContext } from "../contexts/AppContext";
import {
	DRIVER_ABOUT_ROUTE,
	AUTH_ROUTE,
	USER_ABOUT_ROUTE,
} from "../utils/consts";

const Rules = () => {
	const { t } = useTranslation();
	const { user } = useContext(AppContext);

	let link;
	if (user.user.role === "DRIVER") {
		link = DRIVER_ABOUT_ROUTE;
	} else if (user.user.role === "CLIENT") {
		link = USER_ABOUT_ROUTE;
	} else {
		link = AUTH_ROUTE;
	}

	return (
		<main className="relative min-h-screen mx-auto">
			<Navbar title={t("settings.rules")} backLink={link} />

			<div className="container mx-auto py-5">
				<div className="flex flex-col mx-5">
					<p>Правила сервиса</p>
					<p>&nbsp;</p>
					<p>
						Нижеперечисленные правила обязательны для выполнения всеми
						пользователями без исключений.
					</p>
					<p>
						Сервис оставляет за собой право изменять правила без уведомления.
					</p>
					<p>
						Дополнения и изменения правил начинают действовать с момента их
						опубликования.
					</p>
					<p>&nbsp;</p>
					<p>На сервисе запрещено:</p>
					<p>&nbsp;</p>
					<p>Указывать недостоверную информацию в заказе.</p>
					<p>&nbsp;</p>
					<p>
						Использовать Сервис для доставки алкогольной и табачной продукции.
					</p>
					<p>&nbsp;</p>
					<p>В комментариях к заказу:</p>
					<p>&nbsp;</p>
					<p>-Указывать информацию не относящийся к заказу.</p>
					<p>-Размещать чужую персональную информацию.</p>
					<p>-Разжигать национальную/расовую/религиозную вражду.</p>
					<p>-оскорблять кого либо, писать маты.</p>
				</div>
			</div>
		</main>
	);
};

export default Rules;
