import { useTranslation } from "react-i18next";

const Dialog = ({ message, onClose }) => {
	const { t } = useTranslation();

	return (
		<div className="fixed inset-0 bg-black/70 flex justify-center flex-col z-10 p-5">
			<div className="flex flex-col items-center bg-paper rounded-lg p-5">
				<div className="p-2.5 text-center">
					<p className="text-lg font-bold">{message}</p>
				</div>

				<button
					className="text-blue text-lg font-medium p-2.5"
					onClick={onClose}
				>
					{t("back")}
				</button>
			</div>
		</div>
	);
};

export default Dialog
