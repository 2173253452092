import React, { useEffect, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";

import Popup from "../../components/Popup/Popup";
import { authSendCode, authCheckCode, getCode } from "../../http/userApi";
import { AppContext } from "../../contexts/AppContext";
import {
	AUTH_ROUTE,
	DRIVER_CITY_START_ROUTE,
	USER_CITY_START_ROUTE,
} from "../../utils/consts";
import InputCode from "../../js/InputCode";
import { logo } from "../../img/index";

const AuthSMS = observer(() => {
	const { t } = useTranslation();
	const { user } = useContext(AppContext);

	const phone = localStorage.getItem("phone");
	const [counter, setCounter] = React.useState(60);
	const [message, setMessage] = useState("");
	const [popupActive, setPopupActive] = useState(false);
        const [showCode, setShowCode] = useState('')
	let timer = `(${counter})`;

	if (!phone) {
		window.location.href = AUTH_ROUTE;
	}

	const sendForm = async (code) => {
		const data = await authCheckCode(phone, code);
		if (data.message) {
			setPopupActive(true);
			return setMessage(data.message);
		}

		user.setUser(data);

		if (data.role === "USER") {
			window.location.href = USER_CITY_START_ROUTE;
		} else if (data.role === "DRIVER") {
			window.location.href = DRIVER_CITY_START_ROUTE;
		}
	};

	const changePhone = () => {
		localStorage.removeItem("phone");
		window.location.href = AUTH_ROUTE;
	};

	const resendCode = async () => {
		if (counter === 0) {
			await authSendCode(phone);
			setPopupActive(true);
			setMessage("Новый код отправлен");
		}
	};

	useEffect(() => {
		const timer =
			counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
		return () => clearInterval(timer);
	}, [counter]);

	if (counter === 0) {
		timer = "";
	}
  // DEV
  useEffect(() => {
    getCode(phone).then(data => setShowCode(data.code))
  }, [])
	return (
		<main className="h-screen mx-auto flex flex-col text-center">
			<div className="mt-28">
				<img src={logo} className="w-24 h-24 mx-auto" />
				<h3 className="text-base font-semibold text-blue">www.uprider.ru</h3>
			</div>

			<div className="container mx-auto sm:max-w-lg mt-10">
				<h4 className="text-xl">{t("auth.sms_code")}</h4>
                                    <h3>{showCode}</h3>
				<form className="flex flex-col px-6">
					<InputCode
						length={6}
						onComplete={(code) => {
							sendForm(code);
						}}
					/>

					<div className={counter ? "text-gray" : ""} onClick={resendCode}>
						{t("auth.send_again")} {timer}
					</div>

					<p className="text-blue my-2" onClick={changePhone}>
						{t("settings.change_number")}
					</p>
				</form>
			</div>

			<Popup active={popupActive} setActive={setPopupActive}>
				{message}
			</Popup>
		</main>
	);
});

export default AuthSMS;
