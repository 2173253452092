import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import {
	AUTH_ROUTE,
	USER_CITY_START_ROUTE,
	DRIVER_CITY_START_ROUTE,
	USER_CITY_GO_ROUTE,
	DRIVER_CITY_GO_ROUTE,
	USER_CITY_SEARCH_ROUTE,
	DRIVER_CITY_TRADING_ROUTE,
} from "../utils/consts";
import { logo } from "../img/index";
import { AppContext } from "../contexts/AppContext";

const Home = () => {
	const { t } = useTranslation();
	const { user } = useContext(AppContext);

	setTimeout(() => {
		if (user.user.role === "USER") {
			window.location.href = USER_CITY_START_ROUTE;
		} else if (user.user.role === "DRIVER") {
			window.location.href = DRIVER_CITY_START_ROUTE;
		} else {
			window.location.href = AUTH_ROUTE;
		}
	}, 5000);

	return (
		<main className="container h-screen mx-auto flex flex-col justify-center text-center">
			<div className="container fixed top-0 mt-10" />

			<div className="mb-20">
				<img src={logo} className="w-24 h-24 mx-auto" />
				<h1 className="text-3xl font-extrabold text-white">uprider.ru</h1>
			</div>

			<div className="container fixed bottom-0 mb-5">
				<h2 className="text-2xl font-extrabold text-white">
					{t("splash.title")}
				</h2>
			</div>
		</main>
	);
};

export default Home;
