import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { Switch } from "@headlessui/react";

import Button from "./Button";
import Avatar from "./Avatar";
import { TravelMap } from "./TravelMap";
import { getOrderDriver, getDriverInfo, createAnswer } from "../http/orderApi";
import { comment_icon, many_people_icon, baby_chair_icon } from "../img";
import {
	DRIVER_CITY_START_ROUTE,
	DRIVER_CITY_WAIT_ROUTE,
} from "../utils/consts";

export const SelectedOrderModal = ({ order, onClose }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const [orderInfo] = useState({
		...order,
		fromPoint: order.fromPoint.split(",").map(Number),
		toPoint: order.toPoint.split(",").map(Number),
	});
	const [clientInfo, setClientInfo] = useState({});
	const [price, setPrice] = useState();
	const [pending, setPending] = useState(false);
	const [isNavigatorMode, setNavigatorMode] = useState(false);
	const id = order.id;

	useEffect(() => {
		getDriverInfo(order.userId).then((data) => setClientInfo(data));
	}, []);

	const payType = (param) => {
		switch (param) {
			case "CASH":
				return t("pay_method.cash");
			case "TRANSFER":
				return t("pay_method.transfer");
			case "CARD":
				return t("pay_method.card");
		}
	};

	const date = new Date(orderInfo.createdAt);

	function declOfNum(number, words) {
		return words[
			number % 100 > 4 && number % 100 < 20
				? 2
				: [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
		];
	}

	const timeSince = (date) => {
		var seconds = Math.floor((new Date() - date) / 1000);
		var interval = seconds / 31536000;

		interval = seconds / 86400;
		if (interval > 1) {
			return (
				Math.floor(interval) +
				" " +
				declOfNum(Math.floor(interval), ["день", "дня", "дней"])
			);
		}
		interval = seconds / 3600;
		if (interval > 1) {
			return (
				Math.floor(interval) +
				" " +
				declOfNum(Math.floor(interval), ["час", "часа", "часов"])
			);
		}
		interval = seconds / 60;
		if (interval > 1) {
			return (
				Math.floor(interval) +
				" " +
				declOfNum(Math.floor(interval), ["минуту", "минуты", "минут"])
			);
		}
		return (
			Math.floor(seconds) +
			" " +
			declOfNum(Math.floor(seconds), ["секунду", "секунды", "секунд"])
		);
	};

	const sendAnswer = (timer) => {
		if (pending) return;

		setPending(true);
		return createAnswer(price, timer, id, isNavigatorMode).then(() => {
			navigate(`${DRIVER_CITY_WAIT_ROUTE}/${id}`);
		});
	};

	return (
		<div className="fixed inset-0 z-20 bg-paper rounded-xl shadow-map outline-none">
			<main className="flex flex-col h-screen mx-auto bg-map bg-center bg-no-repeat bg-cover">
				<TravelMap
					fromPoint={orderInfo.fromPoint}
					toPoint={orderInfo.toPoint}
				/>

				<div className="flex justify-center flex-col z-10">
					<div className="container sm:max-w-lg flex flex-col bg-paper mx-auto shadow-map p-5 pt-7">
						<div className="px-5 flex items-center justify-center gap-3 mb-6">
							{/* <p>Один</p>
							<ReactSwitch
								isOn={isNavigatorMode}
								handleToggle={() => setNavigatorMode(!isNavigatorMode)}
							/>
							<p>Со штурманом</p> */}
							<Switch
								checked={isNavigatorMode}
								onChange={setNavigatorMode}
								className="relative inline-flex h-[38px] w-[300px] shrink-0 cursor-pointer rounded-full border-2 border-graylight transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-graylight focus-visible:ring-opacity-75"
							>
								<span className="sr-only">Use setting</span>
								<span
									aria-hidden="true"
									className={`${isNavigatorMode ? "translate-x-full" : "translate-x-0"} absolute pointer-events-none inline-block h-[34px] w-1/2 transform rounded-full bg-graylight shadow-lg ring-0 transition duration-200 ease-in-out`}
								/>
								<p className="z-10 flex-1 h-full flex items-center justify-center">Один</p>
								<p className="z-10 flex-1 h-full flex items-center justify-center">Не один</p>
							</Switch>
						</div>
						{!price && (
							<>
								<div className="flex flex-row">
									<div className="flex flex-col text-center items-center mr-3">
										<Avatar
											src={
												orderInfo.user.avatar
													? process.env.REACT_APP_API_URL +
													orderInfo.user.avatar
													: undefined
											}
										/>

										<div className="mt-1">
											<p className="text-xs text-center w-14 truncate">
												{orderInfo.user.firstName
													? orderInfo.user.firstName
													: `+7${orderInfo.user.phone}`}
											</p>

											<p className="text-xs text-gray whitespace-nowrap">
												{`${orderInfo.user.avg_rating
													? orderInfo.user.avg_rating.substr(0, 4)
													: "0.00"
													} (${orderInfo.user.num_orders})`}
											</p>
										</div>
									</div>

									<div className="flex flex-col w-full">
										<div className="flex flex-row flex-1 min-h-[3rem] pb-1">
											<div className="space-y-2.5 py-0.5">
												<div className="flex space-x-1.5">
													<span className="inline-block w-4 h-4 border-4 border-solid border-red rounded-full" />
													<p className="text-sm leading-4 font-bold">
														{orderInfo.from}
													</p>
												</div>

												<div className="flex space-x-1.5">
													<span className="inline-block w-4 h-4 border-4 border-solid border-blue rounded-full" />
													<p className="text-sm leading-4">{orderInfo.to}</p>
												</div>
											</div>
										</div>

										{orderInfo.comment && (
											<div className="flex flex-col justify-start">
												<div className="flex flex-row justify-start items-center h-5 space-x-2.5">
													<span className="w-3 h-2.5">
														<img src={comment_icon} alt="Комментарий" />
													</span>
													<p className="text-xs">{orderInfo.comment}</p>
												</div>
											</div>
										)}

										<div className="flex flex-row justify-start items-center space-x-3">
											{orderInfo.babyChair && (
												<div className="flex flex-row justify-start items-center h-5 space-x-2.5">
													<span className="w-3 h-2.5">
														<img
															src={baby_chair_icon}
															alt={t("order.baby_chair")}
														/>
													</span>
													<p className="text-xs">
														{t("order.need_baby_chair")}
													</p>
												</div>
											)}

											{orderInfo.manyPeople && (
												<div className="flex flex-row justify-start items-center h-5 space-x-2.5">
													<span className="w-3 h-2.5">
														<img
															src={many_people_icon}
															alt="Больше 4 человек"
														/>
													</span>
													<p className="text-xs">
														{t("order.more_than_4_passengers")}
													</p>
												</div>
											)}
										</div>

										<div className="flex flex-row justify-between items-center">
											<div className="flex flex-row justify-start items-center divide-x divide-gray text-center">
												<span className="text-gray text-xs pr-1.5">
													{timeSince(date)} {t("aasta")}
												</span>
												{/* <span className="flex flex-row justify-center items-center text-gray text-xs px-1.5 space-x-1 font-semibold">
														<img src={location_icon} className="w-2.5 grayscale" alt="Расстояние" />
														<p>1,3 км</p>
													</span> */}
												<span className="text-xs px-1.5 text-gray">
													{payType(orderInfo.payMethod)}
												</span>
											</div>
											<span className="text-blue font-medium">
												{orderInfo.price} ₽
											</span>
										</div>
									</div>
								</div>

								<div className="flex flex-col mt-4 text-center">
									<Button onClick={() => setPrice(orderInfo.price)}>
										{t("city.accept_order_button", { price: orderInfo.price })}
									</Button>

									<p className="font-medium mt-4">
										{t("city.offer_your_price")}:
									</p>

									<div className="flex mt-3 space-x-2.5">
										<Button
											className="w-full"
											onClick={() => setPrice(Math.round(orderInfo.price + 50))}
										>
											{Math.round(orderInfo.price + 50)} ₽
										</Button>

										<Button
											className="w-full"
											onClick={() =>
												setPrice(Math.round(orderInfo.price + 100))
											}
										>
											{Math.round(orderInfo.price + 100)} ₽
										</Button>

										<Button
											className="w-full"
											onClick={() => setPrice(Math.round(orderInfo.price * 2))}
										>
											{Math.round(orderInfo.price * 2)} ₽
										</Button>
									</div>
								</div>

								<button
									className="text-gray text-lg font-medium py-2.5 mt-4"
									onClick={onClose}
								>
									{t("back")}
								</button>
							</>
						)}

						{price && (
							<>
								<div>
									<p className="text-lg font-medium text-center">
										{t("city.waiting_time")}
									</p>

									<div className="flex flex-col mt-3 space-y-2.5">
										<Button disabled={pending} onClick={() => sendAnswer(5)}>
											5 {t("time.minute")}
										</Button>
										<Button disabled={pending} onClick={() => sendAnswer(10)}>
											10 {t("time.minute")}
										</Button>
										<Button disabled={pending} onClick={() => sendAnswer(15)}>
											15 {t("time.minute")}
										</Button>
									</div>
								</div>

								<button
									className="text-gray text-lg font-medium py-2.5 mt-4"
									onClick={() => setPrice("")}
								>
									{t("back")}
								</button>
							</>
						)}
					</div>
				</div>
			</main>
		</div>
	);
};
