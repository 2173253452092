import { pushRegister, pushUnregister } from "../http/userApi";

function urlBase64ToUint8Array(base64String) {
	const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
	const base64 = (base64String + padding)
		.replace(/\-/g, "+")
		.replace(/_/g, "/");

	const rawData = window.atob(base64);
	const outputArray = new Uint8Array(rawData.length);

	for (let i = 0; i < rawData.length; ++i) {
		outputArray[i] = rawData.charCodeAt(i);
	}
	return outputArray;
}

export async function createNotificationSubscription() {
	//wait for service worker installation to be ready
	const serviceWorker = await navigator.serviceWorker.ready;

	const existsSub = await serviceWorker.pushManager.getSubscription();

	if (existsSub === null) {
		// subscribe and return the subscription
		const sub = await serviceWorker.pushManager.subscribe({
			userVisibleOnly: true,
			applicationServerKey: urlBase64ToUint8Array(
				process.env.REACT_APP_VAPID_KEY,
			),
		});

		return pushRegister(sub);
	} else {
		return pushRegister(existsSub);
	}
}

export async function deleteNotificationSubscription() {
	//wait for service worker installation to be ready
	const serviceWorker = await navigator.serviceWorker.ready;

	const sub = await serviceWorker.pushManager.getSubscription();

	await sub.unsubscribe();

	if (sub !== null) {
		await pushUnregister(sub);
	}
}

export function isPushNotificationSupported() {
  return "serviceWorker" in navigator && "PushManager" in window;
}
