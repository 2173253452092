import { useEffect, useState, useMemo } from "react";

export function useTimer(deadline) {
	const parsedDeadline = useMemo(() => deadline, [deadline]);
	const [time, setTime] = useState(parsedDeadline - Date.now());
	
	useEffect(() => {
		const interval = setInterval(
			() => setTime(parsedDeadline - Date.now()),
			1000,
		);

		return () => clearInterval(interval);
	}, [parsedDeadline]);

	return time
}
