import { createContext, useContext, useEffect, useState, useMemo } from "react";
import { useGeolocated } from "react-geolocated";
import axios from "axios";

const GeolocationContext = createContext();

const reqInstance = axios.create({
	headers: {
		"Content-Type": "application/json",
		Accept: "application/json",
		Authorization: "Token 46d3c0914c1f5c75dc902b447e0244b38f406bd5",
	},
});

export const WithGeolocation = ({ children }) => {
	const {
		coords,
		isGeolocationAvailable,
		isGeolocationEnabled,
		getPosition,
		positionError,
	} = useGeolocated({
		positionOptions: {
			enableHighAccuracy: false,
		},
		userDecisionTimeout: 5000,
		watchPosition: true,
	});
	const [address, setAddress] = useState(null);

	useEffect(() => {
		if (coords) {
			reqInstance
				.post(
					"https://suggestions.dadata.ru/suggestions/api/4_1/rs/geolocate/address",
					{ lat: coords.latitude, lon: coords.longitude },
				)
				.then(({ data }) => {
					setAddress(data.suggestions[0].data);
				});
		}
	}, [coords]);

	const contextValue = useMemo(
		() => ({
			coords,
			isAvailable: isGeolocationAvailable,
			isEnabled: isGeolocationEnabled,
			address,
			getPosition,
			positionError,
		}),
		[
			coords,
			isGeolocationAvailable,
			isGeolocationEnabled,
			address,
			getPosition,
			positionError,
		],
	);

	return (
		<GeolocationContext.Provider value={contextValue}>
			{children}
		</GeolocationContext.Provider>
	);
};

export const useGeolocation = () => useContext(GeolocationContext);
