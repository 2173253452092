import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { getOneOrder } from "../../../http/orderApi";

const UserHisotryOrder = () => {
	const { t } = useTranslation();
	const { id } = useParams();
	const [order, setOrder] = useState({});

	useEffect(() => {
		getOneOrder(id).then((data) => {
			setOrder(data);
		});
	}, []);

	return (
		<div className="w-64 flex flex-col justify-center mx-auto mt-10">
			<p className="text-center mb-5">
				{t("order")} №{order.id}
			</p>

			<div className="border p-2 flex flex-col space-y-3">
				<p>Тип: {order.type}</p>
				<p>
					{t("order.from")}: {order.from}
				</p>
				<p>
					{t("order.to")}: {order.to}
				</p>
				<p>
					{t("order.price")}: {order.price}
				</p>
				<p>
					{t("order.pay_method")}: {order.payMethod}
				</p>
				<p>
					{t("order.comment")}: {order.comment}
				</p>
				<p>
					{t("order.baby_chair")}: {order.babyChair}
				</p>
				<p>
					{t("order.more_than_4_passengers")}: {order.manyPeople}
				</p>
				{order.status === 0 ? t("order.canceled") : ""}
			</div>
		</div>
	);
};

export default UserHisotryOrder;
