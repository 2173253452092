import cx from "classnames";

const Avatar = ({ src, className, size = "md", onClick }) => {
	return (
		<span
			className={cx(
				"relative inline-flex rounded-full bg-graylight text-gray overflow-hidden",
				{
					sm: "w-10 h-10",
					md: "w-14 h-14",
					xl: "w-40 h-40",
				}[size],
				className,
			)}
			onClick={onClick}
		>
			<div className="absolute inset-0 flex items-center justify-center">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 24 24"
					fill="currentColor"
					className={
						{
							sm: "w-8 h-8",
							md: "w-10 h-10",
							xl: "w-20 h-20",
						}[size]
					}
				>
					<path
						fillRule="evenodd"
						d="M7.5 6a4.5 4.5 0 119 0 4.5 4.5 0 01-9 0zM3.751 20.105a8.25 8.25 0 0116.498 0 .75.75 0 01-.437.695A18.683 18.683 0 0112 22.5c-2.786 0-5.433-.608-7.812-1.7a.75.75 0 01-.437-.695z"
						clipRule="evenodd"
					/>
				</svg>
			</div>

			{src && (
				<img
					src={src}
					className="absolute inset-0 m-auto"
					alt="Аватар пользователя"
				/>
			)}
		</span>
	);
};

export default Avatar;
