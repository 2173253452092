import { useContext, useEffect, useState, useMemo } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { observer } from "mobx-react-lite";
import ReactStars from "react-rating-stars-component";
import useSound from "use-sound";
import cx from "classnames";

import { AppContext } from "../../../contexts/AppContext";
import { useGeolocation } from "../../../contexts/GeolocationContext";
import {
	changeStatus,
	getDriverInfo,
	getOrderDriver,
} from "../../../http/orderApi";
import Loader from "../../../components/Loader";
import Button from "../../../components/Button";
import { TravelMap } from "../../../components/TravelMap";
import Avatar from "../../../components/Avatar";
import Dialog from "../../../components/Dialog";
import {
	baby_chair_icon,
	comment_icon,
	many_people_icon,
	star_icon,
} from "../../../img";
import { setReviewDriver } from "../../../http/userApi";
import { DRIVER_CITY_START_ROUTE, MINUTE, SECOND } from "../../../utils/consts";
import { useTimer } from "../../../utils/useTimer";
import NotifySfx from "../../../sounds/music_marimba_chord.mp3";

const LongWaitNotifification = ({ deadline }) => {
	const { t } = useTranslation();
	const time = useTimer(deadline);

	return (
		<div className="bg-paper rounded-md p-2 px-3 text-center">
			{time <= 0 ? (
				<p className="text-center">{t("city.driver_long_wait_message")}</p>
			) : (
				<span className="font-bold text-xl">
					{Math.floor((time / MINUTE) % 60)}:{Math.floor((time / SECOND) % 60)}
				</span>
			)}
		</div>
	);
};

const OrderInfo = ({ clientInfo, orderData, activePoint }) => {
	const { t } = useTranslation();
	const geolocation = useGeolocation();

	const date = new Date(orderData.createdAt);

	function declOfNum(number, words) {
		return words[
			number % 100 > 4 && number % 100 < 20
				? 2
				: [2, 0, 1, 1, 1, 2][number % 10 < 5 ? Math.abs(number) % 10 : 5]
		];
	}

	const timeSince = (date) => {
		const seconds = Math.floor((new Date() - date) / 1000);
		let interval = seconds / 31536000;

		interval = seconds / 86400;
		if (interval > 1) {
			return (
				Math.floor(interval) +
				" " +
				declOfNum(Math.floor(interval), ["день", "дня", "дней"])
			);
		}
		interval = seconds / 3600;
		if (interval > 1) {
			return (
				Math.floor(interval) +
				" " +
				declOfNum(Math.floor(interval), ["час", "часа", "часов"])
			);
		}
		interval = seconds / 60;
		if (interval > 1) {
			return (
				Math.floor(interval) +
				" " +
				declOfNum(Math.floor(interval), ["минуту", "минуты", "минут"])
			);
		}
		return (
			Math.floor(seconds) +
			" " +
			declOfNum(Math.floor(seconds), ["секунду", "секунды", "секунд"])
		);
	};

	// Functions
	const payType = (param) => {
		switch (param) {
			case "CASH":
				return t("pay_method.cash");
			case "TRANSFER":
				return t("pay_method.transfer");
			case "CARD":
				return t("pay_method.card");
		}
	};

	return (
		<div>
			<div className="flex flex-col mb-1.5">
				<div className="flex items-center justify-between">
					<div className="flex items-center">
						<Avatar
							className="mr-3"
							src={
								clientInfo.avatar
									? process.env.REACT_APP_API_URL + clientInfo.avatar
									: undefined
							}
						/>

						<div>
							<p className="leading-5">
								{clientInfo.firstName
									? clientInfo.firstName
									: `+7${clientInfo.phone}`}
							</p>

							<div className="flex flex-row items-center space-x-0.5 text-gray">
								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="currentColor"
									className="w-4 h-4 text-gold"
								>
									<path
										fillRule="evenodd"
										d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
										clipRule="evenodd"
									/>
								</svg>

								<p className="text-xs mt-0.5">
									{`${clientInfo.rating ? clientInfo.rating.substr(0, 4) : "0.00"
										} (${clientInfo.orders})`}
								</p>
							</div>
						</div>
					</div>

					<a
						href={`tel:+7${clientInfo.phone}`}
						className="inline-block rounded-full border-blue text-blue border-2 p-3"
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							viewBox="0 0 24 24"
							fill="currentColor"
							className="w-5 h-5"
						>
							<path
								fillRule="evenodd"
								d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
								clipRule="evenodd"
							/>
						</svg>
					</a>
				</div>

				<div className="space-y-3 mt-5">
					<div className="flex space-x-1.5">
						<span className="inline-block w-4 h-4 border-4 border-solid border-red rounded-full" />
						<p
							className={cx(
								"text-sm leading-4",
								activePoint === 1 && "font-bold",
							)}
						>
							{orderData.from}
							{orderData.entrance ? `, подъезд ${orderData.entrance}` : ""}{" "}
							{activePoint === 1 && geolocation.coords && (
								<>
									{" "}
									<a
										href={`https://2gis.ru/routeSearch/rsType/car/from/${geolocation.coords.longitude},${geolocation.coords.latitude}/to/${orderData.fromPoint[0]},${orderData.fromPoint[1]}`}
										className="text-xs text-blue whitespace-nowrap"
									>
										(Открыть в 2GIS)
									</a>
								</>
							)}
						</p>
					</div>

					<div className="flex space-x-1.5">
						<span className="inline-block w-4 h-4 border-4 border-solid border-blue rounded-full" />
						<p
							className={cx(
								"text-sm leading-4",
								activePoint === 2 && "font-bold",
							)}
						>
							{orderData.to}
						</p>
						{activePoint === 2 && geolocation.coords && (
							<>
								{" "}
								<a
									href={`https://2gis.ru/routeSearch/rsType/car/from/${geolocation.coords.longitude},${geolocation.coords.latitude}/to/${orderData.toPoint[0]},${orderData.toPoint[1]}`}
									className="text-xs text-blue whitespace-nowrap"
								>
									(Открыть в 2GIS)
								</a>
							</>
						)}
					</div>
				</div>
			</div>

			{orderData.comment && (
				<div className="flex flex-row justify-start items-center space-x-2.5">
					<span className="w-3 h-2.5">
						<img src={comment_icon} />
					</span>
					<p className="text-xs">{orderData.comment}</p>
				</div>
			)}

			<div className="flex flex-row justify-start items-center space-x-3">
				{orderData.babyChair && (
					<div className="flex flex-row justify-start items-center space-x-2.5">
						<span className="w-3 h-2.5">
							<img src={baby_chair_icon} alt="Детское кресло" />
						</span>
						<p className="text-xs">{t("order.need_baby_chair")}</p>
					</div>
				)}

				{orderData.manyPeople && (
					<div className="flex flex-row justify-start items-center space-x-2.5">
						<span className="w-3 h-2.5">
							<img src={many_people_icon} alt="Больше 4 человек" />
						</span>
						<p className="text-xs">{t("order.order_cancel")}</p>
					</div>
				)}
			</div>

			<div className="flex flex-row justify-between items-center">
				<div className="flex flex-row justify-start items-center divide-x divide-gray text-center">
					<span className="text-gray text-xs pr-1.5">
						{timeSince(date)} {t("back")}
					</span>

					{/* <span className="flex flex-row justify-center items-center text-gray text-xs space-x-1 px-1.5 font-semibold">
										<img src={location_icon} />
										<p>1,3 км</p>
									</span> */}

					<span className="text-xs px-1.5 text-gray">
						{payType(orderData.payMethod)}
					</span>
				</div>

				<span className="text-blue text-lg font-medium">
					{orderData.price} ₽
				</span>
			</div>
		</div>
	);
};

const DriverCityGo = observer(() => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { user } = useContext(AppContext);
	const params = useParams();
	const [orderData, setOrderData] = useState(null);
	const [clientInfo, setClientInfo] = useState({});
	const [clientPhone, setClientPhone] = useState(false);
	const [currentStatus, setCurrentStatus] = useState(null);
	const [leaving, setLeaving] = useState(false);
	const [rating, setRating] = useState(5);
	const [reviewText, setReviewText] = useState("");
	const [loading, setLoading] = useState(true);
	const [playNotify] = useSound(NotifySfx);
	const { sendMessage, lastMessage, readyState } = useWebSocket(
		process.env.REACT_APP_WS_URL,
		{
			shouldReconnect: (closeEvent) => true,
		},
	);

	useEffect(() => {
		getOrderDriver(params.id)
			.then((data) => {
				setOrderData({
					...data,
					fromPoint: data.fromPoint.split(",").map(Number),
					toPoint: data.toPoint.split(",").map(Number),
				});
				setCurrentStatus(data.status);
				setLeaving(data.leaving);
				getDriverInfo(data.userId).then((data) => setClientInfo(data));
			})
			.finally(() => setLoading(false));
	}, []);

	useEffect(() => {
		const handleVisibilityChange = () => {
			if (document.visibilityState === "visible") {
				getOrderDriver(params.id)
					.then((data) => {
						setCurrentStatus(data.status);
						setLeaving(data.leaving);
					})
			}
		}

		document.addEventListener("visibilitychange", handleVisibilityChange);

		return () => {
			document.removeEventListener("visibilitychange", handleVisibilityChange)
		}
	}, [])

	useEffect(() => {
		if (readyState === ReadyState.OPEN) {
			sendMessage(
				JSON.stringify({
					id: params.id,
					username: `+7${user.user.phone}`,
					method: "connection",
				}),
			);
		}
	}, [readyState]);

	useEffect(() => {
		if (lastMessage !== null) {
			const msg = JSON.parse(lastMessage.data);

			if (msg.leaving) {
				setLeaving(true);
				playNotify();
			}

			if (msg.cancel) setCurrentStatus(0);
		}
	}, [lastMessage]);

	const inPlace = async () => {
		await changeStatus(params.id, 3);
		setCurrentStatus(3);
		sendMessage(
			JSON.stringify({
				id: params.id,
				status: 3,
			}),
		);
	};

	const startTrip = async () => {
		await changeStatus(params.id, 4);
		setCurrentStatus(4);
		sendMessage(
			JSON.stringify({
				id: params.id,
				status: 4,
			}),
		);
	};

	const completeTrip = async () => {
		await changeStatus(params.id, 5);
		setCurrentStatus(5);
		sendMessage(
			JSON.stringify({
				id: params.id,
				status: 5,
			}),
		);
	};

	const cancelTrip = async () => {
		await changeStatus(params.id, 0);
		navigate(DRIVER_CITY_START_ROUTE);
	};

	const sendReview = async () => {
		await setReviewDriver(params.id, clientInfo.id, rating, reviewText);
		navigate(DRIVER_CITY_START_ROUTE);
	};

	if (loading) return <Loader />;

	const showPhone = () => {
		setClientPhone(true);
		window.location.href = `tel:+7${clientInfo.phone}`;
	};

	const ratingChanged = (newRating) => {
		setRating(newRating);
	};

	const cancelOrder = async () => {
		await changeStatus(params.id, 0);
		sendMessage(
			JSON.stringify({
				id: params.id,
				cancel: true,
			}),
		);
		navigate(DRIVER_CITY_START_ROUTE);
	};

	return (
		<main className="flex flex-col h-screen mx-auto">
			{currentStatus < 4 && currentStatus > 1 && (
				<div className="w-full bg-graylight">
					<button
						className="container mx-auto px-1.5 py-3 cursor-pointer"
						onClick={cancelOrder}
					>
						<span className="text-gray">{t("order.order_cancel")}</span>
					</button>
				</div>
			)}

			{currentStatus === 2 && (
				<LongWaitNotifification deadline={orderData.timer} />
			)}

			<TravelMap fromPoint={orderData.fromPoint} toPoint={orderData.toPoint} />

			{/* {currentStatus === 4 && currentStatus === 5 && (
				<div className="flex flex-col items-center z-10">
					<div className="w-full bg-graylight">
						<div className="container mx-auto px-1.5 py-3">
							<span className="text-gray">{t("order.order_cancel")}</span>
						</div>
					</div>
				</div>
			)} */}

			<div className="relative flex justify-center flex-col z-10">
				{/* Заказ отменен */}
				{currentStatus === 0 && (
					<Dialog message={t("city.client_reject_msg")} onClose={cancelTrip} />
				)}

				{/* К клиенту */}
				{currentStatus === 2 && (
					<>
						<div className="container sm:max-w-lg flex flex-col bg-paper mx-auto shadow-map p-5">
							<OrderInfo
								clientInfo={clientInfo}
								orderData={orderData}
								activePoint={1}
							/>

							<Button onClick={inPlace} className="mt-3">
								{t("city.in_place")}
							</Button>
						</div>
					</>
				)}

				{/* Подъехал */}
				{currentStatus === 3 && (
					<div className="container sm:max-w-lg flex flex-col bg-paper mx-auto shadow-map p-5">
						{leaving && (
							<p className="text-center font-medium mb-3">
								{t("city.leaving_message", {
									name: clientInfo.firstName ? clientInfo.firstName : "Клиент",
								})}
							</p>
						)}

						<OrderInfo clientInfo={clientInfo} orderData={orderData} />

						<Button
							onClick={startTrip}
							disabled={leaving ? false : true}
							className="mt-3"
						>
							{t("city.lets_go")}
						</Button>
					</div>
				)}

				{/* Поездка */}
				{currentStatus === 4 && (
					<div className="container sm:max-w-lg flex flex-col bg-paper mx-auto shadow-map p-5">
						<OrderInfo
							clientInfo={clientInfo}
							orderData={orderData}
							activePoint={2}
						/>

						<Button onClick={completeTrip} className="mt-3">
							{t("complete")}
						</Button>
					</div>
				)}

				{currentStatus === 5 && (
					<div className="container sm:max-w-lg flex flex-col bg-paper mx-auto shadow-map p-5">
						<p className="text-center text-2xl font-bold mt-1.5">
							{t("order.rate_client")}
						</p>

						<button
							className="text-center text-blue text-lg mt-1.5 mx-auto"
							onClick={showPhone}
						>
							{clientPhone ? (
								<p>+7{clientInfo.phone}</p>
							) : (
								<p>{t("order.call_client")}</p>
							)}
						</button>

						<div className="flex flex-row justify-center items-center mx-auto space-x-2.5 my-3">
							<Avatar
								src={
									clientInfo.avatar
										? process.env.REACT_APP_API_URL + clientInfo.avatar
										: undefined
								}
							/>

							<div className="flex flex-col">
								<p className="leading-none">
									{clientInfo.firstName
										? clientInfo.firstName
										: `+7${clientInfo.phone}`}
								</p>

								<div className="flex flex-row justify-start space-x-1 mt-1.5">
									<span className="w-3 h-3">
										<img src={star_icon} />
									</span>

									<p className="leading-3">
										{clientInfo.rating
											? clientInfo.rating.substr(0, 4)
											: "0.00"}{" "}
										<span className="text-gray">({clientInfo.orders})</span>
									</p>
								</div>
							</div>
						</div>

						<div className="flex flex-col space-y-3.5">
							<div className="flex flex-row items-center mx-auto space-x-5">
								<ReactStars
									count={5}
									value={5}
									onChange={ratingChanged}
									size={48}
									isHalf={false}
									activeColor="#ffd700"
								/>
							</div>

							<div className="flex flex-row items-center space-x-2">
								<span className="w-4 h-4">
									<img src={comment_icon} className="w-4 h-4" />
								</span>

								<div className="w-full border-b border-solid border-graylight">
									<input
										type="text"
										onChange={(e) => setReviewText(e.target.value)}
										placeholder={t("city.comment_placeholder")}
										className="w-full px-1.5 py-2 resize-none outline-none"
									/>
								</div>
							</div>

							<Button
								className="flex flex-row justify-center items-center space-x-3 w-full py-3 disabled:bg-gray bg-blue text-white rounded-xl font-medium text-lg"
								onClick={sendReview}
							>
								{t("send")}
							</Button>
						</div>
					</div>
				)}
			</div>
		</main>
	);
});

export default DriverCityGo;
