import { $authHost, $host } from "./index";

export const createOrder = async (orderInfo) => {
	const data = await $authHost.post("api/order/create", orderInfo);

	return data;
};

export const getOrders = async (userId, status, location) => {
	const { data } = await $authHost.get("api/order", {
		params: { userId, status, location },
	});
	return data;
};

export const getDriverOrders = async (driverId) => {
	const { data } = await $authHost.get("api/order", {
		params: { driverId },
	});
	return data;
};

export const getOneOrder = async (id) => {
	const { data } = await $authHost.get(`api/order/${id}`);

	return data;
};

export const changeStatus = async (id, status) => {
	const { data } = await $authHost.post("api/order/changeStatus/", {
		id,
		status,
	});
	return data;
};

export const changePrice = async (id, price) => {
	const { data } = await $authHost.post("api/order/changePrice", { id, price });
	return data;
};
export const getAnswers = async (id, status) => {
	const { data } = await $authHost.post("api/order/getAnswers", { id, status });
	return data;
};

export const changeAnswerStatus = async (id, status) => {
	const { data } = await $authHost.post("api/order/changeAnswerStatus", {
		id,
		status,
	});
	return data;
};

export const getDriverCar = async (id, type) => {
	const { data } = await $authHost.post("api/order/getDriverCar", { id, type });
	return data;
};

export const getDriverInfo = async (id) => {
	const { data } = await $authHost.post("api/order/getDriverInfo", { id });
	return data;
};

export const setOrderDriver = async (id, driverId, timer) => {
	const { data } = await $authHost.post("api/order/setOrderDriver", {
		id,
		driverId,
		timer,
	});
	return data;
};

export const getOrderStatus = async (id) => {
	const { data } = await $authHost.post("api/order/getOrderStatus", { id });
	return data;
};

export const setLeavingStatus = async (id, leaving) => {
	const { data } = await $authHost.post("api/order/setLeavingStatus", {
		id,
		leaving,
	});
	return data;
};

//

export const getOrderDriver = async (id) => {
	const { data } = await $authHost.post("api/order/getOrderDriver", { id });
	return data;
};

export const createAnswer = async (price, time, orderId, isNavigatorMode = false) => {
	const { data } = await $authHost.post("api/order/createAnswer", {
		price,
		time,
		orderId,
		isNavigatorMode,
	});
	return data;
};

export const deleteAnswer = async (id) => {
	const { data } = await $authHost.post("api/order/deleteAnswer", { id });
	return data;
};

export const getMyAnswer = async (orderId) => {
	const { data } = await $authHost.post("api/order/getMyAnswer", { orderId });
	return data;
};

export const getActiveOrder = async () => {
	const { data } = await $authHost.post("api/order/getActiveOrder");
	return data;
};

export const getActiveDriverOrder = async () => {
	const { data } = await $authHost.post("api/order/getActiveDriverOrder");
	return data;
};
