import { useContext } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import { city_icon } from "../img";
import {
	USER_CITY_GO_ROUTE,
	USER_CITY_SEARCH_ROUTE,
	USER_HISTORY_ROUTE,
	DRIVER_CITY_GO_ROUTE,
	DRIVER_HISTORY_ROUTE,
} from "../utils/consts";
import { AppContext } from "../contexts/AppContext";

const OrderItem = ({ order }) => {
	const { user } = useContext(AppContext);
	const { t } = useTranslation();
	const date = new Date(order.createdAt).toLocaleString("ru", {
		year: "numeric",
		month: "short",
		day: "numeric",
		hour: "numeric",
		minute: "numeric",
	});

	const orderType = (param) => {
		switch (param) {
			case "CITY":
				return { text: t("order.type.CITY"), img: city_icon };
			case "INTERCITY":
				return t("order.type.INTERCITY");
			case "FAECES":
				return t("order.type.INTERCITY");
			case "WATER":
				return t("order.type.WATER");
			case "CARGO":
				return t("order.type.CARGO");
		}
	};

	const orderSwitch = (param) => {
		switch (param) {
			case 0:
				return {
					status: t("order.status.canceled"),
					link:
						user._user.role === "USER"
							? USER_HISTORY_ROUTE
							: DRIVER_HISTORY_ROUTE,
				};
			case 1:
				return {
					status: t("order.status.finding"),
					link: USER_CITY_SEARCH_ROUTE,
				};
			case 2:
				return {
					status: t("order.status.waiting"),
					link:
						user._user.role === "USER"
							? USER_CITY_GO_ROUTE
							: DRIVER_CITY_GO_ROUTE,
				};
			case 3:
				return {
					status: t("order.status.in_place"),
					link:
						user._user.role === "USER"
							? USER_CITY_GO_ROUTE
							: DRIVER_CITY_GO_ROUTE,
				};
			case 4:
				return {
					status: t("order.status.proccess"),
					link:
						user._user.role === "USER"
							? USER_CITY_GO_ROUTE
							: DRIVER_CITY_GO_ROUTE,
				};
			case 5:
				return {
					status: t("order.status.complete"),
					link:
						user._user.role === "USER"
							? USER_CITY_GO_ROUTE
							: DRIVER_CITY_GO_ROUTE,
				};
		}
	};

	return (
		<div className="flex flex-row justify-between items-center px-5 p-3 active:bg-graylight/30">
			<div className="flex items-center mr-3">
				<img src={orderType(order.type).img} alt={orderType(order.type).text} />
			</div>

			<div className="flex flex-col justify-between flex-1 truncate">
				<div className="flex justify-between text-sm font-medium">
					<p>
						{orderType(order.type).text}, {order.price} ₽
					</p>

					<p>{date}</p>
				</div>

				<div className="my-1">
					<p className="text-xs truncate">{order.from}</p>

					<p className="text-xs truncate">{order.to}</p>
				</div>

				<p className="text-xs font-medium">
					{t("order.status")}: {orderSwitch(order.status).status}
				</p>
			</div>
		</div>
	);
};

export default OrderItem;
