import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import UserStore from "./store/UserStore";
import "./index.css";
import OrderStore from "./store/OrderStore";
import { AppContext } from "./contexts/AppContext";
import { WithGeolocation } from "./contexts/GeolocationContext";
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

import "./i18n";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
	<AppContext.Provider
		value={{
			user: new UserStore(),
			orders: new OrderStore(),
		}}
	>
		<WithGeolocation>
			<App />
		</WithGeolocation>
	</AppContext.Provider>,
);

serviceWorkerRegistration.register()
