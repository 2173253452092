import { useState, useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

import {
	DRIVER_ABOUT_ROUTE,
	DRIVER_CHANGE_PHONE_ROUTE,
	AUTH_ROUTE,
} from "../../../utils/consts";
import {
	createNotificationSubscription,
	deleteNotificationSubscription,
} from "../../../utils/push";
import { AppContext } from "../../../contexts/AppContext";
import Navbar from "../../../components/Navbar";

const NotificationSettings = () => {
	const [isOn, setIsOn] = useState(false);
	const [isLoading, setIsLoading] = useState(true);
	const [isRejected, setIsRejected] = useState(false);

	useEffect(() => {
		navigator.serviceWorker.ready.then((sw) => {
			sw.pushManager
				.getSubscription()
				.then((sub) => {
					setIsOn(sub !== null);
				})
				.finally(() => setIsLoading(false));
		});
	}, []);

	const handleToggle = () => {
		if (!isOn) {
			Notification.requestPermission()
				.then((result) => {
					if (result === "granted") {
						setIsLoading(true);
						createNotificationSubscription().then(() => setIsOn(true));
					} else {
						setIsOn(false);
						setIsRejected(true);
					}
				})
				.finally(() => setIsLoading(false));
		} else {
			setIsLoading(true);
			deleteNotificationSubscription()
				.then(() => setIsOn(false))
				.finally(() => setIsLoading(false));
		}
	};

	return (
		<div className="flex justify-between items-center">
			<div>
				<p className="text-lg leading-none">Уведомления</p>
				{!isLoading && (
					<p className="text-gray text-sm">
						{isOn
							? "Включено"
							: isRejected
							? "Отклонено пользователем"
							: "Выключено"}
					</p>
				)}
			</div>

			{!isLoading && (
				<button
					disabled={isRejected}
					onClick={handleToggle}
					className="text-sm text-blue leading-none disabled:opacity-60"
				>
					{isOn ? "Отключить" : "Включить"}
				</button>
			)}
		</div>
	);
};

const DriverSettings = () => {
	const { t } = useTranslation();
	const { user } = useContext(AppContext);

	const logOut = () => {
		user.setUser({});
		localStorage.removeItem("token");
		localStorage.removeItem("phone");
		window.location.href = AUTH_ROUTE;
	};

	return (
		<main className="relative min-h-screen mx-auto">
			<Navbar title={t("settings.title")} />

			<div className="container mx-auto py-5">
				<div className="mx-5 mt-5">
					<ul className="space-y-7">
						<li>
							<Link to={DRIVER_CHANGE_PHONE_ROUTE} className="flex flex-col">
								<p className="text-lg leading-none">
									{t("settings.change_number")}
								</p>
								<label className="text-gray">+7{user._user.phone}</label>
							</Link>
						</li>

						{/* <li class="flex flex-row justify-between items-center">
                <label for="push" className="text-lg leading-none">Push уведомления</label>
                <SwitchPush />
              </li> */}

						{/* <li class="flex flex-col">
                <p className="text-lg leading-none">Язык приложения</p>
                <label className="text-gray">Русский</label>
              </li> */}

						<li>
							<NotificationSettings />
						</li>

						<li>
							<Link to={DRIVER_ABOUT_ROUTE} className="flex flex-col">
								<p className="text-lg leading-none">
									{t("settings.about_app")}
								</p>
							</Link>
						</li>

						<li className="flex flex-col mt-4" onClick={logOut}>
							<p className="text-lg text-blue leading-none">
								{t("settings.logout")}
							</p>
						</li>
					</ul>
				</div>
			</div>
		</main>
	);
};

export default DriverSettings;
