import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import HistoryList from "../../../components/HistoryList";
import { AppContext } from "../../../contexts/AppContext";
import { getOrders } from "../../../http/orderApi";
import Navbar from "../../../components/Navbar";
import Loader from "../../../components/Loader";

const UserHisotry = () => {
	const { t } = useTranslation();
	const { user, orders } = useContext(AppContext);
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		getOrders(user.user.id)
			.then((data) => orders.setOrders(data))
			.finally(setLoading(false));
	}, []);

	if (loading) {
		return <Loader />;
	}

	return (
		<main className="relative h-screen mx-auto">
			<Navbar title={t("order.history.title")} />

			<div className="container sm:max-w-lg mx-auto py-5">
				<HistoryList />
			</div>
		</main>
	);
};

export default UserHisotry;
