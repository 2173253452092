import { useEffect, useRef, useState, useLayoutEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import useWebSocket, { ReadyState } from "react-use-websocket";
import { observer } from "mobx-react-lite";
import ReactStars from "react-rating-stars-component";
import useSound from "use-sound";

import {
	changeStatus,
	getDriverCar,
	getDriverInfo,
	getOneOrder,
	setLeavingStatus,
} from "../../../http/orderApi";
import { comment_icon, star_icon } from "../../../img";
import { USER_CITY_START_ROUTE, MINUTE, SECOND } from "../../../utils/consts";
import { useTimer } from "../../../utils/useTimer";
import { setReviewUser } from "../../../http/userApi";
import { TravelMap } from "../../../components/TravelMap";
import Avatar from "../../../components/Avatar";
import Loader from "../../../components/Loader";
import Button from "../../../components/Button";
import Dialog from "../../../components/Dialog";
import NotifySfx from "../../../sounds/music_marimba_chord.mp3";

const useIsomorphicLayoutEffect =
	typeof window !== "undefined" ? useLayoutEffect : useEffect;

function useInterval(callback, delay) {
	const savedCallback = useRef(callback);

	// Remember the latest callback if it changes.
	useIsomorphicLayoutEffect(() => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	useEffect(() => {
		// Don't schedule if no delay is specified.
		// Note: 0 is a valid value for delay.
		if (!delay && delay !== 0) {
			return;
		}

		const id = setInterval(() => savedCallback.current(), delay);

		return () => clearInterval(id);
	}, [delay]);
}

const LeavingButton = (props) => {
	const { t } = useTranslation();
	const [playNotify] = useSound(NotifySfx);

	useInterval(playNotify, 2000);

	return <Button {...props}>{t("order.leaving")}</Button>;
};

const LongWaitNotifification = ({ deadline }) => {
	const { t } = useTranslation();
	const time = useTimer(deadline);

	return (
		<div className="bg-paper p-2 px-3 text-center">
			{time <= 0 ? (
				<p className="text-center">{t("city.client_long_wait_message")}</p>
			) : (
				<span className="font-bold text-xl">
					{Math.floor((time / MINUTE) % 60)}:{Math.floor((time / SECOND) % 60)}
				</span>
			)}
		</div>
	);
};

const OrderInfo = ({ orderData, driverInfo }) => {
	return (
		<div>
			<div className="flex items-center justify-between">
				<div className="flex items-center">
					<Avatar
						className="mr-3"
						src={
							driverInfo.avatar
								? process.env.REACT_APP_API_URL + driverInfo.avatar
								: undefined
						}
					/>

					<div>
						<p className="leading-5">
							{driverInfo.firstName
								? driverInfo.firstName
								: `+7${driverInfo.phone}`}
						</p>

						<div className="flex flex-row items-center space-x-0.5 text-gray">
							<svg
								xmlns="http://www.w3.org/2000/svg"
								viewBox="0 0 24 24"
								fill="currentColor"
								className="w-4 h-4 text-gold"
							>
								<path
									fillRule="evenodd"
									d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
									clipRule="evenodd"
								/>
							</svg>

							<p className="text-xs mt-0.5">
								{`${driverInfo.rating ? driverInfo.rating.substr(0, 4) : "0.00"
									} (${driverInfo.orders})`}
							</p>
						</div>
					</div>
				</div>

				<a
					href={`tel:+7${driverInfo.phone}`}
					className="inline-block rounded-full border-blue text-blue border-2 p-3"
				>
					<svg
						xmlns="http://www.w3.org/2000/svg"
						viewBox="0 0 24 24"
						fill="currentColor"
						className="w-5 h-5"
					>
						<path
							fillRule="evenodd"
							d="M1.5 4.5a3 3 0 013-3h1.372c.86 0 1.61.586 1.819 1.42l1.105 4.423a1.875 1.875 0 01-.694 1.955l-1.293.97c-.135.101-.164.249-.126.352a11.285 11.285 0 006.697 6.697c.103.038.25.009.352-.126l.97-1.293a1.875 1.875 0 011.955-.694l4.423 1.105c.834.209 1.42.959 1.42 1.82V19.5a3 3 0 01-3 3h-2.25C8.552 22.5 1.5 15.448 1.5 6.75V4.5z"
							clipRule="evenodd"
						/>
					</svg>
				</a>
			</div>

			<div className="flex items-center justify-between mt-5">
				<div className="space-y-3">
					<div className="flex space-x-1.5">
						<span className="inline-block w-4 h-4 border-4 border-solid border-red rounded-full" />
						<p className="text-sm leading-4">
							{orderData.from}
							{orderData.entrance ? `, подъезд. ${orderData.entrance}` : ""}
						</p>
					</div>

					<div className="flex space-x-1.5">
						<span className="inline-block w-4 h-4 border-4 border-solid border-blue rounded-full" />
						<p className="text-sm leading-4">{orderData.to}</p>
					</div>
				</div>

				<span className="text-blue text-lg font-medium whitespace-nowrap">
					{orderData.price} ₽
				</span>
			</div>
		</div>
	);
};

const CarInfo = ({ driverCar }) => {
	return (
		<div className="flex flex-col text-center">
			<span className="text-xl font-bold">
				{driverCar.color ? driverCar.color : ""} -{" "}
				{driverCar.brand ? driverCar.brand : ""}{" "}
				{driverCar.model ? driverCar.model : ""}
			</span>

			<div className="flex flex-row justify-between mx-auto w-min bg-graylight border border-gray rounded-md mt-2">
				<p className="w-24 py-1 pl-2.5">
					{driverCar.number ? driverCar.number.substr(0, 8) : ""}
				</p>
				<span className="w-px bg-gray" />
				<p className="py-1 px-2.5">
					{driverCar.number
						? driverCar.number.substr(8, driverCar.number.length)
						: ""}
				</p>
			</div>
		</div>
	);
};

const UserCityGo = observer(() => {
	const { t } = useTranslation();
	const params = useParams();
	const navigate = useNavigate();
	const [orderData, setOrderData] = useState({});
	const [driverInfo, setDriverInfo] = useState({});
	const [driverCar, setDriverCar] = useState({});
	const [driverPhone, setDriverPhone] = useState(false);
	const [showLeaving, setShowLeaving] = useState(true);
	const [currentStatus, setCurrentStatus] = useState(null);
	const [rating, setRating] = useState(5);
	const [reviewText, setReviewText] = useState("");
	const [loading, setLoading] = useState(true);
	const { sendMessage, lastMessage, readyState } = useWebSocket(
		process.env.REACT_APP_WS_URL,
		{
			shouldReconnect: () => true,
		},
	);

	useEffect(() => {
		getOneOrder(params.id).then((data) => {
			setOrderData({
				...data,
				fromPoint: data.fromPoint.split(",").map(Number),
				toPoint: data.toPoint.split(",").map(Number),
			});
			setCurrentStatus(data.status);
			getDriverCar(data.driverId, "CITY").then((data) => setDriverCar(data));
			getDriverInfo(data.driverId)
				.then((data) => setDriverInfo(data))
				.finally(() => setLoading(false));
		});
	}, []);

	useEffect(() => {
		if (readyState === ReadyState.OPEN) {
			sendMessage(JSON.stringify({ id: params.id }));
		}
	}, [readyState]);

	useEffect(() => {
		if (lastMessage !== null) {
			const msg = JSON.parse(lastMessage.data);
			if (msg.status) {
				setCurrentStatus(msg.status);

				if (msg.status === 3) {
					getOneOrder(params.id).then((data) => {
						setOrderData({
							...data,
							fromPoint: data.fromPoint.split(",").map(Number),
							toPoint: data.toPoint.split(",").map(Number),
						});
					});
				}
			}

			if (msg.cancel) setCurrentStatus(0);
		}
	}, [lastMessage]);

	const cancelOrder = async () => {
		await changeStatus(params.id, 0);
		sendMessage(
			JSON.stringify({
				id: params.id,
				cancel: true,
			}),
		);
		navigate(USER_CITY_START_ROUTE);
	};

	const back = async () => {
		navigate(USER_CITY_START_ROUTE);
	};

	const showPhone = () => {
		setDriverPhone(true);
		window.location.href = `tel:+7${driverInfo.phone}`;
	};

	const egress = async () => {
		await setLeavingStatus(params.id, true);
		setShowLeaving(false);
		sendMessage(
			JSON.stringify({
				id: params.id,
				leaving: true,
			}),
		);
	};

	const ratingChanged = (newRating) => {
		setRating(newRating);
	};

	const sendReview = async () => {
		await setReviewUser(params.id, driverInfo.id, rating, reviewText);
		navigate(USER_CITY_START_ROUTE);
	};

	if (loading) return <Loader />;

	return (
		<main className="flex flex-col h-screen mx-auto">
			{currentStatus === 2 && (
				<div className="flex flex-col items-center z-10">
					<div className="w-full bg-graylight">
						<button
							className="container mx-auto px-1.5 py-3 cursor-pointer"
							onClick={cancelOrder}
						>
							<span className="text-gray">{t("order.order_cancel")}</span>
						</button>
					</div>
				</div>
			)}

			{currentStatus === 3 && orderData.status === 3 && (
				<LongWaitNotifification deadline={orderData.timer} />
			)}

			<TravelMap fromPoint={orderData.fromPoint} toPoint={orderData.toPoint} />

			<div className="flex justify-center flex-col z-10">
				{/* Ожидание */}
				{currentStatus === 2 && (
					<div className="container sm:max-w-lg flex flex-col bg-paper mx-auto shadow-map p-5">
						<div className="flex flex-col mb-1.5">
							<p className="text-lg text-center font-medium mb-3">
								{t("order.pending")}
							</p>

							<OrderInfo orderData={orderData} driverInfo={driverInfo} />

							<div className="mt-5">
								<CarInfo driverCar={driverCar} />
							</div>

							{/* <button
								className="flex flex-row justify-center items-center space-x-3 w-full py-3 border border-solid border-blue text-blue rounded-xl font-medium text-lg"
								onClick={showPhone}
							>
								<span>
									<img src={phone_icon} />
								</span>
								{driverPhone ? (
									<p>+7{driverInfo.phone}</p>
								) : (
									<p>{t("order.driver_contact")}</p>
								)}
							</button> */}
						</div>
					</div>
				)}

				{/* Подъехал */}
				{currentStatus === 3 && (
					<div className="container sm:max-w-lg flex flex-col bg-paper mx-auto shadow-map p-5">
						<p className="text-lg text-center font-medium mb-3">
							{t("order.drove_up")}
						</p>

						<OrderInfo orderData={orderData} driverInfo={driverInfo} />

						<div className="mt-5">
							<CarInfo driverCar={driverCar} />
						</div>

						{/* <button
							className="flex flex-row justify-center items-center space-x-3 w-full py-3 border border-solid border-blue text-blue rounded-xl font-medium text-lg mb-2"
							onClick={showPhone}
						>
							<span>
								<img src={phone_icon} />
							</span>
							{driverPhone ? (
								<p>+7{driverInfo.phone}</p>
							) : (
								<p>{t("order.driver_contact")}</p>
							)}
						</button> */}

						{showLeaving && <LeavingButton className="mt-5" onClick={egress} />}
					</div>
				)}

				{/* Поездка */}
				{currentStatus === 4 && (
					<div className="container sm:max-w-lg flex flex-col bg-paper mx-auto shadow-map p-5">
						<p className="text-center text-lg font-bold mb-3">
							{t("order.person_driving")}
						</p>

						<OrderInfo orderData={orderData} driverInfo={driverInfo} />

						<div className="mt-5">
							<CarInfo driverCar={driverCar} />
						</div>

						{/* <div className="flex flex-col text-center">
							<span className="text-xl font-bold">
								{driverCar.color ? driverCar.color : ""} -{" "}
								{driverCar.brand ? driverCar.brand : ""}{" "}
								{driverCar.model ? driverCar.model : ""}
							</span>

							<div className="flex flex-row justify-between mx-auto w-min bg-graylight rounded-md">
								<p className="w-24 py-1 pl-2.5">
									{driverCar.number ? driverCar.number.substr(0, 8) : ""}
								</p>

								<span className="w-px bg-black" />

								<p className="py-1 px-2.5">
									{driverCar.number
										? driverCar.number.substr(8, driverCar.number.length)
										: ""}
								</p>
							</div>

							<p className="text-gray font-lg font-bold pt-3">
								8-
								{`${driverInfo.driverPhone.slice(
									0,
									3,
								)}-${driverInfo.driverPhone.slice(
									3,
									6,
								)}-${driverInfo.driverPhone.slice(6)}`}
							</p>

							<p className="text-xs font-bold">
								{t("order.payment_phone_desc")}
							</p>
						</div> */}

						{/* <p className="text-xs font-bold">{t("order.payment_phone_desc")}</p> */}

						{/* <button
							className="flex flex-row justify-center items-center space-x-3 w-full py-3 border border-solid border-blue text-blue rounded-xl font-medium text-lg"
							onClick={showPhone}
						>
							<span>
								<img src={phone_icon} />
							</span>
							{driverPhone ? (
								<p>+7{driverInfo.phone}</p>
							) : (
								<p>{t("order.driver_contact")}</p>
							)}
						</button> */}

						<div className="text-center">
							<p className="text-gray font-lg font-bold pt-3">
								8-
								{`${driverInfo.driverPhone.slice(
									0,
									3,
								)}-${driverInfo.driverPhone.slice(
									3,
									6,
								)}-${driverInfo.driverPhone.slice(6)}`}
							</p>

							<p className="text-xs font-bold">
								{t("order.payment_phone_desc")}
							</p>
						</div>
					</div>
				)}

				{/* Оставить отзыв */}
				{currentStatus === 5 && (
					<div className="container sm:max-w-lg flex flex-col bg-paper mx-auto shadow-map p-5">
						<p className="text-center text-2xl font-bold mt-1.5">
							{t("order.rate_driver")}
						</p>

						<button
							className="text-center text-blue text-lg mt-1.5 mx-auto"
							onClick={showPhone}
						>
							{driverPhone ? (
								<p>+7{driverInfo.phone}</p>
							) : (
								<p>{t("order.call_driver")}</p>
							)}
						</button>

						<div className="flex flex-row justify-center items-center mx-auto space-x-2.5 my-3">
							<Avatar
								src={
									driverInfo.avatar
										? process.env.REACT_APP_API_URL + driverInfo.avatar
										: undefined
								}
							/>

							<div className="flex flex-col">
								<p className="leading-none">{driverInfo.firstName}</p>

								<div className="flex flex-row justify-start space-x-1 mt-1.5">
									<span className="w-3 h-3">
										<img src={star_icon} />
									</span>

									<p className="leading-3">
										{driverInfo.rating
											? driverInfo.rating.substr(0, 4)
											: "0.00"}{" "}
										<span className="text-gray">
											({driverInfo.driverOrders})
										</span>
									</p>
								</div>
							</div>
						</div>

						<div className="flex flex-col space-y-3.5">
							<div className="flex flex-row items-center mx-auto space-x-5">
								<ReactStars
									count={5}
									value={5}
									onChange={ratingChanged}
									size={48}
									isHalf={false}
									activeColor="#ffd700"
								/>
							</div>

							<div className="flex flex-row items-center space-x-2">
								<span className="w-4 h-4">
									<img src={comment_icon} className="w-4 h-4" />
								</span>

								<div className="w-full border-b border-solid border-graylight">
									<input
										type="text"
										onChange={(e) => setReviewText(e.target.value)}
										placeholder={t("city.comment_placeholder")}
										className="w-full px-1.5 py-2 resize-none outline-none"
									/>
								</div>
							</div>

							<Button onClick={() => sendReview()}>{t("send")}</Button>
						</div>
					</div>
				)}

				{currentStatus === 0 && (
					<Dialog message={t("city.driver_reject_msg")} onClose={back} />
				)}
			</div>
		</main>
	);
});

export default UserCityGo;
