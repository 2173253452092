import { makeAutoObservable } from "mobx"

export default class OrderStore {
    constructor() {
        this._orders = []
        this._answers = []
        makeAutoObservable(this)
    }

    setOrders(orders) {
        this._orders = orders
    }

    setAnswers(answers) {
        this._answers = answers
    }

    removeAnswers(id) {
        for (let answer of this.answers) {
            if (answer.id == id) {
                const indexOf = this.answers.indexOf(answer)
                this.answers.splice(indexOf, 1)
            }
        }
    }

    get orders() {
        return this._orders
    }

    get answers() {
        return this._answers
    }
}