import Resizer from "react-image-file-resizer";

const getImageWidth = async (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (event) => {
      const image = new Image();
      image.src = event.target.result;
      image.onload = () => {
        resolve(image.width);
        return image.width;
      };
      reader.onerror = (err) => reject(err);
    };
  });
};

const resizeImage = (file, options) => {
  let quality = 100;
  //4MB image file
  if (file.size > 4000000) {
    quality = 90;
  }
  //8MB image file
  if (file.size > 8000000) {
    quality = 85;
  }
  return new Promise((resolve) => {
    Resizer.imageFileResizer(
      file,
      options.fitSize,
      options.fitSize,
      "JPEG",
      quality,
      0,
      (uri) => {
        resolve(uri);
      },
      "blob"
    );
  });
};

export function optimizeImage(file, options) {
  return getImageWidth(file).then((width) => {
    if (width <= options.fitSize) {
      return file
    } else {
      return resizeImage(file, options)
    }
  })
}
