import { forwardRef, useLayoutEffect, useCallback, useState, useRef, useEffect } from "react";
import { lineString } from "@turf/helpers";
import bbox from "@turf/bbox";

import { Mapbox, MapboxMarker } from "./Mapbox";

function getSize(el) {
	if (!el) {
		return {
			width: 0,
			height: 0,
		};
	}

	return {
		width: el.offsetWidth,
		height: el.offsetHeight,
	};
}

function useSize(ref) {
	var _useState = useState(getSize(ref ? ref.current : {}));
	var ComponentSize = _useState[0];
	var setComponentSize = _useState[1];

	var handleResize = useCallback(
		function handleResize() {
			if (ref.current) {
				setComponentSize(getSize(ref.current));
			}
		},
		[ref],
	);

	useLayoutEffect(
		function () {
			if (!ref.current) {
				return;
			}

			handleResize();

			if (typeof ResizeObserver === "function") {
				var resizeObserver = new ResizeObserver(function () {
					handleResize();
				});
				resizeObserver.observe(ref.current);

				return function () {
					resizeObserver.disconnect(ref.current);
					resizeObserver = null;
				};
			} else {
				window.addEventListener("resize", handleResize);

				return function () {
					window.removeEventListener("resize", handleResize);
				};
			}
		},
		[ref.current],
	);

	return ComponentSize;
}

export const TravelMap = forwardRef(({ fromPoint, toPoint }, forwardedRef) => {
	const containerRef = useRef(null);
	const mapRef = useRef(null);
	const [minLng, minLat, maxLng, maxLat] = bbox(
		lineString([fromPoint, toPoint]),
	);

	const { width, height } = useSize(containerRef);

	useEffect(() => {
		mapRef.current?.resize()
	}, [width, height])

	return (
		<div ref={containerRef} className="relative flex-1">
			<Mapbox
				ref={mapRef}
				className="pointer-events-none touch-none"
				initialViewState={{
					bounds: [
						[minLng, minLat],
						[maxLng, maxLat],
					],
					fitBoundsOptions: {
						padding: 50,
					},
				}}
			>
				<Mapbox.Marker longitude={fromPoint[0]} latitude={fromPoint[1]}>
					<span className="block w-4 h-4 bg-paper rounded-full border-4 border-solid border-red" />
				</Mapbox.Marker>
				<Mapbox.Marker longitude={toPoint[0]} latitude={toPoint[1]}>
					<span className="block w-4 h-4 bg-paper rounded-full border-4 border-solid border-blue" />
				</Mapbox.Marker>
			</Mapbox>
		</div>
	);
});
