import React, { useState, useRef } from "react";

const InputCode = ({ length, label, loading, onComplete }) => {
  const [code, setCode] = useState([...Array(length)].map(() => ""));
  const inputs = useRef([]);
  // Typescript
  // useRef<(HTMLInputElement | null)[]>([])

  const processInput = (e, slot) => {
    const num = e.target.value;
    if (/[^0-9]/.test(num)) return;
    const newCode = [...code];
    newCode[slot] = num;
    setCode(newCode);
    if (slot !== length - 1) {
      inputs.current[slot + 1].focus();
    }
    if (newCode.every(num => num !== "")) {
      onComplete(newCode.join(""));
    }
  };

  const onKeyUp = (e, slot) => {
    if (e.keyCode === 8 && !code[slot] && slot !== 0) {
      const newCode = [...code];
      newCode[slot - 1] = "";
      setCode(newCode);
      inputs.current[slot - 1].focus();
    }
  };

  return (
    <div className="flex flex-row justify-center space-x-3 my-5">
      {code.map((num, idx) => {
        return (
          <input
            key={idx}
            type="tel"
            inputMode="numeric"
            maxLength={1}
            value={num}
            className="w-8 outline-none border-b border-solid border-gray text-2xl text-center"
            autoFocus={!code[0].length && idx === 0}
            readOnly={loading}
            onChange={e => processInput(e, idx)}
            onKeyUp={e => onKeyUp(e, idx)}
            ref={ref => inputs.current.push(ref)}
          />
        );
      })}
    </div>
  );
};

export default InputCode;