import { useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import { AppContext } from "../contexts/AppContext";
import { driverRouter, publicRoutes, userRouter } from "../router";
import { NOT_FOUND_ROUTE } from "../utils/consts";
import {
	createNotificationSubscription,
	isPushNotificationSupported,
} from "../utils/push";

const AppRouter = () => {
	const { user } = useContext(AppContext);

	useEffect(() => {
		if (isPushNotificationSupported() && user._user.role) {
			Notification.requestPermission().then((result) => {
				if (result === "granted") {
					createNotificationSubscription();
				}
			});
		}
	}, [user._user.role]);

	return (
		<Routes>
			{user._user.role === "USER" &&
				userRouter.map(({ path, Component }) => (
					<Route key={path} path={path} element={Component} exact />
				))}

			{user._user.role === "DRIVER" &&
				driverRouter.map(({ path, Component }) => (
					<Route key={path} path={path} element={Component} exact />
				))}

			{publicRoutes.map(({ path, Component }) => (
				<Route key={path} path={path} element={Component} exact />
			))}

			<Route path="*" element={<Navigate to={NOT_FOUND_ROUTE} />} />
		</Routes>
	);
};

export default AppRouter;
