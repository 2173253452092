import { observer } from "mobx-react-lite";
import { useContext } from "react";
import { AppContext } from "../contexts/AppContext";
import AnswerItem from "./AnswerItem";

const AnswerList = observer(() => {
	const { orders } = useContext(AppContext);

	return (
		<div className="fixed inset-0 bg-black/70 z-10 flex py-16">
			<ul className="container sm:max-w-lg mx-auto space-y-5 px-5">
				{orders.answers
					.filter(({ status }) => status === 1)
					.map((answer) => (
						<AnswerItem
							key={answer.id}
							answer={answer}
							onCancel={() => {
								orders.removeAnswers(answer.id);
							}}
						/>
					))}
			</ul>
		</div>
	);
});

export default AnswerList;
